import React, { useState, useEffect } from "react";
import $ from "jquery";
import dropify from "dropify";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { api, header_token, img_url } from "../config";
import swal from "sweetalert";

export const AdminProfile = () => {
  const { register, handleSubmit, errors, trigger } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();
  const [admin_img, setadmin_img] = useState({});
  const [id, setid] = useState("");
  const [register_date, setregister_date] = useState("");
  const [full_name, setfull_name] = useState("");
  const [email, setemail] = useState("");
  const [username, setusername] = useState("");
  const [old_password, setold_password] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getProfile = async () => {
    try {
      let { data } = await Axios.get(`${api}/user/profile`, header_token);

      // console.log("getProfile", data);
      // setuser(data);
      setid(data.id);
      setadmin_img({ preview: data.picture, base64: "" });

      setemail(data.email);
      setusername(data.username);
      setregister_date(data.register_date);
      setfull_name(data.full_name);

      $(".dropify").dropify();

      var drEventRider = $("#admin_img").dropify();

      drEventRider.on("dropify.afterClear", async function (event, element) {
        await setadmin_img({
          preview: "",
          base64: "",
        });
        trigger("admin_img");
      });
    } catch (error) {
      // console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmitChangePassword = async (data) => {
    if (password === confirm_password) {
      try {
        let send_data = {
          new_password: password,
          old_password: old_password,
          username: username,
        };
        // console.log("send data", send_data);

        let pass = await Axios.put(
          `${api}/user/change-password`,
          send_data,
          header_token
        );
        // console.log("pass", pass);

        if (pass.status === 200) {
          setpassword("");
          setold_password("");
          setconfirm_password("");
          getProfile();
          swal("แก้ไขเสร็จสิ้น !!", {
            icon: "success",
            timer: 1500,
          });
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      let send_data = {
        email: email,
        picture: admin_img.base64 ? admin_img.base64 : admin_img.preview,
        status: 1,
        register_date: register_date,
        full_name: full_name,
      };

      // console.log("send data", send_data);

      let res = await Axios.put(
        `${api}/admin/user/profile/${id}`,
        send_data,
        header_token
      );
      // console.log(res);

      if (res.status === 200) {
        // // console.log("okkk");
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
          timer: 1500,
        });
        setTimeout(() => {
          window.location = "/admin_profile";
        }, 1600);
        // window.location = "/restaurant";
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">Profile Setting</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Profile Setting
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form key={1} onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    รูป
                  </label>
                  <div className="col-sm-5">
                    <div className="mb-2">
                      {errors.admin_img ? (
                        <div
                          className="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="admin_img"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file={`${img_url + admin_img.preview}`}
                      onChange={async (e) => {
                        // // console.log(e.target.files[0]);
                        if (e.target.files.length) {
                          await setadmin_img({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          // trigger("admin_img");
                        }
                      }}
                      // ref={(e) => {
                      //   register(
                      //     { name: "admin_img" },
                      //     {
                      //       validate: (value) => {
                      //         return admin_img.base64 ? true : false;
                      //       },
                      //     }
                      //   );
                      // }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-5">
                    <input
                      className={`form-control ${
                        errors.email ? "error-input" : ""
                      }`}
                      name="email"
                      type="text"
                      ref={register({ required: true })}
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className="row mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2  ml-auto"
                    >
                      บันทึกข้อมูล
                    </button>
                  </div>
                </div>
              </form>

              <form key={2} onSubmit={handleSubmit2(onSubmitChangePassword)}>
                <div className="row border-bottom">
                  <div className="col-12">
                    <h5>เปลี่ยนรหัสผ่าน</h5>
                  </div>
                </div>
                <br />

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    Username
                  </label>
                  <div className="col-sm-5">
                    <input
                      id="username"
                      className={`form-control ${
                        errors2.username ? "error-input" : ""
                      }`}
                      name="username"
                      type="text"
                      ref={register2({ required: true, min: 6 })}
                      disabled
                      value={username}
                      // onChange={(e) => {
                      //   // setusername(e.target.value);
                      // }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    รหัสปัจจุบัน
                  </label>
                  <div className="col-sm-5">
                    <input
                      id="old_password"
                      className={`form-control ${
                        errors2.old_password ? "error-input" : ""
                      }`}
                      name="old_password"
                      type={show ? "text" : "password"}
                      ref={register2({ required: true })}
                      value={old_password}
                      onChange={async (e) => {
                        await setold_password(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {password !== confirm_password ? (
                  <div className=" row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-5">
                      <label htmlFor="name" style={{ color: "red" }}>
                        รหัสผ่านไม่ตรงกัน
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    รหัสใหม่
                  </label>
                  <div className="col-sm-5">
                    <input
                      id="password"
                      className={`form-control ${
                        errors2.password ? "error-input" : ""
                      }`}
                      name="password"
                      type={show ? "text" : "password"}
                      ref={register2({ required: true })}
                      value={password}
                      onChange={async (e) => {
                        await setpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputEmail2"
                    className="col-sm-2 col-form-label"
                  >
                    ยืนยันรหัส
                  </label>
                  <div className="col-sm-5">
                    <input
                      id="confirm_password"
                      className={`form-control`}
                      name="confirm_password"
                      type={show ? "text" : "password"}
                      // ref={register({ required: true })}
                      value={confirm_password}
                      onChange={async (e) => {
                        await setconfirm_password(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className="row mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2  ml-auto"
                    >
                      เปลี่ยนรหัสผ่าน
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
