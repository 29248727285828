import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useHistory, useRouteMatch } from "react-router-dom";
import swal from "sweetalert";
import { api, header_token, img_url } from "../../config";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

export const Banner = () => {
  let { url } = useRouteMatch();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [img, setImg] = useState("");
  const [meta, setMeta] = useState({
    page: 0,
    limit: 10,
    totalCount: 0,
    hasMore: true,
  });
  const [filter, setFilter] = useState({
    status: "",
  })

  const filterBanner = async (page, limit) => {
    try {
      setLoading(true);
      let { data } = await Axios({
        method: "GET",
        url: `${api}/admin/banner/filter`,
        params: {
          page,
          limit,
          status: !filter.status ? undefined : filter.status,
        },
        headers: header_token.headers,
      });

      setBanner(data.results ?? [])
      setMeta({
        page: page,
        limit: limit,
        totalCount: data.meta.total_count,
        hasMore: data.meta.has_more,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      swal(msg || "Error: Something wrong.", {
        icon: "error",
        button: false,
      });
    }
  }

  useEffect(() => {
    filterBanner(1, meta.limit);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-none d-md-flex mt-1">
            <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">แบนเนอร์</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">
              {(() => {
                if (url.includes("add")) {
                  return "เพิ่ม";
                } else if (url.includes("edit")) {
                  return "แก้ไข";
                } else {
                  return "ทั้งหมด";
                }
              })()}
            </p>
          </div>

          <button className="btn btn-primary ml-auto" onClick={() => {
            push(`/banners/add`)
          }}>
            <span className="fa fa-plus mr-2"></span>เพิ่มข้อมูล
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8 ">
                  <span className="mr-2 ">ทั้งหมด {meta.totalCount} รายการ</span>
                </div>
                <div className="col-lg p-0">
                  <div className="form-row mb-2 ">
                    <div className="col-lg ml-auto">
                      <div className="form-group" >
                        <select className="form-control" value={filter.status}
                          onChange={(e) => {
                            e.persist();
                            setFilter(prev => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }}>
                          <option value="">สถานนะ</option>
                          <option value="1">ใช้งาน</option>
                          <option value="0">ไม่ใช้งาน</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoadingMask loading={loading} text={"loading..."}>
                <div className="row">
                  <div className="table-responsive">
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th>รูป</th>
                          <th>Landing</th>
                          <th>ประเภท</th>
                          <th>สถานนะ</th>
                          <th className="text-right">ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          banner.length > 0 ? (
                            banner.map(e => {
                              let status;
                              switch (e.status) {
                                case 0:
                                  status = (
                                    <span className="badge badge-danger h5 font-weight-bold">ไม่ใช้งาน</span>
                                  );
                                  break;
                                case 1:
                                  status = (
                                    <span className="badge badge-success h5 font-weight-bold">ใช้งาน</span>
                                  );
                                  break;
                                default:
                                  status = (
                                    <span className="badge badge-small badge-light">-</span>
                                  );
                              }

                              return (
                                <tr key={e.id}>
                                  <td>
                                    <div
                                      className="thumbnail cursor-img"
                                      data-toggle="modal"
                                      data-target=".show-img"
                                      onClick={() => {
                                        setImg(
                                          e.picture === ""
                                            ? "/images/no-img.jpg"
                                            : `${img_url}${e.picture}`
                                        );
                                      }}
                                    >
                                      <img
                                        className="lazy img-responsive"
                                        src={
                                          e.picture === ""
                                            ? "/images/no-img.jpg"
                                            : `${img_url}${e.picture}`
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {
                                      (() => {
                                        switch (e.goto) {
                                          case "news": return "ข่าว";
                                          case "url": return "URL";
                                          case "restaurant": return "ร้านอาหาร";
                                          case "car_service": return "บริการ";
                                          default: return "-"
                                        }
                                      })()
                                    }
                                  </td>
                                  <td>
                                    {e.is_popup ? "ป๊อบอัพ" : "ปกติ"}
                                  </td>
                                  <td>
                                    {status}
                                  </td>
                                  <td className="text-right">
                                    <button className="btn btn-outline-primary mr-1 mb-1 pt-2" onClick={() => {
                                      push(`/banners/edit/${e.id}`)
                                    }}>
                                      <span className="fa fa-edit "></span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={5}>
                                  <div className="text-center">ไม่พบรายการ</div>
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2 float-right">
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={Math.ceil(meta.totalCount / (meta.limit === 0 ? 1 : meta.limit))}
                  onPageChange={(e) => filterBanner(e + 1, meta.limit)}
                  forcePage={meta.page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
