import React, { useState, useEffect } from "react";
import { api, header_token, img_url } from "../../config";
import Axios from "axios";
import ReactPaginate from "react-paginate";

import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { ServiceFeeHead } from "./ServiceFeeHead";
import { Link } from "react-router-dom";
import { ModalShowImage } from "../../ModalShowImage";

export const ServiceFeeRecommend = () => {
  const [loadingAll, setloadingAll] = useState(false);
  const [img, setimg] = useState("");

  const [data, setdata] = useState([]);

  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(99999);
  const [total_count, settotal_count] = useState(0);

  const getData = async () => {
    setloadingAll(true);

    try {
      let { data } = await Axios.get(
        `${api}/service-recommend?status=${["0", "1"].join(",")}`,
        header_token
      );
      // console.log("rec", data);
      if (data) {
        let page_count = Math.ceil(data.length / limit);
        settotal_page(page_count);
        settotal_count(data.length);

        setdata(data);
      }
    } catch (error) {
      console.log(error);
    }
    setloadingAll(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      getData();
    }
  }, [current_page]);

  return (
    <div>
      <ServiceFeeHead />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>
                <div className="row">
                  <Link to={`/service_fee/recommend/add`}>
                    <button
                      className="btn btn-primary  mr-4 ml-auto "
                      style={{ height: 42 }}
                    >
                      + เพิ่มบริการแนะนำ
                    </button>
                  </Link>
                </div>
              </div>

              <LoadingMask loading={loadingAll} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table table-bordered-">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 150 }}>รูป</th>
                          <th style={{ minWidth: 200 }}>ชื่อ</th>
                          <th style={{ minWidth: 200 }}>สถานะ</th>
                          <th style={{ minWidth: 150 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div
                                  className="thumb_banner cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  style={{ backgroundColor: "white" }}
                                  onClick={() => {
                                    setimg(
                                      value.cover === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.cover}`
                                    );
                                  }}
                                >
                                  <img
                                    style={{ height: "50%" }}
                                    className="lazy img-responsive"
                                    src={
                                      value.cover === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.cover}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>{value.title}</td>
                              <td>{value.status === 1 ? "ใช้งาน" : "ระงับ"}</td>
                              <td>
                                <Link to={`/service_fee/recommend/edit/${value.id}`}>
                                  <button className="btn btn-outline-primary btn-sm mb-1">
                                    แก้ไข
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={total_page}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      <ModalShowImage img={img} />
    </div>
  );
};
