import React, { useState, useImperativeHandle, forwardRef } from "react";

export const BranchAddShare = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      setshare_benefit((prevState) => ({
        ...prevState,
        food_cost: parseFloat(share_benefit.food_cost),
        food_delivery: parseFloat(share_benefit.food_delivery),
        shipping_cost: parseFloat(share_benefit.shipping_cost),
        shipping_extra_cost: parseFloat(share_benefit.shipping_extra_cost),
      }));
      props.callbackShare(share_benefit);
    },
  }));

  const [share_benefit, setshare_benefit] = useState({
    food_cost: 0,
    food_delivery: 0,
    shipping_cost: 0,
    shipping_extra_cost: 0,
  });

  return (
    <div>
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="food_cost">ค่าอาหาร(%)</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="food_cost"
            type="text"
            style={{ width: 80 }}
            value={share_benefit.food_cost}
            onChange={(e) => {
              let val = e.target.value;
              setshare_benefit((prevState) => ({
                ...prevState,
                food_cost: val ? val : 0,
              }));
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="food_delivery">ค่าส่งอาหาร(%)</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="food_delivery"
            type="text"
            style={{ width: 80 }}
            value={share_benefit.food_delivery}
            onChange={(e) => {
              let val = e.target.value;
              setshare_benefit((prevState) => ({
                ...prevState,
                food_delivery: val ? val : 0,
              }));
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="shipping_cost">ค่าขนส่ง(%)</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="shipping_cost"
            type="text"
            style={{ width: 80 }}
            value={share_benefit.shipping_cost}
            onChange={(e) => {
              let val = e.target.value;
              setshare_benefit((prevState) => ({
                ...prevState,
                shipping_cost: val ? val : 0,
              }));
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="shipping_extra_cost">บริการเสริมการขนส่ง(%)</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="shipping_extra_cost"
            type="text"
            style={{ width: 80 }}
            value={share_benefit.shipping_extra_cost}
            onChange={(e) => {
              let val = e.target.value;
              setshare_benefit((prevState) => ({
                ...prevState,
                shipping_extra_cost: val ? val : 0,
              }));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <button
              type="button"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                props.nextRef.current.click();
              }}
            >
              ถัดไป
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
