import React, { useContext } from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import { WithdrawHistory } from "./WithdrawHistory";
import { WithdrawOnProcess } from "./WithdrawOnProcess";
import { WithdrawIndex } from "./WithdrawIndex";
import { NotiContext } from "../../store/keep";

export const WithdrawHead = () => {
  const { noti } = useContext(NotiContext);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ถอนเงิน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ถอนเงิน</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/withdraw"
              >
                คำขอใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.withdraw > 0 ? noti.withdraw : ""}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/withdraw/onprocess"
              >
                กำลังดำเนินการ
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/withdraw/history"
              >
                ประวัติ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/withdraw`}>
          <WithdrawIndex />
        </Route>
        <Route exact path={`/withdraw/onprocess`}>
          <WithdrawOnProcess />
        </Route>
        <Route exact path={`/withdraw/history`}>
          <WithdrawHistory />
        </Route>
      </Switch>
    </div>
  );
};
