import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import { api, header_token } from "../../config";

export const RestaurantDetailMenuAddonGroup = (props) => {
  const [loading, setloading] = useState(false);

  const [group_addon, setgroup_addon] = useState([
    {
      id: "",
      name_th: "",
      name_en: "",
      options: [],
      edit: false,
      new: false,
    },
  ]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/restaurants/${props.restaurantId}/add-on`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          name_th: value.name_th,
          name_en: "",
          options: value.options,
          edit: false,
          new: false,
        });
      });
      setgroup_addon(tmp);

      // if (tmp.length > 0) {
      //   setgroup_addon(tmp);
      // }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const addAddonGroup = async (send_data) => {
    try {
      let res = await Axios.post(
        `${api}/restaurants/${props.restaurantId}/add-on`,
        send_data,
        header_token
      );
      // console.log(res);
      getData();
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editAddonGroup = async (restaurantId, addon_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/restaurants/${restaurantId}/add-on/${addon_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteAddonGroup = async (restaurantId, addon_id) => {
    try {
      let res = await Axios.delete(
        `${api}/restaurants/${restaurantId}/add-on/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="row">
        <h4 className="mb-4 mt-3 ml-2">ทั้งหมด {group_addon.length} รายการ</h4>
      </div>

      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="example" className="table ">
            <thead>
              <tr>
                <th>หมวดหมู่</th>
                {
                  !props?.restaurant?.is_sub_brand ?
                    <>
                      <th width="80">Edit</th>
                      <th width="80">Delete</th>
                    </> : <></>
                }
              </tr>
            </thead>
            <tbody>
              {group_addon.map((value, index) => {
                // console.log("in loop", value);
                return (
                  <tr key={index}>
                    <td className="name">
                      {value.edit === true ? (
                        <input
                          type="text"
                          className="form-control"
                          value={value.name_th}
                          onChange={(e) => {
                            let tmp = [...group_addon];
                            tmp[index].name_th = e.target.value.trim();
                            setgroup_addon(tmp);
                          }}
                        />
                      ) : (
                        value.name_th
                      )}
                    </td>
                    {
                      !props?.restaurant?.is_sub_brand ?
                        <>
                          <td className="edit">
                            {value.edit === false ? (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                onClick={() => {
                                  let tmp = [...group_addon];
                                  tmp[index].edit = !tmp[index].edit;
                                  setgroup_addon(tmp);
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                onClick={() => {
                                  let tmp = [...group_addon];
                                  if (tmp[index].name_th) {
                                    if (tmp[index].new === true) {
                                      addAddonGroup(tmp[index]);
                                    } else {
                                      // console.log("edit");
                                      let send_data = {
                                        name_en: value.name_en,
                                        name_th: group_addon[index].name_th.trim(),
                                        options: value.options,
                                      };
                                      // console.log(send_data);
                                      // console.log(value.id);
                                      editAddonGroup(
                                        props.restaurantId,
                                        value.id,
                                        send_data
                                      );
                                    }

                                    // console.log('index',index)
                                    // console.log('tmp',tmp[index])
                                    tmp[index].edit = !tmp[index].edit;
                                    tmp[index].new = false;

                                    setgroup_addon(tmp);
                                  }
                                }}
                              ></i>
                            )}
                          </td>
                          <td className="trash">
                            <i
                              style={{ cursor: "pointer" }}
                              className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                              onClick={() => {
                                swal({
                                  title: `ต้องการลบ  ${value.name_th} ?`,
                                  // text:
                                  //   "Once deleted, you will not be able to recover this imaginary file!",
                                  confirmButtonText: "ลบ",
                                  cancelButtonText: "ยกเลิก",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    let is_delete = deleteAddonGroup(
                                      props.restaurantId,
                                      value.id
                                    );

                                    if (willDelete && is_delete) {
                                      swal("ลบเสร็จสิ้น !!", {
                                        icon: "success",
                                      });
                                    } else {
                                      swal("เกิดข้อผิดพลาด !!", {
                                        icon: "error",
                                      });
                                    }
                                  }
                                });
                              }}
                            ></i>
                          </td>
                        </> : <></>
                    }
                  </tr>
                );
              })}

              {
                !props?.restaurant?.is_sub_brand ?
                  <>
                    <tr>
                      <td
                        className="new-row"
                        colSpan="5"
                        onClick={() => {
                          let tmp = [...group_addon];
                          // if (
                          //   group_addon[group_addon.length - 1].new !== true ||
                          //   group_addon.length === 0
                          // ) {
                          tmp.push({
                            id: "",
                            name_th: "",
                            name_en: "",
                            options: [],
                            edit: true,
                            new: true,
                          });
                          setgroup_addon(tmp);
                          // }
                        }}
                      >
                        <span className="h3">+</span> <span className="h6">Add</span>{" "}
                      </td>
                    </tr>
                  </> : <></>
              }
            </tbody>
          </table>
        </div>
      </LoadingMask>
    </div>
  );
};
