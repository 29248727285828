import React, { useState, useEffect, useRef } from "react";
import { api, header_token, img_url, recommened_options } from "../../config";
import Axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import "moment/locale/th";
import swal from "sweetalert";

export const RestaurantPromotionHistory = () => {
  const checkAllRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [loadingAll, setloadingAll] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [restaurant_option, setrestaurant_option] = useState("");
  const [select_restaurant, setselect_restaurant] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [status, setstatus] = useState("");
  const [promotion_id, setpromotion_id] = useState("");

  const [select_cancel, setselect_cancel] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState("");

  const getData = async () => {
    setloadingAll(true);

    try {
      let send_data = {
        name: filter_name,
        status: filter_status.value
          ? [parseInt(filter_status.value)]
          : [0, 1, 2, 3],
      };
      let { data } = await Axios.post(
        `${api}/admin/restaurants/promotion/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      setdata(data.promotions ? data.promotions : []);
    } catch (error) {
      console.log(error);
    }
    setloadingAll(false);
  };

  const getDataRestaurant = async () => {
    setloading(true);

    let send_data = {
      // name_th: filter_name,
      // status: [0],
    };
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/filter?page=${1}&limit=${limit}`,
        send_data,
        header_token
      );

      let tmp = [];
      data.restaurants.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      // console.log(tmp);
      setrestaurant_option(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const editSelect = async (send_data, promotion_id) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/restaurants/promotion/${promotion_id}`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
        });
        setselect_restaurant("");
        setstart("");
        setend("");
        setstatus("");
        setpromotion_id("");
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const changeStatusSelect = async (send_data, promotion_id) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/restaurants/promotion/${promotion_id}`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
    getDataRestaurant();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_status, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อร้าน"
                      value={filter_name}
                      onChange={(e) => setfilter_name(e.target.value)}
                    />
                  </div>

                  <div className="col">
                    <div className="row mr-2">
                      <div style={{ width: 150 }}>
                        <span>สถานะ</span>
                        <Select
                          value={filter_status}
                          onChange={async (e) => {
                            setfilter_status(e);
                          }}
                          options={recommened_options}
                          placeholder={"สถานะ..."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row  mr-2">
                      <div style={{ width: 150 }}>
                        <span>ดำเนินการ</span>
                        <Select
                          value={select_cancel}
                          onChange={(e) => {
                            setselect_cancel(e);
                          }}
                          options={recommened_options}
                          placeholder={"ดำเนินการ..."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="row">
                      <button
                        className="btn btn-primary  ml-auto mt-4 mr-2"
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          if (select_cancel.value && count.length > 0) {
                            swal({
                              title: `ดำเนินการที่เลือกหรือไม่ ?`,
                              confirmButtonText: "ใช่",
                              cancelButtonText: "ไม่",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    // console.log(value);
                                    let send_data = {
                                      begin: moment(value.begin).format(
                                        "YYYY-MM-DD"
                                      ),
                                      end: moment(value.end).format(
                                        "YYYY-MM-DD"
                                      ),
                                      status: parseInt(select_cancel.value),
                                    };

                                    sending.push(
                                      changeStatusSelect(send_data, value.id)
                                    );
                                  }
                                });

                                let is_success = true;
                                await Promise.all(sending).then((res) => {
                                  // console.log(res);
                                  if (
                                    res.filter((e) => e === true).length ===
                                      0 ||
                                    res.length === 0
                                  )
                                    is_success = false;
                                });

                                if (is_success) {
                                  getData();

                                  swal("เสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else {
                            alert("เลือกตัวดำเนินการ");
                          }
                        }}
                      >
                        ดำเนินการ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <LoadingMask loading={loadingAll} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table ">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 30 }}>
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, index) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });

                                setdata(tmp);
                              }}
                            />
                          </th>
                          <th style={{ minWidth: 50 }}>รูป</th>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 200 }}>ชื่อ</th>
                          <th style={{ minWidth: 250 }}>ที่อยู่</th>
                          <th style={{ minWidth: 180 }}>เบอร์โทร</th>
                          <th style={{ minWidth: 120 }}>อำเภอ</th>
                          <th style={{ minWidth: 120 }}>จังหวัด</th>
                          <th style={{ minWidth: 100 }}>เครดิต</th>
                          <th style={{ minWidth: 180 }}>ประเภทอาหาร</th>
                          <th style={{ minWidth: 120 }}>วันที่เริ่ม</th>
                          <th style={{ minWidth: 120 }}>วันที่สิ้นสุด</th>
                          <th style={{ minWidth: 80 }}>สถานะ</th>
                          <th style={{ minWidth: 100 }}>เพิ่มเติม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ minWidth: 30 }}>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.restaurant.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.restaurant.picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.restaurant.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.restaurant.picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>{value.restaurant.restaurant_id}</td>
                              <td>
                                <Link
                                  to={`/restaurant/detail/${value.restaurant.id}/history`}
                                >
                                  <span>{value.restaurant.name_th}</span>
                                </Link>
                              </td>
                              <td>{value.restaurant.location.address}</td>
                              <td>{value.restaurant.phone_numbers}</td>
                              <td>
                                {value.restaurant.location.district.name_th}
                              </td>
                              <td>
                                {value.restaurant.location.province.name_th}
                              </td>
                              <td className="text-success dt-cradit">
                                ฿{value.restaurant.credit.toLocaleString()}
                              </td>
                              <td>
                                {value.restaurant.categories.map((v, idx) => {
                                  let comma = "";
                                  if (
                                    idx !==
                                    value.restaurant.categories.length - 1
                                  )
                                    comma = ", ";
                                  return (
                                    <span key={idx}>{v.title + comma}</span>
                                  );
                                })}
                              </td>
                              <td>
                                {" "}
                                {moment(value.begin).format("DD/MM/YYYY")}
                              </td>
                              <td>{moment(value.end).format("DD/MM/YYYY")}</td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ยกเลิก
                                        </div>
                                      );
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );

                                    case 2:
                                      return (
                                        <div className="badge badge-secondary">
                                          ระงับ
                                        </div>
                                      );
                                    case 3:
                                      return (
                                        <div className="badge badge-danger">
                                          หมดอายุ
                                        </div>
                                      );

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <button
                                  data-toggle="modal"
                                  data-target=".edit-row"
                                  className="btn btn-outline-primary btn-sm mb-1"
                                  onClick={() => {
                                    // console.log(value.restaurant)
                                    setselect_restaurant({
                                      value: value.restaurant.id,
                                      label: value.restaurant.name_th,
                                    });
                                    setstart(moment(value.begin).toDate());
                                    setend(moment(value.end).toDate());
                                    setstatus(
                                      recommened_options.filter(
                                        (e) =>
                                          parseInt(e.value) === parseInt(value.status)
                                      )[0]
                                    );

                                    setpromotion_id(value.id);
                                  }}
                                >
                                  แก้ไข
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={total_page}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal  edit-row" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>แก้ไขร้านค้าที่ร่วมโปรโมชั่นค่าส่ง</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">เลือกร้านค้า</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-11">
                      <div className="side-by-side clearfix">
                        <LoadingMask loading={loading} text={"loading..."}>
                          <Select
                            value={select_restaurant}
                            onChange={async (e) => {
                              setselect_restaurant(e);
                            }}
                            options={restaurant_option}
                            placeholder={"ค้นหา..."}
                            onKeyDown={async (e) => {
                              let val = e.target.value;
                              setTimeout(async () => {
                                await setfilter_name(val);
                              }, 50);
                            }}
                            isClearable={true}
                            isDisabled
                          />
                        </LoadingMask>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">กำหนดระยะเวลา</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-5">
                      <DayPickerInput
                        value={start}
                        onDayChange={(e) => {
                          setstart(e);
                          if (!end) {
                            setend(e);
                          }
                        }}
                        placeholder={"วัน/เดือน/ปี"}
                        style={{ width: "100%" }}
                        inputProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        format="L"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          locale: "th",
                          localeUtils: MomentLocaleUtils,
                        }}
                      />
                    </div>
                    <div className="col-1 text-center">ถึง</div>
                    <div className="col-5">
                      <DayPickerInput
                        value={end}
                        onDayChange={(e) => {
                          setend(e);
                          if (!start) {
                            setstart(e);
                          }
                        }}
                        // overlayComponent={CustomOverlay}
                        placeholder={"วัน/เดือน/ปี"}
                        style={{ width: "100%" }}
                        inputProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        format="L"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          locale: "th",
                          localeUtils: MomentLocaleUtils,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-3 pl-3">
                  <label htmlFor="name">สถานะ</label>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-11">
                      <div className="side-by-side clearfix">
                        <Select
                          value={status}
                          onChange={async (e) => {
                            setstatus(e);
                          }}
                          options={recommened_options}
                          placeholder={"เลือก..."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="row">
                    <button
                      className="btn btn-outline-primary mr-2 ml-auto mt-2 "
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2  mt-2 "
                      onClick={() => {
                        let send_data = {
                          begin: moment(start, "DD/MM/YYYY").format(
                            "YYYY-MM-DD"
                          ),
                          end: moment(end, "DD/MM/YYYY").format("YYYY-MM-DD"),
                          status: parseInt(status.value),
                        };
                        editSelect(send_data, promotion_id);
                      }}
                    >
                      แก้ไข
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
