import React from "react";
import { useRouteMatch } from "react-router-dom";

export const BrandHeader = () => {
  let { url } = useRouteMatch();
  return (
    <div className="dashboard-header d-flex flex-column grid-margin">
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="d-flex align-items-center">
          <h4 className="mb-0 font-weight-bold mr-2">
            แบรนด์
          </h4>
          <div className="d-none d-md-flex mt-1">
            <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">
              {(() => {
                if (url.includes("add")) {
                  return "เพิ่ม";
                } else if (url.includes("edit")) {
                  return "แก้ไข";
                } else if (url.includes("detail")) {
                  return "รายละเอียด";
                } else {
                  return "ทั้งหมด";
                }
              })()}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}