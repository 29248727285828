import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import { api, header_token, img_url } from "../../config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import swal from "sweetalert";

export const NewsIndex = () => {
  const checkAllRef = useRef(null);
  const [loading, setloading] = useState(false);
  const [keyword, setkeyword] = useState("");
  const [img, setimg] = useState("");
  const [news_detail, setnews_detail] = useState("");

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [action, setaction] = useState("");

  const getData = async () => {
    setloading(true);

    try {
      let send_data = {
        effective_date: "",
        keyword: keyword,
        status: [0, 1, 2],
      };

      let { data } = await Axios.post(
        `${api}/admin/news/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      setdata(data.news ? data.news : []);
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  const deleteNews = async (news_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/news/${news_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [current_page, keyword]);
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประกาศข่าว</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ประกาศข่าว</p>
            </div>
          </div>
          <div className="button-wrapper d-flex align-items-center mt-md-3 mt-xl-0">
            <Link to={`/news/add`}>
              <button
                type="button"
                className="btn btn-primary mr-2"
              >
                + เพิ่มข้อมูล
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-12 mb-2">
          <div className="form-row align-items-center mb-2 ml-auto">
            <h4 className="mt-4 ml-1">
              ทั้งหมด {total_count.toLocaleString()} รายการ
            </h4>
            <div className="col-sm-2 mr-1 ml-auto">
              <span>ค้นหา</span>
              <input
                type="text"
                className="form-control- global_filter"
                id="global_filter"
                placeholder="รายละเอียด"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
              />
            </div>
            <div className="col-sm-2 mr-1 ">
              <span>ดำเนินการ</span>
              <Select
                value={action}
                onChange={async (e) => {
                  setaction(e);
                }}
                options={[{ value: "delete", label: "ลบ" }]}
                placeholder={"เลือก..."}
              />
            </div>

            <div className="mr-1">
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: 23 }}
                onClick={async () => {
                  let count = data.filter((e) => e.checked === true);
                  if (action.value === "delete" && count.length > 0) {
                    swal({
                      title: `ต้องการลบข่าวที่เลือกหรือไม่ ?`,
                      confirmButtonText: "ลบ",
                      cancelButtonText: "ยกเลิก",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willDelete) => {
                      if (willDelete) {
                        let sending = [];
                        data.forEach((value) => {
                          if (value.checked === true) {
                            sending.push(deleteNews(value.id));
                          }
                        });

                        let is_success = true;
                        await Promise.all(sending).then((res) => {
                          console.log(res);
                          if (
                            res.filter((e) => e === true).length === 0 ||
                            res.length === 0
                          )
                            is_success = false;
                        });

                        if (is_success) {
                          getData();
                          swal("ลบเสร็จสิ้น !!", {
                            icon: "success",
                          });
                        } else {
                          swal("เกิดข้อผิดพลาด !!", {
                            icon: "error",
                          });
                        }
                      }
                    });
                  } else {
                    alert("เลือกตัวดำเนินการ");
                  }
                }}
              >
                ดำเนินการ
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 30 }}>
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, index) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });

                                setdata(tmp);
                              }}
                            />
                          </th>
                          <th style={{ minWidth: 150 }}>วันที่ส่ง</th>
                          <th style={{ minWidth: 200 }}>หัวข้อ</th>
                          <th style={{ minWidth: 180 }}>รายละเอียด</th>
                          <th style={{ minWidth: 100 }}> รูปแนบ</th>
                          <th style={{ minWidth: 120 }}>วันที่สร้าง</th>
                          <th style={{ minWidth: 200 }}>หมายเหตุ</th>
                          <th style={{ minWidth: 120 }}>สถานะ</th>
                          <th style={{ minWidth: 120 }}>ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                {moment(value.effective_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{value.title}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm "
                                  data-toggle="modal"
                                  data-target=".show-detail"
                                  onClick={() => {
                                    setnews_detail(value.description);
                                  }}
                                >
                                  รายละเอียด
                                </button>
                              </td>
                              <td>
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.cover === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.cover}`
                                    );
                                  }}
                                >
                                  <img
                                    className="lazy img-responsive"
                                    src={
                                      value.cover === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.cover}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}
                              </td>
                              <td>{value.note}</td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ยกเลิก
                                        </div>
                                      );
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ส่งแล้ว
                                        </div>
                                      );

                                    case 2:
                                      return (
                                        <div className="badge badge-secondary">
                                          รอส่ง
                                        </div>
                                      );

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <Link
                                  to={`/news/${value.id}`}
                                  type="button"
                                  className="btn btn-primary btn-sm mb-1"
                                >
                                  แก้ไข
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={<span className="gap">...</span>}
                      pageCount={total_page}
                      onPageChange={(e) => setcurrent_page(e.selected + 1)}
                      forcePage={current_page - 1}
                      containerClassName={"pagination"}
                      previousLinkClassName={"previous_page"}
                      nextLinkClassName={"next_page"}
                      disabledClassName={"disabled"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal  show-detail" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800, maxHeight: 700 }}>
            <div className="modal-header">
              <span>รายละเอียดข่าว</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ padding: 0 }}>
              <div
                style={{
                  overflow: "scroll",
                  width: "100%",
                  height: 600,
                  padding: 20,
                }}
              >
                <div
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: news_detail,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
