import React, { useState, useRef } from "react";
import Axios from "axios";

import { BranchAddProfile } from "./BranchAddProfile";
import { BranchAddShare } from "./BranchAddShare";
import { BranchAddAdmin } from "./BranchAddAdmin";

import { api, header_token } from "../../config";
import swal from "sweetalert";
import { BranchHead } from "./BranchHead";
import { BranchAddArea } from "./BranchAddArea";

export const BranchAdd = () => {
  const [data, setdata] = useState({
    data: {
      area_id: [],
      email: "",
      full_name: "",
      location: {
        address: "",
        district_id: "",
        latitude: 0,
        longitude: 0,
        province_id: "",
        sub_district_id: "",
      },
      name_en: "",
      name_th: "",
      personal_number: "",
      phone_number: "",
      picture: "",
      provider: {
        password: "",
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: "",
      },
      register_date: "",
      status: 0,
      thumbnail: "",
      share_additional_express_percentage: 0,
      share_express_percentage: 0,
      share_food_percentage: 0,
      share_food_shipping_percentage: 0,
      url: "",
    },
  });

  const profileRef = useRef(null);
  const areaRef = useRef(null);
  const shareRef = useRef(null);
  const adminRef = useRef(null);

  const profileComponentRef = useRef(null);
  const shareComponentRef = useRef(null);
  const areaComponentRef = useRef(null);
  const adminComponentRef = useRef(null);

  const callbackProfile = async (receive_data) => {
    let tmp = { ...data };
    // console.log("receive_data", receive_data);

    tmp.data.name_th = receive_data.name_th.trim();
    tmp.data.picture = receive_data.image;
    tmp.data.phone_number = receive_data.phone_number.trim();
    tmp.data.full_name = receive_data.contact_name.trim();
    tmp.data.personal_number = receive_data.personal_number.trim();
    tmp.data.email = receive_data.email.trim();
    tmp.data.location.address = receive_data.address.trim();
    tmp.data.location.sub_district_id = receive_data.sub_district_id;
    tmp.data.location.district_id = receive_data.district_id;
    tmp.data.location.province_id = receive_data.province_id;
    tmp.data.register_date = receive_data.register_date;
    tmp.data.status = receive_data.status;

    // console.log("tmp", tmp);

    setdata({ ...tmp });

    // console.log("profile", data);
  };

  const callbackArea = (receive_data) => {
    let tmp = { ...data };
    // console.log("callbackArea", receive_data);

    const unique = [...new Set(receive_data.map((item) => item.area))];

    // console.log("unique", unique[0] !== "" ? unique : []);
    let val = [];
    unique.forEach((v) => {
      if (v.value) {
        val.push(v.value);
      }
    });
    tmp.data.area_id = val;

    // console.log(tmp);
    setdata(tmp);

    // console.log("callbackShare", tmp);
  };

  const callbackShare = (receive_data) => {
    let tmp = { ...data };
    tmp.data.share_additional_express_percentage = parseFloat(
      receive_data.shipping_extra_cost
    );
    tmp.data.share_express_percentage = parseFloat(receive_data.shipping_cost);
    tmp.data.share_food_percentage = parseFloat(receive_data.food_cost);
    tmp.data.share_food_shipping_percentage = parseFloat(
      receive_data.food_delivery
    );
    setdata(tmp);

    // console.log("callbackShare", tmp);
  };

  const callbackAdmin = async (receive_data) => {
    let tmp = { ...data };
    //  tmp.data.email = receive_data.email;
    tmp.data.provider.username = receive_data.email.trim();
    tmp.data.provider.password = receive_data.password;
    tmp.data.provider.provider_name = "password";

    setdata(tmp);

    // console.log("admin", data);
  };

  // /v1/admin/branch/area/

  const addAreaToBranch = async (area_data) => {
    try {
      let res = await Axios.post(
        `${api}/admin/branch/area/`,
        area_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  const submit = async (send_data) => {
    console.log("send_data", send_data);
    try {
      // let res;
      let res = await Axios.post(
        `${api}/admin/branch`,
        send_data.data,
        header_token
      );
      console.log(res);

      let branch_id = res.data.id;
      let area_sending = [];

      send_data.data.area_id.forEach((v, idx) => {
        area_sending.push(
          addAreaToBranch({
            areaId: v,
            branchId: branch_id,
          })
        );
      });

      let is_success = true;
      await Promise.all(area_sending).then((res) => {
        if (res.filter((e) => e === true).length === 0 || res.length === 0)
          is_success = false;

          console.log(res);
      });

      console.log("area_sending", area_sending);

      if (res.status === 201 && is_success) {
        // console.log("okkk");
        swal("สร้างสาขาเสร็จสิ้น !!", {
          icon: "success",
        });
        // history.push("/restaurant");
        window.location = "/branch";
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      // console.log(;
    }
  };
  // const setopen_day = (receive) => {
  //   let tmp = data;
  //  tmp.data.open_day = receive;
  //   setdata(data);

  //   console.log(data);
  // };
  return (
    <div>
      <BranchHead />
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                    ref={profileRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      areaComponentRef.current.sendToParent();
                      shareComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    ข้อมูลสาขา
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="area_branch-tab"
                    data-toggle="tab"
                    href="#area_branch"
                    role="tab"
                    aria-controls="area_branch"
                    aria-selected="false"
                    ref={areaRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      areaComponentRef.current.sendToParent();
                      shareComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    พื้นที่
                  </a>
                </li>

                <li className="nav-item ">
                  <a
                    className="nav-link "
                    id="location-tab"
                    data-toggle="tab"
                    href="#location"
                    role="tab"
                    aria-controls="location"
                    aria-selected="false"
                    ref={shareRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      areaComponentRef.current.sendToParent();
                      shareComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    ส่วนแบ่งรายได้
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="admin-tab"
                    data-toggle="tab"
                    href="#admin"
                    role="tab"
                    aria-controls="admin"
                    aria-selected="false"
                    ref={adminRef}
                    onClick={() => {
                      profileComponentRef.current.sendToParent();
                      areaComponentRef.current.sendToParent();
                      shareComponentRef.current.sendToParent();
                      adminComponentRef.current.sendToParent();
                    }}
                  >
                    ตั้งค่า Admin
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane show active"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <BranchAddProfile
                    ref={profileComponentRef}
                    nextRef={areaRef}
                    callbackProfile={callbackProfile}
                  />
                </div>

                <div
                  className="tab-pane "
                  id="area_branch"
                  role="tabpanel"
                  aria-labelledby="area_branch-tab"
                >
                  <BranchAddArea
                    ref={areaComponentRef}
                    nextRef={shareRef}
                    callbackArea={callbackArea}
                  />
                </div>

                <div
                  className="tab-pane  "
                  id="location"
                  role="tabpanel"
                  aria-labelledby="location-tab"
                >
                  <BranchAddShare
                    ref={shareComponentRef}
                    nextRef={adminRef}
                    callbackShare={callbackShare}
                  />
                </div>

                <div
                  className="tab-pane "
                  id="admin"
                  role="tabpanel"
                  aria-labelledby="admin-tab"
                >
                  <BranchAddAdmin
                    ref={adminComponentRef}
                    callbackAdmin={callbackAdmin}
                    submit={submit}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
