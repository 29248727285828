import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { BranchEditProfile } from "./BranchEditProfile";
import { BranchEditArea } from "./BranchEditArea";
import { BranchEditShare } from "./BranchEditShare";
import { BranchEditAdmin } from "./BranchEditAdmin";
import { useHistory, useParams } from "react-router-dom";

import { api, header_token } from "../../config";
import swal from "sweetalert";

export const BranchEdit = () => {
  const { push } = useHistory();
  const { id } = useParams();

  const [data, setdata] = useState({
    data: {
      area_id: "",
      categories_id: [""],
      email: "",
      full_name: "",
      location: {
        address: "",
        district_id: "",
        latitude: 0,
        longitude: 0,
        province_id: "",
        sub_district_id: "",
      },
      name_en: "",
      name_th: "",
      personal_number: "",
      phone_number: "",
      picture: "",
      provider: {
        password: "",
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: "",
      },
      register_date: "",
      status: 0,
      thumbnail: "",
      url: "",
      share_additional_express_percentage: 0,
      share_express_percentage: 0,
      share_food_percentage: 0,
      share_food_shipping_percentage: 0,
    },
  });

  const [area_data, setarea_data] = useState([])


  const profileRef = useRef(null);
  const areaRef = useRef(null);
  const shareRef = useRef(null);
  const adminRef = useRef(null);

  const profileComponentRef = useRef(null);
  const shareComponentRef = useRef(null);
  const areaComponentRef = useRef(null);
  const adminComponentRef = useRef(null);
  const [parentData, setparentData] = useState({});
  const [trigger, settrigger] = useState(false);

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/branch/${id}`, header_token);
      // console.log("from get", data);
      setparentData(data);
      let tmp = { ...data };
      tmp.data = data;
      settrigger(!trigger);
      // setdata(tmp);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getDataArea = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/area/branch/${id}`,
        header_token
      );
      // console.log(data);
      setarea_data(data);


    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const callbackProfile = async (receive_data) => {
    let tmp = { ...data };
    // console.log("receive_data", receive_data);

    // console.log("test ", tmp.data.name_th);
    tmp.data.name_th = receive_data.name_th.trim();
    tmp.data.picture = receive_data.image;
    tmp.data.phone_number = receive_data.phone_number.trim();
    tmp.data.full_name = receive_data.contact_name.trim();
    tmp.data.personal_number = receive_data.personal_number.trim();
    tmp.data.email = receive_data.email.trim();
    tmp.data.location.address = receive_data.address.trim();
    tmp.data.location.sub_district_id = receive_data.sub_district_id;
    tmp.data.location.district_id = receive_data.district_id;
    tmp.data.location.province_id = receive_data.province_id;
    tmp.data.area_id = receive_data.area_id;
    tmp.data.categories_id = receive_data.categories_id;
    tmp.data.register_date = receive_data.register_date;

    tmp.data.credit = receive_data.credit;
    tmp.data.status = receive_data.status;
    tmp.data.type = receive_data.type;
    // tmp.data.begin_type = receive_data.begin_type;
    // tmp.data.end_type = receive_data.end_type;
    // console.log("tmp", tmp);

    setdata({ ...tmp });

    // console.log("profile", data);
  };

  const callbackShare = (receive_data) => {
    let tmp = { ...data };
    tmp.data.share_additional_express_percentage = parseFloat(
      receive_data.shipping_extra_cost
    );
    tmp.data.share_express_percentage = parseFloat(receive_data.shipping_cost);
    tmp.data.share_food_percentage = parseFloat(receive_data.food_cost);
    tmp.data.share_food_shipping_percentage = parseFloat(
      receive_data.food_delivery
    );
    setdata(tmp);
  };

  const callbackAdmin = async (receive_data) => {
    let tmp = { ...data };

    // tmp.data.email = receive_data.email;
    tmp.data.provider.username = receive_data.email.trim();
    tmp.data.provider.password = receive_data.password;
    tmp.data.provider.provider_name = "password";

    setdata(tmp);

    // console.log("admin", data);
  };

  const submit = async (send_data, message) => {
    try {
      // console.log("send_data", send_data);
      let res = await Axios.put(
        `${api}/admin/branch/${id}`,
        send_data.data,
        header_token
      );
      // console.log(res);
      if (res.status === 201 || res.status === 200) {
        // console.log("okkk");
        swal("แก้ไขสาขาเสร็จสิ้น !!", {
          icon: "success",
          timer: 900,
        });
        setTimeout(() => {
          push(`/branch/detail/${id}/history`);
        }, 1000);
        //localhost:3000/restaurant/detail/5f3a44f2ef41ff9eb70e327a/history
        // http: window.location = `/restaurant/detail/${id}/history`;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
    getDataArea()
  }, []);

  useEffect(() => {
    shareComponentRef.current.sendToParent();
  }, [trigger]);

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="true"
                  ref={profileRef}
                  onClick={() => {
                    profileComponentRef.current.sendToParent();
                    shareComponentRef.current.sendToParent();
                    adminComponentRef.current.sendToParent();
                  }}
                >
                  ข้อมูลสาขา
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link"
                  id="area_branch-tab"
                  data-toggle="tab"
                  href="#area_branch"
                  role="tab"
                  aria-controls="area_branch"
                  aria-selected="false"
                  ref={areaRef}
                  onClick={() => {
                    profileComponentRef.current.sendToParent();
                    shareComponentRef.current.sendToParent();
                    adminComponentRef.current.sendToParent();
                  }}
                >
                  พื้นที่
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="share-tab"
                  data-toggle="tab"
                  href="#share"
                  role="tab"
                  aria-controls="share"
                  aria-selected="false"
                  ref={shareRef}
                  onClick={() => {
                    profileComponentRef.current.sendToParent();
                    shareComponentRef.current.sendToParent();
                    adminComponentRef.current.sendToParent();
                  }}
                >
                  ส่วนแบ่งรายได้
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="admin-tab"
                  data-toggle="tab"
                  href="#admin"
                  role="tab"
                  aria-controls="admin"
                  aria-selected="false"
                  ref={adminRef}
                  onClick={() => {
                    profileComponentRef.current.sendToParent();
                    shareComponentRef.current.sendToParent();
                    adminComponentRef.current.sendToParent();
                  }}
                >
                  ตั้งค่า Admin
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane show active"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <BranchEditProfile
                  parentData={parentData}
                  ref={profileComponentRef}
                  nextRef={areaRef}
                  callbackProfile={callbackProfile}
                  submit={submit}
                  data={data}
                />
              </div>
              <div
                className="tab-pane  "
                id="area_branch"
                role="tabpanel"
                aria-labelledby="area_branch-tab"
              >
                <BranchEditArea
                  parentData={area_data}
                  branchId={id}
                  ref={areaComponentRef}
                  nextRef={shareRef}
                  submit={submit}
                  data={data}
                />
              </div>
              <div
                className="tab-pane "
                id="share"
                role="tabpanel"
                aria-labelledby="share-tab"
              >
                <BranchEditShare
                  parentData={parentData}
                  ref={shareComponentRef}
                  nextRef={adminRef}
                  callbackShare={callbackShare}
                  submit={submit}
                  data={data}
                />
              </div>
              <div
                className="tab-pane "
                id="admin"
                role="tabpanel"
                aria-labelledby="admin-tab"
              >
                <BranchEditAdmin
                  parentData={parentData}
                  ref={adminComponentRef}
                  callbackAdmin={callbackAdmin}
                  submit={submit}
                  data={data}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
