import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportSaleBillShippingBranch = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Branch_Sale_Shipping_Export_${moment(new Date()).format("DD-MM-YYYY")}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Branch Shipping Sales">
        <ExcelColumn label="ชื่อสาขา" value="name_th" />
        <ExcelColumn label="ค่าอาหาร" value="total_sales" />
        <ExcelColumn
          label="คอมมิชชั่นค่าอาหาร"
          value="total_sales_commission"
        />
        <ExcelColumn label="ค่าจัดส่ง" value="total_addon_cost" />
        <ExcelColumn
          label="คอมมิชชั่นค่าส่ง"
          value="total_commission_addon_cost"
        />
        <ExcelColumn label="ส่วนแบ่งรายได้" value="total_share" />
      </ExcelSheet>
    </ExcelFile>
  );
};
