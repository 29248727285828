import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import $ from "jquery";
import swal from "sweetalert";
import { AsyncPaginate } from "react-select-async-paginate";
import { api, header_token, image_size, img_url } from "../../config";
import Axios from "axios";
import { toBase64 } from "../../Helper/format";

export const BannerForm = ({ mode }) => {
  let { url } = useRouteMatch();
  const { push } = useHistory();
  let { id } = useParams();
  const [banner, setBanner] = useState({
    picture: "",
    goto: "",
    goto_ref: "",
    status: 1,
    is_popup: false,
    submit: false,
  });
  const [loading, setLoading] = useState(false);
  const [gotoRef, setGotoRef] = useState();
  const maxWidth = [1000, 500];
  const maxHeight = [560, 780];
  let drEvent = null;
  const initDropify = (defaultFile, w, h) => {
    if (!drEvent) {
      drEvent = $('.dropify').dropify();
      drEvent = drEvent.data('dropify');
    }
    drEvent.resetPreview();
    drEvent.clearElement();
    drEvent.settings.defaultFile = !!defaultFile ? `${img_url}${defaultFile}` : "";
    drEvent.settings.height = 400;
    drEvent.settings.allowedFileExtensions = ["jpg", "png", "jpeg"]
    drEvent.settings.maxFileSize = "3M"
    drEvent.settings.maxHeight = h + 1;
    drEvent.settings.maxWidth = w + 1;
    drEvent.settings.minHeight = h - 1;
    drEvent.settings.minWidth = w - 1;
    drEvent.settings.error = {
      ...drEvent.settings.error,
      'minWidth': `จำกัดขนาดความกว้างของรูปที่ ${w}px`,
      'maxWidth': `จำกัดขนาดความกว้างของรูปที่ ${w}px`,
      'minHeight': `จำกัดขนาดความสูงของรูปที่ ${h}px`,
      'maxHeight': `จำกัดขนาดความสูงของรูปที่ ${h}px`,
    }
    drEvent.destroy();
    drEvent.init();
    $(".dropify-wrapper").css("height", 400);
  }

  const getBannerById = async () => {
    try {
      setLoading(true);
      let { data } = await Axios({
        method: "GET",
        url: `${api}/banner/${id}`,
        headers: header_token.headers,
      });
      setBanner((prev) => ({
        ...prev,
        ...data
      }))

      initDropify(data.picture, maxWidth[data.is_popup ? 1 : 0], maxHeight[data.is_popup ? 1 : 0])
      console.log(data)
      switch (data.goto) {
        case "news": {
          if (data.goto_ref === "") break;
          let result = await Axios.get(`${api}/admin/news/${data.goto_ref}`, header_token);
          setGotoRef({ value: result?.data?.id ?? "", label: result?.data?.title });
          break
        }
        case "restaurant": {
          if (data.goto_ref === "") break;
          let result = await Axios.get(`${api}/restaurants/${data.goto_ref}`, header_token);
          setGotoRef({ value: result?.data?.id ?? "", label: result?.data?.name_th });
          break
        }
        default:
          setGotoRef(data.goto_ref);
          break
      }
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      swal(msg || "Error: Something wrong.", {
        icon: "error",
        button: false,
      });
      push(`/banners`)
    }
  }

  const createBanner = async (payload) => {
    try {
      if (payload.picture === "") {
        swal("กรุณาใส่รูป", {
          icon: "warning",
        });
        return;
      }

      setLoading(true);
      let { data } = await Axios.post(`${api}/admin/banner`, payload, header_token);
      setLoading(false);
      swal("สำเร็จ !!", {
        icon: "success",
      });
      setTimeout(() => {
        push(`/banners`)
      }, 500)
    } catch (e) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      swal(msg || "Error: Something wrong.", {
        icon: "error",
        button: false,
      });
    }
  }

  const updateBannerById = async (payload) => {
    try {
      if (payload.picture === "") {
        swal("กรุณาใส่รูป", {
          icon: "warning",
        });
        return;
      }

      setLoading(true);

      let { data } = await Axios.put(`${api}/admin/banner/${id}`, payload, header_token);

      setLoading(false);
      swal("สำเร็จ !!", {
        icon: "success",
      });
      setTimeout(() => {
        push(`/banners`)
      }, 500)
    } catch (e) {
      setLoading(false);
      let msg;
      if (typeof e?.response?.data?.message === "string") {
        msg = e?.response?.data?.message;
      }
      swal(msg || "Error: Something wrong.", {
        icon: "error",
        button: false,
      });
    }
  }

  const filterNews = async (search, loadedOptions, additional) => {
    try {

      let { data } = await Axios.post(
        `${api}/admin/news/filter?page=${additional.page}&limit=${10}`,
        {
          keyword: search,
          status: [1],
        },
        header_token
      );
      return ({
        options: data.results?.map(e => ({ value: e.id, label: e.title })) ?? [],
        hasMore: data.meta.has_more,
        additional: {
          page: additional.page + 1,
        },
      });

    } catch (e) {
      return ({
        options: [],
        hasMore: false,
        additional: {
          page: 2,
        },
      });
    }
  }

  const filterRestaurant = async (search, loadedOptions, additional) => {
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/filter?page=${additional.page}&limit=${10}`,
        {
          name_th: search,
          status: [1],
        },
        header_token
      );
      return ({
        options: data.restaurants?.map(e => ({ value: e.id, label: e.name_th })) ?? [],
        hasMore: data.meta.has_more,
        additional: {
          page: additional.page + 1,
        },
      });

    } catch (e) {
      return ({
        options: [],
        hasMore: false,
        additional: {
          page: 2,
        },
      });
    }
  }

  const renderLandingOpts = (goto) => {
    switch (goto) {
      case "url": {
        return (
          <div className="form-group">
            <input
              className="form-control"
              value={gotoRef}
              onChange={(e) => {
                e.persist();
                setGotoRef(e.target.value)
                setBanner(prev => ({
                  ...prev,
                  goto_ref: e.target.value
                }))
              }}
            />
          </div>
        )
      }
      case "news": {
        return (
          <div className="form-group">
            <AsyncPaginate
              debounceTimeout={500}
              value={gotoRef}
              additional={{
                page: 1,
              }}
              styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    textOverflow: "ellipsis",
                  }
                }
              }}
              loadOptions={filterNews}
              onChange={(e) => {
                setGotoRef(e)
                setBanner(prev => ({
                  ...prev,
                  goto_ref: e.value
                }))
              }}
            />
          </div>
        )
      }
      case "car_service": {
        return (
          <div className="form-group">
            <select
              className="form-control"
              value={gotoRef}
              onChange={(e) => {
                e.persist();
                setGotoRef(e.target.value)
                setBanner(prev => ({
                  ...prev,
                  goto_ref: e.target.value
                }))
              }}
            >
              <option value={"111111111111111111111111"}>ขนส่ง</option>
              <option value={"222222222222222222222222"}>เมสเซนเจอร์</option>
            </select>
          </div>
        )
      }
      case "restaurant": {
        return (
          <div className="form-group">
            <AsyncPaginate
              debounceTimeout={500}
              value={gotoRef}
              additional={{
                page: 1,
              }}
              styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    textOverflow: "ellipsis",
                  }
                }
              }}
              loadOptions={filterRestaurant}
              onChange={(e) => {
                setGotoRef(e)
                setBanner(prev => ({
                  ...prev,
                  goto_ref: e.value
                }))
              }}
            />
          </div>
        )
      }
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (mode === "new") {
      initDropify(undefined, maxWidth[0], maxHeight[0])
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === "edit") {
      getBannerById();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-none d-md-flex mt-1">
            <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">แบนเนอร์</p>
            <i className="mdi mdi-chevron-right text-muted"></i>
            <p className="text-muted mb-0 tx-13 cursor-pointer">
              {(() => {
                if (url.includes("add")) {
                  return "เพิ่ม";
                } else if (url.includes("edit")) {
                  return "แก้ไข";
                } else {
                  return "ทั้งหมด";
                }
              })()}
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" >
              <form onSubmit={(e) => {
                e.preventDefault();
                if (mode === "new") {
                  createBanner({
                    ...banner
                  });
                } else {
                  updateBannerById({
                    ...banner
                  });
                }
              }}>
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">รูป</label>
                  <div className="col-sm-5">
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        onChange={(e) => {
                          let reader = new FileReader();
                          reader.readAsDataURL(e.target.files[0]);
                          reader.onload = async function () {
                            setBanner(prev => ({
                              ...prev,
                              picture: reader.result,
                            }))
                          };
                        }}
                        className="dropify"
                      />

                      <div>
                        <div className="text-danger text-small">* จำกัดขนาดความกว้างของรูปปกติที่ {maxWidth[0]}px</div>
                        <div className="text-danger text-small">* จำกัดขนาดความสูงของรูปปกติที่ {maxHeight[0]}px</div>
                        <div className="text-danger text-small">* จำกัดขนาดความกว้างของรูปป๊อบอัพที่ {maxWidth[1]}px</div>
                        <div className="text-danger text-small">* จำกัดขนาดความสูงของรูปป๊อบอัพที่ {maxHeight[1]}px</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">Landing page</label>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select className="form-control" value={banner.goto} onChange={e => {
                        e.persist();
                        setBanner(prev => ({
                          ...prev,
                          goto: e.target.value,
                        }))
                      }}>
                        <option value={""}>ไม่มี action</option>
                        <option value={"news"}>ข่าว</option>
                        <option value={"url"}>URL</option>
                        <option value={"restaurant"}>ร้านอาหาร</option>
                        <option value={"car_service"}>บริการ</option>
                      </select>
                    </div>

                    {renderLandingOpts(banner.goto)}

                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">ป๊อบอัพ</label>
                  <div className="col-sm-3">
                    <select className="form-control" value={banner.is_popup ? "1" : "0"} onChange={e => {
                      e.persist();
                      setBanner(prev => ({
                        ...prev,
                        is_popup: (+e.target.value) === 1,
                      }))
                      initDropify(undefined, maxWidth[(+e.target.value)], maxHeight[(+e.target.value)])
                    }}>
                      <option value={"0"}>ปกติ</option>
                      <option value={"1"}>ป๊อบอัพ</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3 col-form-label required">สถานนะ</label>
                  <div className="col-sm-3">
                    <select className="form-control" value={banner.status} onChange={e => {
                      e.persist();
                      setBanner(prev => ({
                        ...prev,
                        status: +e.target.value,
                      }))

                    }}>
                      <option value={1}>ใช้งาน</option>
                      <option value={0}>ไม่ใช้งาน</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <button className="btn btn-outline-primary mr-2 ml-auto" onClick={() => {
                    push(`/banners`)
                  }}>ยกเลิก</button>
                  <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
