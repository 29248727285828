import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { NotiContext } from "../store/keep";

export const SidebarRestaurant = (props) => {
  const { noti } = useContext(NotiContext);

  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav ">
        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/`}
          style={{ textDecoration: "none" }}
          exact
        >
          <span className="nav-link" to="/">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/dashboard_line.svg"} />
            </span>
            <span className="menu-title">หน้าหลัก</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant`}
          style={{ textDecoration: "none" }}
          exact
        >
          <span className="nav-link" to="/restaurant">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/food-and-restaurant_line.svg"} />
            </span>
            <span className="menu-title">ร้านอาหาร</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/order`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/order">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/waiter.svg"} />
            </span>
            <span className="menu-title">
              คำสั่งซื้อ
              <span className="badge badge-pill badge-danger ml-1">
                {noti.order > 0 ? noti.order : ""}
              </span>
            </span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/sale_bill`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/sale_bill">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/report_line.svg"} />
            </span>
            <span className="menu-title">ยอดขาย/ค่าบริการ</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant/menu`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant/menu">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/restaurant.svg"} />
            </span>
            <span className="menu-title">เมนูอาหาร</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant/catergory`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant/catergory">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/gastronomy.svg"} />
            </span>
            <span className="menu-title">หมวดหมู่อาหาร</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant/credit`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant/credit">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/coin.svg"} />
            </span>
            <span className="menu-title">ประวัติเครดิต</span>
          </span>
        </NavLink>

        <NavLink
          className={"nav-item "}
          activeClassName="active"
          to={`/restaurant/rate`}
          style={{ textDecoration: "none" }}
        >
          <span className="nav-link" to="/restaurant/rate">
            <span className="menu_icon">
              <img alt="" src={"/images/icon/star.svg"} />
            </span>
            <span className="menu-title">คะแนนรีวิว</span>
          </span>
        </NavLink>
      </ul>
    </nav>
  );
};
