import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toBase64 } from "../../Helper/format";
import { api, header_token, image_size, img_url } from "../../config";
import Select from "react-select";
import swal from "sweetalert";
import Axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import { BrandHeader } from "./BrandHeader";

export const EditBrand = () => {
  const { register, handleSubmit, errors } = useForm();
  const [filename, setFilename] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "", base64: "" });
  const [status, setStatus] = useState({ value: 1, label: "ใช้งาน" });
  const [recommend, setRecommend] = useState({ value: 1, label: "ใช้งาน" });
  const [name, setName] = useState("");
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [optionCategories, setOptionCategories] = useState([]);

  const getBrandById = async () => {
    try {
      setLoading(true);
      let { data } = await Axios.get(`${api}/brand/${id}`);
      setImage({
        preview: data.logo,
        raw: "",
        base64: "",
      });
      setStatus({ label: data.status === 1 ? "ใช้งาน" : "ไม่ใช้งาน", value: data.status });
      setCategories(data?.categories?.map(v => ({ value: v.id, label: v.title })) ?? []);
      setRecommend({ label: data.recommend ? "ใช้งาน" : "ไม่ใช้งาน", value: data.recommend });
      setName(data.name);
      setIndex(data.index);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
        button: false,
      });
    }
  };

  const onSubmit = async (sendData) => {
    try {
      let { data } = await Axios.put(`${api}/brand/${id}`, {
        name,
        logo: image.base64,
        index,
        status: status.value,
        recommend: Boolean(recommend.value),
        categories_id: categories?.map(({ value }) => value) ?? null,
      }, header_token);
      swal("สำเร็จ !!", {
        icon: "success",
      });
      // window.location = "/restaurant";
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getOptionCategories = async () => {
    try {
      let { data } = await Axios.get(`${api}/categories`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.title });
      });
      setOptionCategories(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBrandById();
    getOptionCategories();
  }, [])

  return (
    <div>
      <BrandHeader />
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <LoadingMask loading={loading} text={"loading..."}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="">รูปร้าน (512*512)</label>
                    </div>
                    <div className="col-lg-5">
                      <img
                        id="img-upload"
                        src={
                          image.base64 === ""
                            ? (image.preview === "" ? "" : (img_url + image.preview))
                            : image.preview
                        }
                        style={{ maxWidth: "100%", maxHeight: 512 }}
                      />
                      <div className="row">
                        <div className="col-10">
                          <div className="input-group">
                            <input
                              type="file"
                              id="imgInp"
                              className="form-control"
                              accept=".png, .jpg, .jpeg"
                              value={filename}
                              onChange={async (e) => {
                                if (e.target.files?.length > 0) {
                                  setFilename(e.target.value);

                                  let img_file = e.target.files[0];
                                  let base64img = await toBase64(img_file);

                                  if (await image_size(base64img, 512, 512)) {
                                    setImage({
                                      preview: URL.createObjectURL(img_file),
                                      base64: base64img,
                                    });
                                  } else {
                                    setFilename("");
                                    alert("ขนาดภาพเกินกว่าที่กำหนด");
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-light btn-sm mt-1"
                            onClick={() => {
                              setFilename("");
                              setImage({
                                preview: "",
                                base64: "",
                              });
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="">
                        ชื่อ<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        className={`form-control ${errors.name ? "error-input" : ""}`}
                        name="name"
                        type="text"
                        ref={register({ required: true })}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="address">ประเภทอาหาร</label>
                    </div>

                    <div className="col-lg-5">
                      <Select
                        value={categories}
                        onChange={(e) => {
                          setCategories(e);
                        }}
                        options={optionCategories}
                        placeholder={"เลือกประเภทอาหาร..."}
                        closeMenuOnSelect={false}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="">
                        ลำดับ
                      </label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        className={`form-control ${errors.index ? "error-input" : ""}`}
                        name="index"
                        type="number"
                        step={1}
                        ref={register({ required: true })}
                        value={index}
                        onChange={(e) => {
                          setIndex(+e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="status">สถานะ</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        id="status"
                        name="status"
                        options={[
                          { value: 0, label: "ไม่ใช้งาน" },
                          { value: 1, label: "ใช้งาน" },
                        ]}
                        placeholder={"สถานะ"}
                        value={status}
                        onChange={(e) => setStatus(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="recommend">แนะนำ</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        id="recommend"
                        name="recommend"
                        options={[
                          { value: 0, label: "ไม่ใช้งาน" },
                          { value: 1, label: "ใช้งาน" },
                        ]}
                        placeholder={"แนะนำ"}
                        value={recommend}
                        onChange={(e) => setRecommend(e)}
                      />
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-lg-3">
                    </div>
                    <div className="col-lg-5">
                      <Link
                        to={`/brands/detail/${id}`}
                        className="btn btn-light mr-2 ml-auto mt-2"
                      >
                        ยกเลิก
                      </Link>

                      <button
                        type="submit"
                        className="btn btn-primary  mr-2  mt-2 "
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </form>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}