import React, { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import Axios from "axios";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";
import { api, header_token } from "../../config";
import ReactPaginate from "react-paginate";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { ExportSaleBillData } from "../SaleBill/ExportSaleBillData";
import { NavLink } from "react-router-dom";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const BranchDetailSale = ({ branchId, pushToLink }) => {
  const [loading, setloading] = useState(false);
  const [summary_loading, setsummary_loading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [total_sales, settotal_sales] = useState(0);
  const [total_commission_sales, settotal_commission_sales] = useState(0);
  const [total_shipping, settotal_shipping] = useState(0);
  const [total_commission_shipping, settotal_commission_shipping] = useState(0);
  const [total_share_sales, settotal_share_sales] = useState(0);

  const [filter_name, setfilter_name] = useState("");
  const [filter_from, setfilter_from] = useState(new Date());
  const [filter_to, setfilter_to] = useState(new Date());

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const getDataInit = async () => {
    setsummary_loading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        branch_id: branchId,
        end_date: end,
        start_date: start,
        status: [5],
      };

      let { data } = await Axios.post(
        `${api}/admin/order/summary/filter`,
        send_data,
        header_token
      );

      // console.log("dataxxxxx", data);
      settotal_sales(data.total_sales);
      settotal_commission_sales(data.sales_commission);
      settotal_shipping(data.total_shipping);
      settotal_commission_shipping(data.shipping_commission);
      settotal_share_sales(
        data.shipping_commission + data.share_sales_commission
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setpage_count(0);
      settotal_count(0);
      setdata([]);
      settotal_sales(0);
      settotal_commission_sales(0);
      settotal_shipping(0);
      settotal_commission_shipping(0);
      settotal_share_sales(0);
    }
    setsummary_loading(false);
  };

  const getDataExport = async () => {
    setexport_loading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        branch_id: branchId,
        end_date: end,
        order_no: "",
        start_date: start,
        status: [5],
      };

      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${99999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let _export = [];
        data.orders.forEach((value) => {
          let _menu = "";

          if (value.menu) {
            value.menu.map((v, idx) => {
              let comma = "";
              if (idx !== value.menu.length - 1) comma = ", ";
              _menu += `${v.name_th}(x${v.quantity})` + comma;
            });
          }

          let re;
          switch (value.status) {
            case 0:
              re = "ยกเลิก";
              break;
            case 1:
              re = "ออเดอร์ใหม่";
              break;
            case 2:
              re = "พนักงานรับออเดอร์";
              break;
            case 3:
              re = "ดำเนินการ";
              break;
            case 4:
              re = "กำลังจัดส่ง";
              break;
            case 5:
              re = "ส่งสำเร็จ";
              break;
            case 6:
              re = "ส่งไม่สำเร็จ";
              break;
            default:
              break;
          }
          if (value.type === 0) {
            re = "ออเดอร์ล่วงหน้า";
          }

          _export.push({
            order_no: value.order_no,
            order_time: `${moment(value.created_at).format(
              "DD/MM/YYYY"
            )} ${moment(value.created_at).format("LT")}`,
            delivery:
              value.type === 0
                ? `${moment(value.due_date).format("DD/MM/YYYY")}  ${moment(
                    value.due_date
                  ).format("LT")}`
                : "สังทันที",
            restaurant: `${value.restaurant.name_th}\n ${value.restaurant.phone_numbers}`,
            menu: _menu,
            user: `${value.user.full_name}\n ${value.user.phone_number}`,
            rider: value.rider
              ? `${value.rider.full_name}\n ${value.rider.phone_number}`
              : null,
            menu_count: value.menu ? value.menu.length : null,
            food_cost: value.food_cost,
            shipping_cost: value.shipping_cost,
            total: value.total,
            distance: `~ ${value.distance}km`,
            status: re,
            food_percentage: (
              value.food_cost *
              (value.percentage_gp / 100)
            ).toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            shipping_percentage: (
              value.shipping_cost *
              (value.percentage_rider_charge / 100)
            ).toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
          });
        });
        // console.log("_export", _export);
        await setexport_data(_export);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setexport_loading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        branch_id: branchId,
        end_date: end,
        start_date: start,
        status: [5],
      };

      // console.log("send", send_data);
      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("getData", data);
      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);

        setdata(data.orders);
      } else {
        setpage_count(0);
        settotal_count(0);
        setdata([]);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setpage_count(0);
      settotal_count(0);
      setdata([]);
    }
    setloading(false);
  };

  useEffect(() => {
    getDataInit();
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getDataInit();
    getData();
  }, [filter_from, filter_to, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div className="ml-4 mr-4 mt-4">
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="mr-3">
              <NavLink
                exact
                className={"nav-link "}
                to={`/branch/detail/${branchId}/sale`}
                activeClassName="active"
                onClick={() => {
                  pushToLink(`/branch/detail/${branchId}/sale`);
                }}
                isActive={() =>
                  [`/branch/detail/${branchId}/sale`].includes(
                    window.location.pathname
                  )
                }
              >
                บริการอาหาร
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/branch/detail/${branchId}/shipping`}
                onClick={() => {
                  pushToLink(`/branch/detail/${branchId}/shipping`);
                }}
                isActive={() =>
                  [`/branch/detail/${branchId}/shipping`].includes(
                    window.location.pathname
                  )
                }
              >
                บริการขนส่ง
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <h4 className="mb-4  ml-3" style={{ marginTop: "auto" }}>
          {moment().format("LL")}
        </h4>

        <div className="ml-auto mr-2 mb-2" style={{ float: "right" }}>
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo ">
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
                if (!filter_to) {
                  setfilter_to(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                  if (!filter_from) {
                    setfilter_from(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
            <button
              type="button"
              className="btn btn-primary mr-2 ml-2"
              style={{ marginTop: -2, padding: `5px 10px 5px 10px` }}
              onClick={async () => {
                if (!trigger && !export_loading) {
                  await getDataExport();
                  await settrigger(true);
                }
              }}
            >
              {export_loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              {` `}Export
            </button>
            <ExportSaleBillData
              data={export_data}
              trigger={trigger}
              settrigger={settrigger}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          {/* <div className="row">
                <div className="col-12">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="mr-3">
                      <NavLink
                        exact
                        className={"nav-link active"}
                        to={`/branch/sale`}
                      >
                        บริการอาหาร
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={"nav-link "}
                        // activeClassName="active"
                        to={`/branch/sale/shipping`}
                      >
                        บริการขนส่ง
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}

          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex flex-wrap mb-2">
                <div className="mr-4">
                  <h2 className="mb-1 text-success ">
                    {summary_loading ? (
                      <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `฿${total_sales.toLocaleString()}`
                    )}
                  </h2>
                  <p className="text-muted mb-0 tx-13">ยอดขายทั้งหมด</p>
                </div>

                <div className="mr-4">
                  <h2 className="mb-1  ">
                    {summary_loading ? (
                      <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `฿${total_commission_sales.toLocaleString("en", {
                        maximumFractionDigits: 0,
                      })}`
                    )}
                  </h2>
                  <p className="text-muted mb-0 tx-13">ค่าคอมมิชชั่นยอดขาย</p>
                </div>

                <div className="mr-4">
                  <h2 className="mb-1  ">
                    {summary_loading ? (
                      <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `฿${total_shipping.toLocaleString()}`
                    )}
                  </h2>
                  <p className="text-muted mb-0 tx-13">ค่าจัดส่ง</p>
                </div>

                <div className="mr-4">
                  <h2 className="mb-1  ">
                    {summary_loading ? (
                      <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `฿${total_commission_shipping.toLocaleString("en", {
                        maximumFractionDigits: 0,
                      })}`
                    )}
                  </h2>
                  <p className="text-muted mb-0 tx-13">
                    ค่าคอมมิชชั่นค่าจัดส่ง
                  </p>
                </div>

                <div className="mr-4">
                  <h2 className="mb-1  ">
                    {summary_loading ? (
                      <span
                        className="spinner-border "
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      `฿${total_share_sales.toLocaleString("en", {
                        maximumFractionDigits: 0,
                      })}`
                    )}
                  </h2>
                  <p className="text-muted mb-0 tx-13">ส่วนแบ่งรายได้</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 mb-2 mt-4">
              <div className="form-row align-items-center mb-2">
                <h4 className="mt-4 ml-1">ทั้งหมด {total_count} รายการ</h4>
                <div className="col-sm-2 mr-1 ml-auto">
                  <span>ชื่อสาขา</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    id="global_filter"
                    placeholder="ชื่อสาขา"
                    value={filter_name}
                    onChange={(e) => setfilter_name(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <LoadingMask loading={loading} text={"loading..."}>
            <div style={{ minHeight: 500 }}>
              <div className="sticky-div">
                <table
                  id="dataTable"
                  className="table sticky-table"
                  style={{ width: "100%" }}
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th style={{ width: 150 }} className="sticky-head">
                        ออเดอร์ ID
                      </th>
                      <th style={{ width: 200 }}>ชื่อร้าน</th>
                      <th style={{ width: 200 }}>พื้นที่</th>
                      <th style={{ width: 80 }}>ค่าอาหาร</th>
                      <th style={{ width: 100 }}>คอมมิชชั่นค่าอาหาร</th>
                      <th style={{ width: 100 }}>ค่าจัดส่ง</th>
                      <th style={{ width: 130 }}>คอมมิชชั่นค่าส่ง</th>
                      <th style={{ width: 150 }}>วันที่</th>
                      <th style={{ width: 100 }}>ชำระ</th>
                      <th style={{ width: 130 }}>ดำเนินการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td className="sticky-col">
                            <a
                              tabIndex={0}
                              onClick={() =>
                                pushToLink(`/order/detail/${value.id}`)
                              }
                            >
                              {value.order_no}
                            </a>
                          </td>
                          <td>{value.restaurant.name_th}</td>
                          <td> {value.restaurant.area.name_th}</td>
                          <td>฿{value.food_cost}</td>
                          <td>
                            ฿
                            {(
                              value.food_cost *
                              (value.percentage_gp / 100)
                            ).toLocaleString("en", {
                              maximumFractionDigits: 0,
                            })}
                          </td>
                          <td>฿{value.shipping_cost}</td>
                          <td>
                            ฿
                            {(
                              value.shipping_cost *
                              (value.percentage_rider_charge / 100)
                            ).toLocaleString("en", {
                              maximumFractionDigits: 0,
                            })}
                          </td>
                          <td>
                            {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                            {moment(value.created_at).format("LT")}
                          </td>
                          <td>{value.payment_method}</td>
                          <td>
                            <button
                              className="btn btn-outline-primary btn-sm mb-1"
                              onClick={() =>
                                pushToLink(`/order/detail/${value.id}`)
                              }
                            >
                              รายละเอียด
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </LoadingMask>
        </div>
      </div>
    </div>
  );
};
