import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import { NotFound } from "./NotFound";
import { Topbar } from "./Components/Topbar";
import { Sidebar } from "./Components/Sidebar";
import { Dashboard } from "./Components/Dashboard";
import { UserAll } from "./Components/User/UserAll";
import { RiderAll } from "./Components/Rider/RiderAll";
import { RestaurantApprove } from "./Components/Restaurant/RestaurantApprove";
import { RestaurantAll } from "./Components/Restaurant/RestaurantAll";
import { RestaurantEdit } from "./Components/Restaurant/RestaurantEdit";
import { RestaurantDetail } from "./Components/Restaurant/RestaurantDetail";
import { RestaurantDetailMenuAdd } from "./Components/Restaurant/RestaurantDetailMenuAdd";
import { OrderIndex } from "./Components/Order/OrderIndex";
import { SaleBillIndex } from "./Components/SaleBill/SaleBillIndex";
import { CreditIndex } from "./Components/Credit/CreditIndex";
import { NewsIndex } from "./Components/News/NewsIndex";
import { SettingIndex } from "./Components/Setting/SettingIndex";
import { RestaurantDetailMenuEdit } from "./Components/Restaurant/RestaurantDetailMenuEdit";
import { RiderAdd } from "./Components/Rider/RiderAdd";
import { RiderTeam } from "./Components/Rider/RiderTeam";
import { RiderDetailHistory } from "./Components/Rider/RiderDetailHistory";
import { RiderDetailCredit } from "./Components/Rider/RiderDetailCredit";
import { RiderDetailWithdraw } from "./Components/Rider/RiderDetailWithdraw";
import { RiderDetailService } from "./Components/Rider/RiderDetailService";
import { RiderEdit } from "./Components/Rider/RiderEdit";
import { UserAdd } from "./Components/User/UserAdd";
import { UserEdit } from "./Components/User/UserEdit";
import { RestaurantAdd } from "./Components/Restaurant/RestaurantAdd";
import { CreditHistory } from "./Components/Credit/CreditHistory";
import { WithdrawHead } from "./Components/Withdraw/WithdrawHead";
import { OrderDetail } from "./Components/Order/OrderDetail";
import { RiderApprove } from "./Components/Rider/RiderApprove";
import { NotificationFirebase } from "./store/NotificationFirebase";
import { RecommenedHead } from "./Components/Recommened/RecommenedHead";
import { PromotionBannerHead } from "./Components/PromotionBanner/PromotionBannerHead";
import { RestaurantPromotionHead } from "./Components/RestaurantPromotion/RestaurantPromotionHead";
import { AdminProfile } from "./Components/AdminProfile";
import { UserOrderHistory } from "./Components/User/UserOrderHistory";
import { NewsAdd } from "./Components/News/NewsAdd";
import { RiderImport } from "./Components/Rider/RiderImport";
import { NewsEdit } from "./Components/News/NewsEdit";
import { ReadNews } from "./ReadNews";

import { NotiContext, RulesContext } from "./store/keep";
import { UserImport } from "./Components/User/UserImport";
import { RestaurantImport } from "./Components/Restaurant/RestaurantImport";
import { CarServiceIndex } from "./Components/CarService/CarServiceIndex";
import { CarServiceEdit } from "./Components/CarService/CarServiceEdit";
import { CarServiceAdd } from "./Components/CarService/CarServiceAdd";
import { OrderCarIndex } from "./Components/OrderCarService/OrderCarIndex";
import { OrderCarDetail } from "./Components/OrderCarService/OrderCarDetail";
import { SaleBillShippingIndex } from "./Components/SaleBill/SaleBillShippingIndex";
import { ListBrand } from "./Components/Brand/ListBrand";
import { AddBrand } from "./Components/Brand/AddBrand";
import { EditBrand } from "./Components/Brand/EditBrand";
import { DetailBrand } from "./Components/Brand/DetailBrand";
import { CategoryBrand } from "./Components/Brand/CategoryBrand";
import { MenuBrand } from "./Components/Brand/MenuBrand";
import { BrandMenuAdd } from "./Components/Brand/BrandMenuAdd";
import { BrandMenuEdit } from "./Components/Brand/BrandMenuEdit";
import { Banner } from "./Components/Banner";
import { BannerForm } from "./Components/Banner/BannerForm";
import { BranchDetail } from "./Components/Branch/BranchDetail";
import { BranchEdit } from "./Components/Branch/BranchEdit";
import { BranchAdd } from "./Components/Branch/BranchAdd";
import { BranchSaleFood } from "./Components/Branch/BranchSaleFood";
import { BranchSaleShipping } from "./Components/Branch/BranchSaleShipping";
import { BranchAll } from "./Components/Branch/BranchAll";
import { SendingIndex } from "./Components/Sending/SendingIndex";
import { PromotionCodeHead } from "./Components/PromotionCode/PromotionCodeHead";

import { UserServiceEdit } from "./Components/UserService/UserServiceEdit";
import { UserServiceDetailHistory } from "./Components/UserService/UserServiceDetailHistory";
import { UserServiceDetailService } from "./Components/UserService/UserServiceDetailService";
import { UserServiceDetailWithdraw } from "./Components/UserService/UserServiceDetailWithdraw";
import { UserServiceDetailCredit } from "./Components/UserService/UserServiceDetailCredit";
import { UserServiceApprove } from "./Components/UserService/UserServiceApprove";
import { UserServiceAdd } from "./Components/UserService/UserServiceAdd";
import { UserServiceAll } from "./Components/UserService/UserServiceAll";
import { ServiceFeeGroup } from "./Components/ServiceFee/ServiceFeeGroup";
import { ServiceFeeAll } from "./Components/ServiceFee/ServiceFeeAll";
import { ServiceFeeRecommend } from "./Components/ServiceFee/ServiceFeeRecommend";
import { ServiceFeeGroupAdd } from "./Components/ServiceFee/ServiceFeeGroupAdd";
import { ServiceFeeAdd } from "./Components/ServiceFee/ServiceFeeAdd";
import { ServiceFeeRecommendAdd } from "./Components/ServiceFee/ServiceFeeRecommendAdd";
import { ServiceFeeGroupEdit } from "./Components/ServiceFee/ServiceFeeGroupEdit";
import { ServiceFeeRecommendEdit } from "./Components/ServiceFee/ServiceFeeRecommendEdit";
import { ServiceFeeEdit } from "./Components/ServiceFee/ServiceFeeEdit";
import { OrderServiceIndex } from "./Components/OrderService/OrderServiceIndex";
import { OrderServiceDetail } from "./Components/OrderService/OrderServiceDetail";
import { RULE } from "./Components/Setting/SettingAdmin";

export const Admin = ({ token: token }) => {
  const [rules, setrules] = useState({
    banner: false,
    promotion_banner: false,
    deposit: false,
    member: false,
    news: false,
    order: false,
    promotion: false,
    recommend: false,
    restaurant: false,
    rider: false,
    setting: false,
    shipping: false,
    summary: false,
    withdraw: false,
    branch: false,
    car_service: false,
    car_service_order: false,
    brand: false,
  });

  const [noti, setnoti] = useState({
    rider: 0,
    restuarant: 0,
    order: 0,
    withdraw: 0,
    credit: 0,
    getdata: "",
  });

  // useEffect(() => {
  //   console.log('rules',rules);
  // }, [rules]);

  return (
    <RulesContext.Provider value={{ rules, setrules }}>
      <NotiContext.Provider value={{ noti, setnoti }}>
        <div className="container-scroller">
          <Topbar />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <NotificationFirebase />

                <Switch>
                  <PrivateRoute
                    token={token}
                    path={`/news/read/:id`}
                    component={ReadNews}
                    isAllowed={true}
                  />

                  {/* -------------------------------------- User -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path={`/user/order/:id`}
                    component={UserOrderHistory}
                    isAllowed={rules.member && RULE.hasOwnProperty('member')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user/edit/:id`}
                    component={UserEdit}
                    isAllowed={rules.member && RULE.hasOwnProperty('member')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/add"
                    component={UserAdd}
                    isAllowed={rules.member && RULE.hasOwnProperty('member')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/user"
                    component={UserAll}
                    isAllowed={rules.member && RULE.hasOwnProperty('member')}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/user/import"
                    component={UserImport}
                    isAllowed={rules.member && RULE.hasOwnProperty('member')}
                  />

                  {/* -------------------------------------- Car Service --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service"
                    component={CarServiceIndex}
                    isAllowed={rules.car_service_setting && RULE.hasOwnProperty('car_service_setting')}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service/add"
                    component={CarServiceAdd}
                    isAllowed={rules.car_service_setting && RULE.hasOwnProperty('car_service_setting')}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service/edit/:service_id"
                    component={CarServiceEdit}
                    isAllowed={rules.car_service_setting && RULE.hasOwnProperty('car_service_setting')}
                  />

                  {/* -------------------------------------- Branch --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/branch/detail/:id"
                    component={BranchDetail}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/branch/edit/:id"
                    component={BranchEdit}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/add"
                    component={BranchAdd}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/sale"
                    component={BranchSaleFood}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/branch/sale/shipping"
                    component={BranchSaleShipping}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/branch"
                    component={BranchAll}
                    isAllowed={rules.branch && RULE.hasOwnProperty('branch')}
                  />

                  {/* -------------------------------------- Brand --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    exact
                    path="/brands"
                    component={ListBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/brands/add`}
                    component={AddBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/brands/edit/:id`}
                    component={EditBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/brands/detail/:id/menu-add"
                    component={BrandMenuAdd}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/brands/detail/:id/menu-edit/:menu_id"
                    component={BrandMenuEdit}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/brands/detail/:id`}
                    component={DetailBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/brands/detail/:id/menu`}
                    component={MenuBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/brands/detail/:id/category`}
                    component={CategoryBrand}
                    isAllowed={rules.brand && RULE.hasOwnProperty('brand')}
                  />



                  {/* -------------------------------------- Restaurant --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-add"
                    component={RestaurantDetailMenuAdd}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-edit/:menu_id"
                    component={RestaurantDetailMenuEdit}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id"
                    component={RestaurantDetail}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/edit/:id"
                    component={RestaurantEdit}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/approve`}
                    component={RestaurantApprove}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/add`}
                    component={RestaurantAdd}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/restaurant/import"
                    component={RestaurantImport}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/restaurant"
                    component={RestaurantAll}
                    isAllowed={rules.restaurant && RULE.hasOwnProperty('restaurant')}
                  />

                  {/* -------------------------------------- Rider --------------------------------------  */}
                  <PrivateRoute
                    token={token}
                    path={`/rider/import`}
                    component={RiderImport}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/rider/edit/:id`}
                    component={RiderEdit}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/history/:id`}
                    component={RiderDetailHistory}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/service/:id`}
                    component={RiderDetailService}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/withdraw/:id`}
                    component={RiderDetailWithdraw}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/credit/:id`}
                    component={RiderDetailCredit}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/team`}
                    component={RiderTeam}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/approve`}
                    component={RiderApprove}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/add`}
                    component={RiderAdd}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider`}
                    component={RiderAll}
                    isAllowed={rules.rider && RULE.hasOwnProperty('rider')}
                  />
                  {/* -------------------------------------- Order -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/order/detail/:id"
                    component={OrderDetail}
                    isAllowed={rules.order && RULE.hasOwnProperty('order')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/order"
                    component={OrderIndex}
                    isAllowed={rules.order && RULE.hasOwnProperty('order')}
                  />

                  {/* -------------------------------------- Car Service Order -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service_order/detail/:id"
                    component={OrderCarDetail}
                    isAllowed={rules.car_service && RULE.hasOwnProperty('car_service')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/car_service_order"
                    component={OrderCarIndex}
                    isAllowed={rules.car_service && RULE.hasOwnProperty('car_service')}
                  />

                  {/* -------------------------------------- Sending -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/sending"
                    component={SendingIndex}
                    isAllowed={rules.shipping && RULE.hasOwnProperty('shipping')}
                  />

                  {/* -------------------------------------- Credit -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/credit/history"
                    component={CreditHistory}
                    isAllowed={rules.deposit && RULE.hasOwnProperty('deposit')}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path="/credit"
                    component={CreditIndex}
                    isAllowed={rules.deposit && RULE.hasOwnProperty('deposit')}
                  />
                  {/* -------------------------------------- Withdraw  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/withdraw"
                    component={WithdrawHead}
                    isAllowed={rules.withdraw && RULE.hasOwnProperty('withdraw')}
                  />

                  {/* --------------------------------------  News  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news"
                    component={NewsIndex}
                    isAllowed={rules.news && RULE.hasOwnProperty('news')}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news/add"
                    component={NewsAdd}
                    isAllowed={rules.news && RULE.hasOwnProperty('news')}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/news/:id"
                    component={NewsEdit}
                    isAllowed={rules.news && RULE.hasOwnProperty('news')}
                  />

                  {/* -------------------------------------- Promotion Code --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/promotion_code"
                    component={PromotionCodeHead}
                    isAllowed={rules.promotion_code && RULE.hasOwnProperty('promotion_code')}
                  />

                  {/* -------------------------------------- Banner --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/banners"
                    exact
                    component={Banner}
                    isAllowed={rules.banner && RULE.hasOwnProperty('banner')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/banners/add"
                    component={BannerForm}
                    componentProps={{
                      mode: "new",
                    }}
                    isAllowed={rules.banner && RULE.hasOwnProperty('banner')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/banners/edit/:id"
                    component={BannerForm}
                    componentProps={{
                      mode: "edit",
                    }}
                    isAllowed={rules.banner && RULE.hasOwnProperty('banner')}
                  />

                  {/* -------------------------------------- Order Service -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    path={`/user_service/edit/:id`}
                    component={UserServiceEdit}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/history/:id`}
                    component={UserServiceDetailHistory}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/service/:id`}
                    component={UserServiceDetailService}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/withdraw/:id`}
                    component={UserServiceDetailWithdraw}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/user_service/credit/:id`}
                    component={UserServiceDetailCredit}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service/approve`}
                    component={UserServiceApprove}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service/add`}
                    component={UserServiceAdd}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path={`/user_service`}
                    component={UserServiceAll}
                    isAllowed={rules.user_service && RULE.hasOwnProperty('user_service')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group"
                    component={ServiceFeeGroup}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group/add"
                    component={ServiceFeeGroupAdd}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/group/edit/:service_id"
                    component={ServiceFeeGroupEdit}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee"
                    component={ServiceFeeAll}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/add"
                    component={ServiceFeeAdd}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/edit/:service_id"
                    component={ServiceFeeEdit}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend"
                    component={ServiceFeeRecommend}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend/add"
                    component={ServiceFeeRecommendAdd}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />
                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee/recommend/edit/:service_id"
                    component={ServiceFeeRecommendEdit}
                    isAllowed={rules.service_setting && RULE.hasOwnProperty('service_setting')}
                  />

                  <PrivateRoute
                    exact
                    token={token}
                    path="/service_fee_order/detail/:id"
                    component={OrderServiceDetail}
                    isAllowed={rules.service && RULE.hasOwnProperty('service')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/service_fee_order"
                    component={OrderServiceIndex}
                    isAllowed={rules.service && RULE.hasOwnProperty('service')}
                  />

                  {/* --------------------------------------  Others  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/setting"
                    component={SettingIndex}
                    isAllowed={rules.setting && RULE.hasOwnProperty('setting')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/recommened"
                    component={RecommenedHead}
                    isAllowed={rules.recommend && RULE.hasOwnProperty('recommend')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/promotion_banner"
                    component={PromotionBannerHead}
                    isAllowed={rules.promotion_banner && RULE.hasOwnProperty('promotion_banner')}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant_promotion"
                    component={RestaurantPromotionHead}
                    isAllowed={rules.promotion && RULE.hasOwnProperty('promotion')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/sale_bill"
                    exact
                    component={SaleBillIndex}
                    // component={SaleBillShippingIndex}
                    isAllowed={rules.summary && RULE.hasOwnProperty('summary')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/sale_bill/shipping"
                    component={SaleBillShippingIndex}
                    isAllowed={rules.summary && RULE.hasOwnProperty('summary')}
                  />

                  <PrivateRoute
                    token={token}
                    path="/admin_profile"
                    component={AdminProfile}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/"
                    component={Dashboard}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="*"
                    component={NotFound}
                    isAllowed={true}
                  />
                  <Redirect to="/404" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </NotiContext.Provider>
    </RulesContext.Provider>
  );
};
