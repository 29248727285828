import Axios from "axios";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import Select from "react-select";
import { api } from "../../config";

export const BranchAddArea = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      props.callbackArea(area);
    },
  }));
  const [area, setarea] = useState([{ area: "" }]);
  const [option_area, setoption_area] = useState([]);

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      data
        .filter((e) => e.branch_id === "")
        .forEach((value) => {
          tmp.push({ value: value.id, label: value.name_th });
        });
      setoption_area(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOptionArea();
  }, []);
  return (
    <div>
      <div className="form-group col-12">
        {area.map((value, index) => {
          return (
            <div key={index}>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="area">พื้นที่ {index + 1}</label>
                </div>
                <div className="col-lg-5">
                  <Select
                    value={value.area}
                    onChange={(e) => {
                      let tmp = [...area];

                      tmp[index].area = e;
                      setarea(tmp);
                    }}
                    options={option_area}
                    placeholder={"เลือกพื้นที่.."}
                  />
                </div>
                <div className="col-lg-4">
                  {area.length > 1 ? (
                    <button
                      type="button"
                      className="btn btn-danger  ml-2"
                      onClick={() => {
                        let tmp = [...area];
                        tmp.splice(index, 1);

                        setarea(tmp);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  ) : null}

                  {area.length === index + 1 ? (
                    <button
                      type="button"
                      className="btn btn-primary  ml-2"
                      onClick={() => {
                        let tmp = [...area];

                        tmp.push({ area: "" });
                        setarea(tmp);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              href="#"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                props.callbackArea(area);
                props.nextRef.current.click();
              }}
            >
              ถัดไป
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
