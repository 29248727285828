import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
export const RestaurantAddAdmin = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      props.callbackAdmin({
        email: email,
        password: password,
      });
    },
  }));
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [show, setshow] = useState(false);
  const [error, seterror] = useState(<span />);

  return (
    <div>
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="email">Email</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row has-error" >
        <div className="col-lg-3">
          <label htmlFor="name">Password</label>
        </div>
        <div className="col-lg-5">
          <input
            id="password"
            className="form-control"
            name="password"
            placeholder=""
            type={show === false ? "password" : "text"}
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="name">Confirm password</label>
        </div>
        <div className="col-lg-5">
          {error}

          <input
            id="confirm_password"
            className="form-control"
            name="confirm_password"
            placeholder=""
            type={show === false ? "password" : "text"}
            value={confirmpassword}
            onChange={(e) => setconfirmpassword(e.target.value)}
          />
          <div className="form-check mt-4 ml-2">
            <input
              type="checkbox"
              className="mr-2"
              defaultValue={show}
              onChange={() => setshow(!show)}
            />
            Show Password
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-11">
          <div className="row">
            <a
              href="/restaurant"
              className="btn btn-outline-primary mr-2 ml-auto mt-2 "
            >
              ยกเลิก
            </a>
            <a
              href="#"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                // console.log("password", password);
                // console.log("confi", confirmpassword);
                if (password !== confirmpassword) {
                  seterror(
                    <label htmlFor="name" style={{ color: "red" }}>
                      รหัสผ่านไม่ตรงกัน
                    </label>
                  );
                } else {
                  seterror(<span />);
                  props.callbackAdmin({
                    email: email,
                    password: password,
                  });
                  console.log("before submit", props.data);
                  props.submit(props.data);
                }
              }}
            >
              บันทึก
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
