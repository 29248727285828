import React, { useState, useEffect, useRef } from "react";
import {
  api,
  img_url,
  action_option,
  menu_status_option,
  header_token,
} from "../../config";
import Axios from "axios";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export const BrandMenuList = ({ brand }) => {
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);
  const [img, setimg] = useState("");
  const [action, setaction] = useState({ value: "", label: "เลือก.." });
  const [status, setstatus] = useState({ value: "", label: "เลือก.." });
  const [search_name, setsearch_name] = useState("");
  const { push } = useHistory();

  const [data, setdata] = useState([]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/brand/${brand.id}/menu`
      );
      // props.callbackName(data.name_th);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        value.checked = false;
        tmp.push(value);
      });
      // console.log(tmp);
      setdata(tmp);
    } catch (error) {
      console.log(error)
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const deleteMenu = async (menu_id) => {
    try {
      let res = await Axios.delete(
        `${api}/brands/${brand.id}/menu/${menu_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="row">
        <h4 className="mb-4 mt-3 ml-2">เมนูทั้งหมด {data.length} รายการ</h4>

        <form className="ml-auto">
          <div className="form-row align-items-center mb-2">
            <div className="col mr-2">
              <span className="tx-13 text-muted">ค้นหา</span>
              <input
                type="text"
                className="form-control- global_filter"
                id="global_filter"
                placeholder="ชื่อเมนู"
                value={search_name}
                onChange={(e) => setsearch_name(e.target.value)}
              />
            </div>

            <div className="col">
              <div className="row">
                <div style={{ width: 150 }}>
                  <span className="tx-13 text-muted">สถานะ</span>
                  <Select
                    value={status}
                    onChange={(e) => {
                      setstatus(e);
                    }}
                    options={menu_status_option}
                  />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="row">
                <div style={{ width: 150 }}>
                  <span className="tx-13 text-muted">ดำเนินการ</span>
                  <Select
                    value={action}
                    onChange={(e) => {
                      setaction(e);
                    }}
                    options={action_option}
                  />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="row">
                <div style={{ width: 150 }}>
                  {/* <span className="tx-13 text-muted"> </span> */}
                  <br></br>
                  <a
                    tabIndex="0"
                    className="btn btn-primary  ml-2 "
                    onClick={async () => {
                      // 0 = ลบ

                      let count = data.filter((e) => e.checked === true);
                      if (action.value === 0 && count.length > 0) {
                        swal({
                          title: `ต้องการลบเมนูที่เลือกหรือไม่ ?`,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let sending = [];
                            data.forEach((value) => {
                              if (value.checked === true) {
                                // console.log('true');
                                sending.push(
                                  deleteMenu(value.id)
                                );
                              }
                            });

                            let is_success = true;
                            await Promise.all(sending).then((res) => {
                              // console.log(res);
                              if (
                                res.filter((e) => e === true).length === 0 ||
                                res.length === 0
                              )
                                is_success = false;
                            });

                            if (is_success) {
                              swal("ลบเสร็จสิ้น !!", {
                                icon: "success",
                              });
                            } else {
                              swal("เกิดข้อผิดพลาด !!", {
                                icon: "error",
                              });
                            }
                          }
                        });
                      } else {
                        alert("เลือกตัวดำเนินการ");
                      }
                    }}
                  >
                    ดำเนินการ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="dataTable" className="table">
            <thead>
              <tr>
                <th width="30">
                  {" "}
                  <input
                    type="checkbox"
                    className="selectAll "
                    name="selectAll"
                    defaultChecked={false}
                    ref={checkAllRef}
                    onClick={(e) => {
                      // console.log(checkAllRef.current.checked);
                      let tmp = [...data];
                      let _tmp = [];
                      tmp.forEach((value, idx) => {
                        value.checked = checkAllRef.current.checked;
                        _tmp.push(value);
                      });
                      setdata(_tmp);
                      // console.log(data);
                    }}
                  />
                </th>
                <th width="50">รูป</th>
                <th width="250">ชื่อ</th>
                <th width="250">รายละเอียด</th>
                <th width="80">ราคาขาย</th>
                <th width="100">ราคาโปรโมชั่น</th>
                <th width="80">หมวดหมู่</th>
                <th width="80">สถานะ</th>
                <th width="80">ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => {
                if (
                  (value.status === status.value || status.value === "") &&
                  value.name_th.includes(search_name)
                ) {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: value.checked ? "#e8eaed" : "",
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={value.checked}
                          onChange={(e) => {
                            let tmp = [...data];
                            tmp[index].checked = !tmp[index].checked;
                            setdata(tmp);
                          }}
                        />
                      </td>
                      <td>
                        <div
                          className="thumbnail cursor-img"
                          data-toggle="modal"
                          data-target=".show-img"
                          onClick={() => {
                            setimg(
                              value.thumbnail === ""
                                ? "/images/no-img.jpg"
                                : `${img_url}${value.thumbnail}`
                            );
                          }}
                        >
                          <img
                            className="lazy img-responsive"
                            // src={`${img_url}${value.thumbnail}`}
                            src={
                              value.thumbnail === ""
                                ? "/images/no-img.jpg"
                                : `${img_url}${value.thumbnail}`
                            }
                          />
                        </div>
                      </td>
                      <td>{value.name_th}</td>
                      <td>{value.description}</td>
                      <td>{value.price}</td>
                      <td>{value.promotion_price}</td>
                      <td>
                        {value.menu_group.map((v, idx) => {
                          let comma = "";
                          if (idx !== value.menu_group.length - 1) comma = ", ";
                          return <span key={idx}>{v.title + comma}</span>;
                        })}
                      </td>
                      <td>
                        {menu_status_option.map((e, indx) => {
                          // console.log(e);
                          if (parseInt(e.value) === parseInt(value.status))
                            return <span key={indx}>{e.label}</span>;
                        })}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            push(
                              `/brands/detail/${brand.id}/menu-edit/${value.id}`
                            );
                          }}
                          className="btn btn-outline-primary btn-sm mb-1"
                        >
                          แก้ไข
                        </button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </LoadingMask>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
