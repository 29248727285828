import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import dropify from "dropify";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Select from "react-select";
import { useParams, Link, useHistory } from "react-router-dom";
import Axios from "axios";
import {
  api,
  img_url,
  menu_status_option,
  header_token,
  image_size,
} from "../../config";
import swal from "sweetalert";

export const BrandMenuEdit = () => {
  const { id, menu_id } = useParams();
  const { push } = useHistory();
  const menu_group_ref = useRef(null);

  const [img_cover, setimg_cover] = useState({
    preview: "",
    raw: "",
    base64: "",
  });
  const [img_thumbnail, setimg_thumbnail] = useState({
    preview: "",
    raw: "",
    base64: "",
  });
  const [menu_name, setmenu_name] = useState("");
  const [detail, setdetail] = useState("");
  const [time, settime] = useState(0);
  const [price, setprice] = useState(0);
  const [sale, setsale] = useState(false);
  const [sale_price, setsale_price] = useState(0);
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [menu_group, setmenu_group] = useState([]);
  const [is_group_menu, setis_group_menu] = useState(false);

  const [add_on, setadd_on] = useState([]);
  const [menu_add_on, setmenu_add_on] = useState([
    // {
    //   add_on_id: "",
    //   options_id: ["", ""],
    //   status: { value: 1, label: "บังคับเลือก 1 รายการ" },
    // },
  ]);

  const [option_menu_group, setoption_menu_group] = useState([]);

  const getOptionMenuGroup = async () => {
    try {
      let { data } = await Axios.get(`${api}/brand/${id}/menu-group`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.title });
      });
      setoption_menu_group(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getAddOn = async (aa) => {
    try {
      let { data } = await Axios.get(`${api}/brand/${id}/add-on`);

      let data2 = data.map((e) => {
        let found = aa.find((f) => f.add_on.id === e.id);

        return {
          add_on_id: e.id,
          options_id: e.options.map((ee) => ({
            id: ee.id,
            checked: found
              ? found.add_on.options.map((f) => f.id).includes(ee.id)
              : false,
          })),
          // status: menu_option.find(
          //   (mo) => mo.value === (found ? found.status : "")
          // ),
          min: found ? found.min : "",
          max: found ? found.max : "",
        };
      });
      // console.log("add_on", add_on);
      setmenu_add_on(data2);
      setadd_on(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const sendData = async (menu_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/brand/${id}/menu/${menu_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201 || res.status === 200) {
        // window.location = `/restaurant/detail/${id}/menu`;
        push(`/brands/detail/${id}/menu`);
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/brand/${id}/menu/${menu_id}`
      );
      // console.log("getdata", data);

      await getAddOn(data.menu_add_on);
      setmenu_name(data.name_th);
      setdetail(data.description);
      settime(data.cook_time);
      setprice(data.price);
      setsale(data.is_promotion);
      setsale_price(data.promotion_price);
      setstatus(data.status);
      menu_status_option.map((v) => {
        if (v.value === data.status) {
          setstatus({ value: data.status, label: v.label });
        }
      });

      data.menu_add_on.map((value) => {
        // console.log(value.add_on);
      });

      // setadd_on([]);
      // setmenu_add_on(data.menu_add_on);
      // setmenu_group(data.menu_group_id);
      let selected = [];
      // console.log('option_menu_group',option_menu_group);
      // console.log('menu_group_id',data.menu_group_id);
      option_menu_group.map((value) => {
        data.menu_group_id.map((e) => {
          if (value.value === e) {
            selected.push({ value: e, label: value.label });
          }
        });
      });
      setmenu_group(selected);

      setimg_cover({
        preview: data.cover,
        raw: "",
        base64: "",
      });
      setimg_thumbnail({
        preview: data.thumbnail,
        raw: "",
        base64: "",
      });

      var drEventPreview = $("#preview-img").dropify();
      var drEventThumb = $("#thumbnail-img").dropify();

      drEventPreview.on("dropify.afterClear", function (event, element) {
        setimg_cover({
          preview: "",
          raw: "",
          base64: "",
        });
      });

      drEventThumb.on("dropify.afterClear", function (event, element) {
        setimg_thumbnail({
          preview: "",
          raw: "",
          base64: "",
        });
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    // $(".dropify").dropify();
    // console.log(id);
    getOptionMenuGroup();
    initState();
  }, []);

  const initState = async () => {
    // await getAddOn();
    await getData();
  };
  useEffect(() => {
    getData();
  }, [option_menu_group]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">เพิ่มรายการอาหาร</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                เพิ่มรายการอาหาร
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รูป Cover(1000*500)</label>
                    </div>
                    <div className="col-lg-5" id="preview-img-div">
                      <input
                        type="file"
                        id="preview-img"
                        className="dropify"
                        data-max-file-size="1M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file={`${img_url}${img_cover.preview}`}
                        onChange={async (e) => {
                          if (e.target.files.length) {
                            let img_file = e.target.files[0];
                            let base64img = await toBase64(img_file);
                            if (await image_size(base64img, 500, 500)) {
                              setimg_cover({
                                preview: URL.createObjectURL(img_file),
                                base64: base64img,
                              });
                            } else {
                              setTimeout(() => {
                                $(
                                  "#preview-img-div .dropify-wrapper .dropify-clear"
                                ).trigger("click");
                                alert("ขนาดภาพเกินกว่าที่กำหนด");
                              }, 50);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รูป Thumbnail(150*150)</label>
                    </div>
                    <div className="col-lg-5" id="thumbnail-img-div">
                      <input
                        type="file"
                        id="thumbnail-img"
                        className="dropify dropify-thumb"
                        data-max-file-size="1M"
                        accept=".png, .jpg, .jpeg"
                        data-default-file={
                          img_thumbnail.preview === ""
                            ? ""
                            : `${img_url}${img_thumbnail.preview}`
                        }
                        onChange={async (e) => {
                          if (e.target.files.length) {
                            let img_file = e.target.files[0];
                            let base64img = await toBase64(img_file);
                            if (await image_size(base64img, 150, 150)) {
                              setimg_thumbnail({
                                preview: URL.createObjectURL(img_file),
                                base64: base64img,
                              });
                            } else {
                              setTimeout(() => {
                                $(
                                  "#thumbnail-img-div .dropify-wrapper .dropify-clear"
                                ).trigger("click");
                                alert("ขนาดภาพเกินกว่าที่กำหนด");
                              }, 50);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">ชื่อเมนู</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="name"
                        className="form-control"
                        name="name"
                        type="text"
                        value={menu_name}
                        onChange={(e) => {
                          setmenu_name(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">รายละเอียด</label>
                    </div>
                    <div className="col-lg-5">
                      <SimpleMDE
                        id="detail-restaurant"
                        // label="Your label"
                        onChange={(value) => {
                          setdetail(value);
                        }}
                        value={detail}
                        options={{
                          autofocus: true,
                          spellChecker: false,
                          // etc.
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="time">เวลาการปรุง(นาที)</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="time"
                        className="form-control"
                        name="number"
                        type="number"
                        value={time}
                        onChange={(e) => {
                          settime(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="price">ราคา</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="price"
                        className="form-control"
                        name="price"
                        type="number"
                        value={price}
                        onChange={(e) => {
                          setprice(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สินค้านี้กำลังลดราคา</label>
                    </div>
                    <div className="col-lg-5">
                      <label className="toggle-switch toggle-switch-success">
                        <input
                          type="checkbox"
                          checked={sale}
                          onChange={() => {
                            setsale(!sale);
                          }}
                        />
                        <span className="toggle-slider round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sale_price">ราคาโปรโมชั่น</label>
                    </div>
                    <div className="col-lg-5">
                      <input
                        id="sale_price"
                        className="form-control"
                        name="sale_price"
                        type="number"
                        value={sale_price}
                        onChange={(e) => {
                          setsale_price(parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">หมวดหมู่</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        ref={menu_group_ref}
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: is_group_menu ? "red" : "#cdd4e0",

                            "&:focus": {
                              borderColor: is_group_menu ? "red" : "#cdd4e0",
                            },
                          }),
                        }}
                        value={menu_group}
                        onChange={(e) => {
                          setmenu_group(e);
                          if (e.length > 0) {
                            setis_group_menu(false);
                          } else {
                            setis_group_menu(true);
                          }
                        }}
                        options={option_menu_group}
                        placeholder={"เลือกหมวดหมู่..."}
                        closeMenuOnSelect={false}
                        isMulti
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="name">สถานะ</label>
                    </div>
                    <div className="col-lg-5">
                      <Select
                        value={status}
                        onChange={(e) => {
                          setstatus(e);
                        }}
                        options={menu_status_option}
                        placeholder={"สถานะ"}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <h5>เพิ่ม Add On</h5>
          <div className="card">
            <div className="card-body">
              {add_on.map((value, idx) => {
                return (
                  <table id="example" className="table " key={idx}>
                    <thead>
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            backgroundColor: "#f1f3f6",
                            paddingTop: ".85rem",
                            paddingBottom: ".85rem",
                          }}
                        >
                          <div className="row">
                            <div className="col-7">
                              {" "}
                              <span className="h5 ml-2 mt-2">
                                {value.name_th}
                              </span>
                            </div>
                            <div className="col-5">
                              <div
                                style={{
                                  float: "right",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                ขั้นต่ำ:
                                <input
                                  type="number"
                                  className="form-control ml-2 mr-2"
                                  style={{ maxWidth: 65 }}
                                  value={menu_add_on[idx].min}
                                  onChange={(e) => {
                                    if (e.target.value && e.target.value > 0) {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].min = parseInt(e.target.value);
                                      setmenu_add_on(tmp);
                                    } else {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].min = 0;
                                      setmenu_add_on(tmp);
                                    }
                                  }}
                                />
                                ไม่เกิน:
                                <input
                                  type="number"
                                  className="form-control "
                                  min={1}
                                  style={{ maxWidth: 65 }}
                                  value={menu_add_on[idx].max}
                                  onChange={(e) => {
                                    if (e.target.value && e.target.value >= 1) {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].max = parseInt(e.target.value);
                                      setmenu_add_on(tmp);
                                    } else {
                                      let tmp = [...menu_add_on];
                                      tmp[idx].max = 1;
                                      setmenu_add_on(tmp);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {value.options.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <span className=" ml-2 ">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={
                                    menu_add_on[idx].options_id[i].checked
                                  }
                                  onChange={() => {
                                    let tmp = [...menu_add_on];
                                    tmp[idx].options_id[i].checked = !tmp[idx]
                                      .options_id[i].checked;
                                    setmenu_add_on(tmp);
                                  }}
                                />
                                {v.name_th}
                              </span>
                            </td>
                            <td align={"right"}>{v.price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <Link
              to={`/brands/detail/${id}/menu`}
              className="btn btn-outline-primary mr-2 ml-auto mt-2 "
            >
              ยกเลิก
            </Link>

            <button
              onClick={() => {
                if (menu_group.length > 0) {
                  setis_group_menu(false);

                  let tmp_menu = [];

                  menu_add_on.forEach((value) => {
                    let options_id = [];
                    value.options_id.forEach((v) => {
                      if (v.checked === true) options_id.push(v.id);
                    });

                    if (options_id.length !== 0) {
                      tmp_menu.push({
                        add_on_id: value.add_on_id,
                        options_id: options_id,
                        // status: value.status.value,
                        min: value.min,
                        max: value.max,
                      });
                    }
                  });

                  let tmp_menu_group = [];
                  menu_group.forEach((vl) => {
                    tmp_menu_group.push(vl.value);
                  });

                  let send_data = {
                    cook_time: time,
                    // cover: img_cover.base64,
                    cover:
                      img_cover.base64 === ""
                        ? img_cover.preview
                        : img_cover.base64,
                    // cover: "",
                    description: detail,
                    is_promotion: sale,
                    menu_add_on: tmp_menu.filter(
                      (vl) => vl.options_id.length > 0
                    ),
                    // menu_group_id: [menu_group.value],
                    menu_group_id: tmp_menu_group,

                    name_en: "",
                    name_th: menu_name.trim(),
                    price: price,
                    promotion_price: sale_price,
                    status: status.value,
                    thumbnail:
                      img_thumbnail.base64 === ""
                        ? img_thumbnail.preview
                        : img_thumbnail.base64,
                    // thumbnail: "",
                  };
                  // console.log(send_data);

                  sendData(menu_id, send_data);
                } else {
                  setis_group_menu(true);
                  menu_group_ref.current.focus();
                }
              }}
              className="btn btn-primary  mr-2  mt-2 "
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
