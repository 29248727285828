import React, { useState, useEffect,  } from "react";

import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import Axios from "axios";
import { api, img_url, header_token } from "../../config";
import swal from "sweetalert";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const RestaurantDetailCredit = (props) => {
  const [loading, setloading] = useState(false);
  const [img, setimg] = useState("");

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_from, setfilter_from] = useState(moment().format("DD/MM/YYYY"));
  const [filter_to, setfilter_to] = useState(moment().format("DD/MM/YYYY"));

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
      let end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        status: -1,
        type: 1,
        start_date: start,
        end_date: end,
        restaurant_id: props.restaurantId,
      };
      // console.log(send_data);

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      settotal_count(data.meta.total_count);

      setdata(data.wallets);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    // console.log(props.restaurantId);
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_from, filter_to]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div className="card-body">
      <div className="row">
        <h4 className="mt-3 ml-2">ทั้งหมด {total_count} รายการ</h4>
        <div className="col-10 my-1 ml-auto">
          <div className="InputFromTo" style={{ float: "right" }}>
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
          </div>
        </div>
      </div>

      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="dataTable" className="table">
            <thead>
              <tr>
                {/* <th width="30">
              <input
                type="checkbox"
                className="selectAll "
                name="selectAll"
                value="all"
              />
            </th> */}
                <th>วันที่</th>
                <th>จำนวนเงิน</th>
                <th>ไฟล์แนบ</th>
                <th>สถานะ</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => {
                return (
                  <tr>
                    <td>
                      {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                      {moment(value.created_at).format("LT")}
                    </td>
                    <td>฿{value.amount}</td>
                    <td className="dt-edit">
                      <div
                        className="thumbnail cursor-img"
                        data-toggle="modal"
                        data-target=".show-img"
                        onClick={() => {
                          setimg(
                            value.picture === ""
                              ? "/images/no-img.jpg"
                              : `${img_url}${value.picture}`
                          );
                        }}
                      >
                        <img
                          className="lazy img-responsive"
                          src={
                            value.picture === ""
                              ? "/images/no-img.jpg"
                              : `${img_url}${value.picture}`
                          }
                        />
                      </div>
                    </td>
                    <td>
                      {(() => {
                        switch (value.status) {
                          case 0:
                            return "ยกเลิก";
                          case 1:
                            return "อนุมัติ";
                          case 2:
                            return "รอตรวจสอบ";
                          case 3:
                            return "รอดำเนินการ";
                          default:
                            break;
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </LoadingMask>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
