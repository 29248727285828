import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import Axios from "axios";
import { api, header_token } from "../../config";
import swal from "sweetalert";

export const RestaurantEditAdmin = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    sendToParent() {
      props.callbackAdmin({
        email: email,
        password: password,
      });
    },
  }));
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [show, setshow] = useState(false);
  const [error, seterror] = useState(<span />);

  const receiveProps = async (parentData) => {
    setemail(parentData.username);
  };

  const changePassword = async () => {
    try {
      let send_data = {
        password: password,
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: email,
      };
      let res = await Axios.put(
        `${api}/restaurants/${props.parentData.id}/change-password`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 201 || res.status === 200) {
        swal(`แก้ไขเสร็จสิ้น`, {
          icon: "success",
          button: false,
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(props.parentData).length > 0) {
      receiveProps(props.parentData);
    }
  }, [props.parentData]);

  return (
    <div>
      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="name">Email</label>
        </div>
        <div className="col-lg-5">
          <input
            className="form-control"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="name">Password</label>
        </div>
        <div className="col-lg-5">
          <input
            id="password"
            className="form-control"
            name="password"
            placeholder=""
            type={show === false ? "password" : "text"}
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-lg-3">
          <label htmlFor="name">Confirm password</label>
        </div>
        <div className="col-lg-5">
          {error}

          <input
            id="confirm_password"
            className="form-control"
            name="confirm_password"
            placeholder=""
            type={show === false ? "password" : "text"}
            value={confirmpassword}
            onChange={(e) => setconfirmpassword(e.target.value)}
          />
          <div className="form-check mt-4 ml-2">
            <input
              type="checkbox"
              className="mr-2"
              defaultValue={show}
              onChange={() => setshow(!show)}
            />
            Show Password
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              tabIndex="0"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                if (password !== confirmpassword) {
                  seterror(
                    <label htmlFor="name" style={{ color: "red" }}>
                      รหัสผ่านไม่ตรงกัน
                    </label>
                  );
                } else {
                  seterror(<span />);
                  changePassword();
                }
              }}
            >
              เปลี่ยนรหัสผ่าน
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
