import React, { useContext, useEffect, useState } from "react";
import { RestaurantDetailHistory } from "./RestaurantDetailHistory";
import { RestaurantDetailSale } from "./RestaurantDetailSale";
import { RestaurantDetailCredit } from "./RestaurantDetailCredit";
import { RestaurantDetailMenu } from "./RestaurantDetailMenu";
import { RestaurantDetailMenuGroup } from "./RestaurantDetailMenuGroup";
import { RestaurantDetailRate } from "./RestaurantDetailRate";
import {
  Switch,
  BrowserRouter as Route,
  NavLink,
  useHistory,
} from "react-router-dom";

import { NotiContext } from "../../store/keep";
import { api } from "../../config";
import Axios from "axios";
import swal from "sweetalert";

export const RestaurantDetail = () => {
  const { push } = useHistory();
  const { restaurantObj } = useContext(NotiContext);
  const [restaurant, setRestaurant] = useState();

  const pushToLink = async (link) => {
    setTimeout(() => {
      push(link);
    }, 50);
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/restaurants/${restaurantObj.id}`);
      setRestaurant(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">ร้านอาหาร</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">ร้านอาหาร</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {restaurantObj.name_th}
              </p>
            </div>
          </div>
          <div className="button-wrapper d-flex align-items-center mt-md-3 mt-xl-0"></div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <h3 className="mb-4 mt-2">{restaurantObj.name_th}</h3>
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-tabs nav-fill card-header-tabs">
                <li className="nav-item">
                  <NavLink
                    exact
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant`}
                  >
                    ประวัติ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant/sale`}
                  >
                    ยอดขาย
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant/credit`}
                  >
                    เครดิต
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant/menu`}
                  >
                    เมนู
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant/catergory`}
                  >
                    หมวดหมู่
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/restaurant/rate`}
                  >
                    คะแนนรีวิว
                  </NavLink>
                </li>
              </ul>
            </div>

            <Switch>
              <Route exact path={`/restaurant`}>
                <RestaurantDetailHistory
                  restaurantId={restaurantObj.id}
                  pushToLink={pushToLink}
                />
              </Route>
              <Route exact path={`/restaurant/sale`}>
                <RestaurantDetailSale
                  restaurantId={restaurantObj.id}
                  pushToLink={pushToLink}
                />
              </Route>
              <Route exact path={`/restaurant/credit`}>
                <RestaurantDetailCredit restaurantId={restaurantObj.id} />
              </Route>
              <Route exact path={`/restaurant/menu`}>
                <RestaurantDetailMenu
                  restaurant={restaurant}
                  restaurantId={restaurantObj.id}
                  pushToLink={pushToLink}
                />
              </Route>
              <Route exact path={`/restaurant/catergory`}>
                <RestaurantDetailMenuGroup 
                  restaurant={restaurant}
                  restaurantId={restaurantObj.id} />
              </Route>
              <Route exact path={`/restaurant/rate`}>
                <RestaurantDetailRate restaurantId={restaurantObj.id} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <a
          href="restaurant_add.php"
          className="btn btn-outline-primary mr-2 ml-auto mt-2 "
        >
          แก้ไข
        </a>
        <a href="#" className="btn btn-primary  mr-2  mt-2 ">
          บันทึก
        </a>
      </div> */}
    </div>
  );
};
