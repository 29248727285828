import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import { NotFound } from "./NotFound";
import { TopbarRestaurant } from "./Components/TopbarRestaurant";
import { SidebarRestaurant } from "./Components/SidebarRestaurant";
import { DashboardRestaurant } from "./Components/DashboardRestaurant";
import { RestaurantEdit } from "./Components/RestaurantOnly/RestaurantEdit";
import { RestaurantDetail } from "./Components/RestaurantOnly/RestaurantDetail";
import { RestaurantDetailMenuAdd } from "./Components/RestaurantOnly/RestaurantDetailMenuAdd";
import { OrderIndex } from "./Components/OrderRestaurant/OrderIndex";
import { SaleBillIndex } from "./Components/SaleBillRestaurant/SaleBillIndex";
import { RestaurantDetailMenuEdit } from "./Components/RestaurantOnly/RestaurantDetailMenuEdit";
import { OrderDetail } from "./Components/OrderRestaurant/OrderDetail";
import { NotificationFirebaseRestaurant } from "./store/NotificationFirebaseRestaurant";
import { NotiContext } from "./store/keep";
import Axios from "axios";
import { api, header_token } from "./config";
import swal from "sweetalert";

export const Restaurant = () => {
  const [token] = useState(localStorage.getItem("token"));

  const [noti, setnoti] = useState({
    rider: 0,
    restuarant: 0,
    order: 0,
    withdraw: 0,
    credit: 0,
    getdata: "",
  });
  const [restaurantObj, setrestaurantObj] = useState({});

  const getRestaurantOwner = async (id) => {
    try {
      let { data } = await Axios.get(`${api}/restaurants/owner`, header_token);
      setrestaurantObj(data);
    } catch (error) {
      swal(`กรุณาล็อคอินใหม่อีกครั้ง`, {
        icon: "error",
        button: false,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getRestaurantOwner();
  }, []);

  return (
    <NotiContext.Provider
      value={{ noti, setnoti, restaurantObj, setrestaurantObj }}
    >
      <div className="container-scroller">
        <TopbarRestaurant />
        <div className="container-fluid page-body-wrapper">
          <SidebarRestaurant />
          <div className="main-panel">
            <div className="content-wrapper">
              <NotificationFirebaseRestaurant />

              <Switch>
                {/* -------------------------------------- Restaurant --------------------------------------  */}
                <PrivateRoute
                  token={token}
                  path="/restaurant/menu-add"
                  component={RestaurantDetailMenuAdd}
                  isAllowed={true}
                />
                <PrivateRoute
                  token={token}
                  path="/restaurant/menu-edit/:menu_id"
                  component={RestaurantDetailMenuEdit}
                  isAllowed={true}
                />
                <PrivateRoute
                  token={token}
                  path="/restaurant/edit"
                  component={RestaurantEdit}
                  isAllowed={true}
                />

                <PrivateRoute
                  token={token}
                  path="/restaurant"
                  component={RestaurantDetail}
                  isAllowed={true}
                />

                {/* -------------------------------------- Order -------------------------------------- */}
                <PrivateRoute
                  token={token}
                  exact
                  path="/order/detail/:id"
                  component={OrderDetail}
                  isAllowed={true}
                />
                <PrivateRoute
                  token={token}
                  path="/order"
                  component={OrderIndex}
                  isAllowed={true}
                />

                <PrivateRoute
                  token={token}
                  path="/sale_bill"
                  component={SaleBillIndex}
                  isAllowed={true}
                />

                <PrivateRoute
                  token={token}
                  exact
                  path="/"
                  component={DashboardRestaurant}
                  isAllowed={true}
                />
                <PrivateRoute
                  token={token}
                  path="*"
                  component={NotFound}
                  isAllowed={true}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </NotiContext.Provider>
  );
};
