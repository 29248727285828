import React, { useState, useEffect } from "react";
import { api, header_token, image_size } from "../../config";
import axios from "axios";
import Select from "react-select";
import "react-loadingmask/dist/react-loadingmask.css";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { toBase64 } from "../../Helper/format";

export const ServiceFeeRecommendAdd = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const { push } = useHistory();

  const [service, setservice] = useState("");
  const [status, setstatus] = useState("");
  const [filename, setfilename] = useState("");
  const [banner, setbanner] = useState("");
  const [service_option, setservice_option] = useState("");

  const getServiceOption = async () => {
    try {
      let { data } = await axios.get(
        `${api}/service?status=${1}`,
        header_token
      );
      if (data) {
        let tmp = [...data];

        tmp.forEach((val, idx) => {
          tmp[idx].label = val.name;
          tmp[idx].value = val.id;
        });

        setservice_option(tmp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      let send_data = {
        cover: banner.base64,
        title: data.headline,
        status: parseInt(status.value),
        service_id: service.value,
      };

      // let response = { status: 20012 };
      let response = await axios.post(
        `${api}/admin/service-recommend`,
        send_data,
        header_token
      );

      if (response.status === 200 || response.status === 201) {
        swal("เพิ่มหมวดหมู่บริการเสร็จสิ้น !!", {
          icon: "success",
          timer: 1500,
        });
        setTimeout(() => {
          push(`/service_fee/recommend`);
        }, 1600);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getServiceOption();
  }, []);
  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">บริการ</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">บริการแนะนำ</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>เพิ่มบริการแนะนำ</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="name">
                                  รูป Banner (400*200)
                                </label>
                              </div>
                              <div className="col-lg-5">
                                <img
                                  id="img-upload"
                                  src={banner.preview}
                                  style={{ maxWidth: "100%", maxHeight: 500 }}
                                />
                                <div className="row">
                                  <div className="col-10">
                                    <input
                                      type="file"
                                      id="imgInp"
                                      className={`form-control ${errors.banner ? "error-input" : ""
                                        }`}
                                      accept=".png, .jpg, .jpeg"
                                      value={filename}
                                      onChange={async (e) => {
                                        if (e.target.files.length) {
                                          setfilename(e.target.value);

                                          let img_file = e.target.files[0];
                                          let base64img = await toBase64(
                                            img_file
                                          );

                                          if (
                                            await image_size(
                                              base64img,
                                              400,
                                              200
                                            )
                                          ) {
                                            setbanner({
                                              preview: URL.createObjectURL(
                                                img_file
                                              ),
                                              base64: base64img,
                                            });
                                            trigger("banner");
                                          } else {
                                            setfilename("");
                                            alert("ขนาดภาพเกินกว่าที่กำหนด");
                                          }
                                        }
                                      }}
                                      ref={(e) => {
                                        register(
                                          { name: "banner" },
                                          {
                                            validate: (value) => {
                                              return banner.base64 ===
                                                undefined ||
                                                banner.base64 === ""
                                                ? false
                                                : true;
                                            },
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <button
                                      type="button"
                                      className="btn btn-light btn-sm mt-1"
                                      onClick={() => {
                                        setfilename("");
                                        setbanner({
                                          preview: null,
                                          base64: "",
                                        });
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="name"
                                className="col-lg-3 col-form-label"
                              >
                                Headline
                              </label>

                              <div className="col-lg-5">
                                <input
                                  id={`headline`}
                                  name={`headline`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${errors.headline ? "error-input" : ""
                                    }`}
                                  type="text"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="service">เลือกบริการ</label>
                              </div>
                              <div className="col-lg-5">
                                <Select
                                  name="service"
                                  options={service_option}
                                  value={service}
                                  onChange={async (e) => {
                                    await setservice(e);
                                    trigger("service");
                                  }}
                                  placeholder={"บริการ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.service
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.service
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "service" },
                                      {
                                        validate: () => {
                                          return service.value ? true : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="status">สถานะ</label>
                              </div>
                              <div className="col-lg-5">
                                <Select
                                  name="status"
                                  options={[
                                    { label: "ใช้งาน", value: 1 },
                                    { label: "ระงับ", value: "0" },
                                  ]}
                                  value={status}
                                  onChange={async (e) => {
                                    await setstatus(e);
                                    trigger("status");
                                  }}
                                  placeholder={"สถานะ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.status
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.status
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "status" },
                                      {
                                        validate: () => {
                                          return status.value ? true : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "10%" }}
                              >
                                บันทึก
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
