import React, { useEffect } from "react";
import { useState } from "react";

export const NotFound = () => {
  const [state, setstate] = useState(null);
  useEffect(() => {
    let a = setTimeout(() => {
      setstate(
        <div style={{ textAlign: "center" }}>
          <img src="/images/something-lost.png" alt="" />
          <h1>Oops, looks like the page is lost.</h1>
          <br />

          <h3>
            This is not a fault, just an accident that was not intentional.
          </h3>
        </div>
      );
    }, 888);

    return () => {
      clearTimeout(a);
    }
  }, []);
  return <div>{state}</div>;
};
