import React, { useEffect, useRef } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportRiderData = ({ data, trigger, settrigger }) => {
  const exportButton = useRef();

  useEffect(() => {
    if (trigger) {
      exportButton.current.click();
      settrigger(false);
    }
  }, [trigger]);

  return (
    <ExcelFile
      filename={`Rider_export_${moment(new Date()).format("DD-MM-YYYY")}`}
      element={
        <button
          type="submit"
          className="btn btn-primary mr-2"
          style={{ marginTop: 23, display: "none" }}
          ref={exportButton}
        >
          Download Data
        </button>
      }
    >
      <ExcelSheet data={data} name="Restaurant">
        <ExcelColumn label="ชื่อ-นามสกุล" value="name" />
        <ExcelColumn label="เลขที่บัตรประชาชน" value="identification_number" />
        <ExcelColumn label="วัน/เดือน/ปีเกิด" value="birth_date" />
        <ExcelColumn label="วุฒิการศึกษา" value="degree" />
        <ExcelColumn label="ที่อยู่" value="address" />
        <ExcelColumn label="ตำบล" value="sub_district" />
        <ExcelColumn label="อำเภอ" value="district" />
        <ExcelColumn label="จังหวัด" value="province" />
        <ExcelColumn label="รหัสไปรษณีย์" value="zip_code" />
        <ExcelColumn label="พื้นที่" value="area" />
        <ExcelColumn label="ทีมงาน" value="team" />
        <ExcelColumn label="เบอร์โทรศัพท์" value="phone_number" />
        <ExcelColumn label="ID Line" value="line_id" />
        <ExcelColumn label="อีเมล์" value="email" />
        <ExcelColumn label="เครดิต" value="credit" />
        <ExcelColumn label="แต้ม" value="point" />
        <ExcelColumn label="ประเภท" value="employee_type" />
        <ExcelColumn label="วันที่สมัคร" value="register" />
        <ExcelColumn label="พาหนะ" value="vehicle_type" />
        <ExcelColumn label="เลขทะเบียน" value="license_plate" />
        <ExcelColumn label="รุ่น" value="vehicle_make" />
        <ExcelColumn label="สี" value="vehicle_color" />
        <ExcelColumn label="สถานะ" value="status" />
      </ExcelSheet>
    </ExcelFile>
  );
};
