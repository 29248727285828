import React, { useEffect, useState, useRef, useContext } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert";
import Axios from "axios";
import { api, header_token, header_token_getfile } from "../../config";
import { ExportOrderCarData } from "./ExportOrderCarData";
import { NotiContext } from "../../store/keep";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

const option_order = [{ value: "cancel", label: "ยกเลิก" }];

export const OrderCarHistory = (props) => {
  const { start_date, optionArea, statusOrder } = props;
  const { noti } = useContext(NotiContext);

  const checkAllRef = useRef(null);
  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [loading, setloading] = useState(false);
  const [is_first, setis_first] = useState(true);
  const [total_count, settotal_count] = useState(0);

  const [filter_order, setfilter_order] = useState("");
  const [filter_status, setfilter_status] = useState(null);

  const [id, setid] = useState("");
  const [filter_area, setfilter_area] = useState(null);
  const [filter_name, setfilter_name] = useState("");

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  // const exportexcel = async () => {
  //   setexport_loading(true);

  //   try {
  //     let _export = [];
  //     if (data) {
  //       // console.log(data);
  //       data.forEach((value, index) => {
  //         let re;
  //         switch (value.status) {
  //           case 0:
  //             re = "ยกเลิก";
  //             break;
  //           case 1:
  //             re = "ออเดอร์ใหม่";
  //             break;
  //           case 2:
  //             re = "พนักงานรับออเดอร์";
  //             break;
  //           case 3:
  //             re = "ดำเนินการ";
  //             break;
  //           case 4:
  //             re = "กำลังจัดส่ง";
  //             break;
  //           case 5:
  //             re = "ส่งสำเร็จ";
  //             break;
  //           case 6:
  //             re = "ส่งไม่สำเร็จ";
  //             break;
  //           default:
  //             break;
  //         }
  //         if (value.type === 0) {
  //           re = "ออเดอร์ล่วงหน้า";
  //         }

  //         let _addon = "";
  //         if (value.car_service_addons) {
  //           value.car_service_addons.map((v, i) => {
  //             _addon += `${v.name} ${
  //               value.car_service_addons.length !== i + 1 ? "," : null
  //             }`;
  //           });
  //         }

  //         let _destinations = "";
  //         if (value.car_service_destinations) {
  //           value.car_service_destinations.map((v, inx) => {
  //             _destinations += `${
  //               value.car_service_destinations.length !== 1
  //                 ? `(${inx + 1})`
  //                 : ""
  //             } ${v.address} \n`;
  //           });
  //         }

  //         _export.push({
  //           order_no: value.order_no,
  //           order_time: `${moment(value.created_at).format(
  //             "DD/MM/YYYY"
  //           )} ${moment(value.created_at).format("LT")}`,
  //           delivery:
  //             value.type === 0
  //               ? `${moment(value.due_date).format("DD/MM/YYYY")}  ${moment(
  //                   value.due_date
  //                 ).format("LT")}`
  //               : "สังทันที",
  //           car_service_name: `${value.car_service_name}`,
  //           car_service_addons: _addon,
  //           car_service_cost: `${value.car_service_cost.toLocaleString("en", {
  //             maximumFractionDigits: 0,
  //           })}`,
  //           car_service_addon_cost: value.car_service_addon_cost.toLocaleString(
  //             "en",
  //             {
  //               maximumFractionDigits: 0,
  //             }
  //           ),
  //           address: `${value.car_service_start_point.address} ${value.phone_number}`,
  //           destinations: _destinations,
  //           rider: `${value.rider ? value.rider.full_name : ""} ${
  //             value.rider ? value.rider.phone_number : ""
  //           }`,
  //           distance: `~ ${value.distance}km`,
  //           total: value.total,
  //           status: re,
  //         });
  //       });
  //     }
  //     // console.log("_export", _export);
  //     await setexport_data(_export);
  //   } catch (error) {
  //     // console.log(error.response);
  //   }

  //   setexport_loading(false);
  // };

  const getDataExport = async () => {
    setexport_loading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: start,
        order_no: id ? id : "",
        area_id: filter_area?.value ?? "",
        service: ["car"],
        status: filter_status?.value
          ? [parseInt(filter_status.value)]
          : statusOrder,
      };

      // console.log("send", send_data);
      let data = await Axios.post(
        `${api}/admin/order/filter/csv`,
        send_data,
        header_token_getfile
      );

      // console.log("data", data.headers);
      const type = data.headers["content-type"];
      const blob = new Blob([data.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = `${moment(new Date()).format(
        "DD-MM-YYYY"
      )}`;
      link.click();
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setexport_loading(false);
  };

  const cancelOrder = async (order_id) => {
    try {
      let res = await Axios.get(
        `${api}/admin/order/cancel/${order_id}`,
        header_token
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
    }
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD");

      let send_data = {
        start_date: start,
        end_date: start,
        order_no: id ? id : "",
        area_id: filter_area ? filter_area.value : "",
        restaurant_name: filter_name ? filter_name : "",
        service: ["car"],
        status: filter_status.value
          ? [parseInt(filter_status.value)]
          : statusOrder,
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${current_page}&limit=${limit}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);
        setdata(data.orders);
      } else {
        setdata([]);
        settotal_count(0);
      }
      setis_first(false);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
    setloading(false);
  };

  useEffect(() => {
    if (is_first) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (!is_first) {
      console.log("start");
      getData();
    }
  }, [start_date]);

  useEffect(() => {
    if (!is_first) {
      setcurrent_page(1);
      getData();
    }
  }, [filter_area, id, filter_name, filter_status]);

  useEffect(() => {
    if (!is_first) {
      getData();
    }
  }, [current_page]);

  useEffect(() => {
    if (noti.getdata === "car_service") {
      getData();
    }
  }, [noti]);
  return (
    <div>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  {/* <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ชื่อร้านอาหาร</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      placeholder="ชื่อร้าน"
                      value={filter_name}
                      onChange={(e) => setfilter_name(e.target.value)}
                    />
                  </div> */}

                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">Order Id</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ID"
                      value={id}
                      onChange={(e) => setid(e.target.value)}
                    />
                  </div>

                  <div className="mr-1" style={{ width: 150 }}>
                    <span className="tx-13 text-muted">พื้นที่</span>
                    <Select
                      value={filter_area}
                      onChange={(e) => {
                        setfilter_area(e);
                      }}
                      options={optionArea}
                      placeholder={"พื้นที่.."}
                    />
                  </div>

                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 150 }} className="">
                        <span className="tx-13 text-muted">สถานะ</span>
                        <Select
                          value={filter_status}
                          onChange={(e) => {
                            setfilter_status(e);
                          }}
                          options={[
                            { value: 1, label: "ออเดอร์ใหม่" },
                            { value: 4, label: "กำลังจัดส่ง" },
                            { value: 5, label: "สำเร็จ" },
                            { value: "0", label: "ยกเลิก" },
                            { value: 6, label: "ไม่สำเร็จ" },
                            { value: 99, label: "ออเดอร์ล่วงหน้า" },
                          ]}
                          placeholder={"สถานะ..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col ml-1 mr-2">
                    <div className="row">
                      <div style={{ width: 120 }} className="">
                        <span className="tx-13 text-muted">ดำเนินการ</span>
                        <Select
                          value={filter_order}
                          onChange={async (e) => {
                            setfilter_order(e);
                          }}
                          options={option_order}
                          placeholder={"เลือก..."}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ maxWidth: 120 }}>
                    <button
                      data-toggle="modal"
                      data-target={`${filter_order.value === "assign" ? ".assign-order" : ""
                        }`}
                      className="btn btn-primary mr-2 "
                      style={{ height: 42, marginTop: 23 }}
                      onClick={async () => {
                        if (
                          !filter_order.value ||
                          data.filter((e) => e.checked === true).length === 0
                        ) {
                          alert("เลือกตัวดำเนินการ");
                          return;
                        }

                        if (filter_order.value === "cancel") {
                          swal({
                            title: `ต้องการยกเลิกออเดอร์ที่เลือกหรือไม่ ?`,
                            confirmButtonText: "ใช่",
                            cancelButtonText: "ไม่",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              let sending = [];
                              data.forEach((value) => {
                                if (value.checked === true) {
                                  sending.push(cancelOrder(value.id));
                                }
                              });

                              let is_success = true;
                              await Promise.all(sending).then((res) => {
                                // console.log(res);
                                if (
                                  res.filter((e) => e === true).length === 0 ||
                                  res.length === 0
                                )
                                  is_success = false;
                              });

                              if (is_success) {
                                swal("ยกเลิกเสร็จสิ้น !!", {
                                  icon: "success",
                                });
                              } else {
                                swal("เกิดข้อผิดพลาด !!", {
                                  icon: "error",
                                });
                              }
                            }
                          });
                        }
                      }}
                    >
                      ดำเนินการ
                    </button>
                  </div>
                  <div style={{ maxWidth: 120 }}>
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      style={{ marginTop: 23 }}
                      onClick={async () => {
                        getDataExport();
                        // if (!trigger && !export_loading) {
                        //   await exportexcel();
                        //   await settrigger(true);
                        // }
                      }}
                    >
                      {export_loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                      {` `}Export
                    </button>
                    <ExportOrderCarData
                      data={export_data}
                      trigger={trigger}
                      settrigger={settrigger}
                    />
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 30 }} className="sticky-head">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, index) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });

                                setdata(tmp);
                              }}
                            />
                          </th>
                          <th style={{ width: 150 }} className="sticky-head">
                            Order No.
                          </th>
                          <th style={{ width: 120 }}>เวลา</th>
                          <th style={{ width: 150 }}>การจัดส่ง</th>
                          <th style={{ width: 150 }}>บริการ</th>
                          <th style={{ width: 300 }}>ต้นทาง</th>
                          <th style={{ width: 300 }}>ปลายทาง</th>
                          <th style={{ width: 150 }}>ผู้รับงาน</th>
                          <th style={{ width: 80 }}>ค่าขนส่ง</th>
                          <th style={{ width: 120 }}>ค่าบริการเสริม</th>
                          <th style={{ width: 150 }}>โปรโมชั่น</th>
                          <th style={{ width: 80 }}>รวม</th>
                          <th style={{ width: 120 }}>ระยะทาง</th>
                          <th style={{ width: 150 }}>สถานะ</th>
                          <th style={{ width: 120 }}>ชำระ</th>
                          <th style={{ width: 150 }}>รายละเอียด</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked
                                  ? "#e8eaed "
                                  : "",
                              }}
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td
                                className="sticky-col"
                                style={{
                                  backgroundColor: value.checked
                                    ? "#e8eaed "
                                    : "",
                                }}
                              >
                                <a
                                  href="#"
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                >
                                  {value.order_no}
                                </a>
                              </td>
                              <td>
                                <i className="fa fa-clock-o mr-1"></i>
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {value.type === 0 ? (
                                  <span className="badge badge-outline-warning badge-pill">
                                    {moment(value.due_date).format(
                                      "DD/MM/YYYY"
                                    )}{" "}
                                    {moment(value.due_date).format("LT")}
                                  </span>
                                ) : (
                                  <span className="badge badge-outline-success badge-pill">
                                    สังทันที
                                  </span>
                                )}
                              </td>
                              <td>{value.car_service_name}</td>
                              <td>
                                {value.car_service_start_point.address}
                                <br />
                                {value.phone_number}
                              </td>
                              <td>
                                {value.car_service_destinations
                                  ? value.car_service_destinations.map(
                                    (v, inx) => (
                                      <div key={inx}>
                                        {value.car_service_destinations
                                          .length !== 1
                                          ? `(${inx + 1})`
                                          : null}{" "}
                                        {v.address}
                                      </div>
                                    )
                                  )
                                  : null}
                              </td>
                              <td>
                                {value.rider ? value.rider.full_name : ""}
                                <br />
                                {value.rider ? value.rider.phone_number : ""}
                              </td>
                              <td>฿{value.car_service_cost}</td>
                              <td>฿{value.car_service_addon_cost}</td>{" "}
                              <td>
                                {value.promotion_discount > 0 ? (
                                  <span>
                                    {value.promotion_code
                                      ? value.promotion_code
                                      : ""}{" "}
                                    {value.promotion_discount
                                      ? `(฿${value.promotion_discount.toLocaleString()})`
                                      : "0"}
                                  </span>
                                ) : null}
                              </td>
                              <td>฿{value.total}</td>
                              <td>~ {value.distance}km</td>
                              <td>
                                {(() => {
                                  if (value.type === 0)
                                    return "ออเดอร์ล่วงหน้า";

                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "ออเดอร์ใหม่";
                                    case 2:
                                      return "พนักงานรับออเดอร์";
                                    case 3:
                                      return "ดำเนินการ";
                                    case 4:
                                      return "กำลังจัดส่ง";
                                    case 5:
                                      return "ส่งสำเร็จ";
                                    case 6:
                                      return "ส่งไม่สำเร็จ";

                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>{value.payment_method}</td>
                              <td>
                                <a
                                  href="#"
                                  tabIndex={0}
                                  onClick={() =>
                                    props.pushToLink(
                                      `/car_service_order/detail/${value.id}`
                                    )
                                  }
                                  className="btn btn-outline-primary btn-sm"
                                >
                                  รายละเอียด
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
              <div className="mt-2" style={{ float: "right" }}>
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel={<span className="gap">...</span>}
                  pageCount={page_count}
                  onPageChange={(e) => setcurrent_page(e.selected + 1)}
                  forcePage={current_page - 1}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous_page"}
                  nextLinkClassName={"next_page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
