import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
import $ from "jquery";
import dropify from "dropify";
import {
  api,
  header_token,
  image_size,
  img_url,
} from "../../config";
import axios from "axios";
import Select from "react-select";
import "react-loadingmask/dist/react-loadingmask.css";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { toBase64 } from "../../Helper/format";

export const ServiceFeeEdit = () => {
  const { register, handleSubmit, errors, trigger, setValue } = useForm();
  const { push } = useHistory();
  const { service_id } = useParams();

  const [service_group, setservice_group] = useState("");
  const [description, setdescription] = useState(undefined);
  const [status, setstatus] = useState("");
  const [cover, setcover] = useState({
    preview: "",
    base64: "",
  });
  const [thumb, setthumb] = useState({
    preview: "",
    base64: "",
  });
  const [service_option, setservice_option] = useState([]);
  const [add_on, setadd_on] = useState([]);
  const [add_item, setadd_item] = useState({});
  const service_status = [
    { label: "ใช้งาน", value: 1 },
    { label: "ระงับ", value: 0 },
  ];
  
  const getServiceOption = async () => {
    try {
      let { data } = await axios.get(
        `${api}/service-group?status=${1}`,
        header_token
      );
      if (data) {
        let tmp = [...data];

        tmp.forEach((val, idx) => {
          tmp[idx].label = val.name;
          tmp[idx].value = val.id;
        });

        setservice_option(tmp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      let service_data = await axios.get(
        `${api}/service/${service_id}`,
        header_token
      );

      let add_on = await axios.get(
        `${api}/service-addon?service_id=${service_id}`,
        header_token
      );
      let tmp = add_on.data ? [...add_on.data] : [];
      for (let op of tmp) {
        let { data } = await axios.get(
          `${api}/service-addon-option?service_addon_id=${op.id}`,
          header_token
        );
        if (data) {
          data.forEach((val) => {
            val.edit = false;
            val.new = false;
          });
        }
        op.add_on = data ? data : [];
      }
      setadd_on(tmp);

      let _data = service_data.data;
      setValue("name", _data.name);
      setValue("price", _data.price);
      setValue("price_promotion", _data.price_promotion);
      setValue("percentage_commission", _data.percentage_commission);
      setValue("discount", _data.discount);
      setValue("area_distance", _data.area_distance);
      setservice_group(
        service_option.filter((e) => e.id === _data.service_group_id)[0]
      );
      setstatus(
        service_status.filter((e) => parseInt(e.value) === _data.status)[0]
      );
      setcover({
        preview: _data.cover ? _data.cover : "",
        base64: "",
      });
      setthumb({
        preview: _data.thumbnail ? _data.thumbnail : "",
        base64: "",
      });

      const blocksFromHtml = htmlToDraft(_data.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      setdescription(EditorState.createWithContent(contentState));

      $(".dropify").dropify();

      var drEventCover = $("#cover_img").dropify();
      var drEventThumb = $("#thumb_img").dropify();

      drEventCover.on("dropify.afterClear", async function (event, element) {
        await setcover({
          preview: "",
          base64: "",
        });
        trigger("cover_img");
      });

      drEventThumb.on("dropify.afterClear", async function (event, element) {
        await setthumb({
          preview: "",
          base64: "",
        });
        trigger("thumb_img");
      });
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const addAddOn = async (addon) => {
    try {
      let send_data = {
        max: addon.max,
        min: addon.min,
        name: addon.name,
        service_id: service_id,
      };
      let { data } = await axios.post(
        `${api}/admin/service-addon`,
        send_data,
        header_token
      );
      return data;
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editAddOn = async (addon) => {
    try {
      let send_data = {
        max: addon.max,
        min: addon.min,
        name: addon.name,
        service_id: service_id,
      };
      // console.log(send_data);
      let edit = await axios.put(
        `${api}/admin/service-addon/${addon.id}`,
        send_data,
        header_token
      );
      let isSuceess = edit.status === 200 ? true : false;

      if (isSuceess) {
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        return isSuceess;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const addAddOnOption = async (addon_option, service_addon_id) => {
    try {
      let send_data = {
        name: addon_option.name ? addon_option.name.trim() : "",
        price: addon_option.price ? parseFloat(addon_option.price) : 0,
        service_addon_id: service_addon_id,
        service_id: service_id,
      };
      // console.log(send_data);
      let { data, status } = await axios.post(
        `${api}/admin/service-addon-option`,
        send_data,
        header_token
      );
      let isSuceess = status === 201 || status === 200 ? true : false;

      if (isSuceess) {
        swal("เพิ่มเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        return { isSuceess, data };
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const editAddOnOption = async (addon_option) => {
    try {
      let send_data = {
        name: addon_option.name ? addon_option.name.trim() : "",
        price: addon_option.price ? parseFloat(addon_option.price) : 0,
        service_addon_id: addon_option.service_addon_id,
        service_id: service_id,
      };
      // console.log(send_data);
      let { status, data } = await axios.put(
        `${api}/admin/service-addon-option/${addon_option.id}`,
        send_data,
        header_token
      );
      let isSuceess = status === 200 ? true : false;

      if (isSuceess) {
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        return { isSuceess, data };
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteAddOnOption = async (id) => {
    try {
      let { status } = await axios.delete(
        `${api}/admin/service-addon-option/${id}`,
        header_token
      );
      let isSuceess = status === 200 ? true : false;

      if (isSuceess) {
        swal("ลบเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        return isSuceess;
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteAddOn = async (id) => {
    try {
      let del = await axios.delete(
        `${api}/admin/service-addon/${id}`,
        header_token
      );
      let isSuceess = del.status === 200 ? true : false;
      if (isSuceess) {
        swal("ลบเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        return isSuceess ? true : false;
      }
      // setdata(data);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      let send_data = {
        area_distance: parseFloat(data.area_distance),
        cover: cover.base64 ? cover.base64 : cover.preview,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        discount: data.discount,
        name: data.name.trim(),
        percentage_commission: parseInt(data.percentage_commission),
        price: parseInt(data.price),
        price_promotion: parseInt(data.price_promotion),
        service_group_id: service_group.value,
        status: parseInt(status.value),
        thumbnail: thumb.base64 ? thumb.base64 : thumb.preview,
      };
      // console.log("send_data", send_data);
      // let response = { status: 2200 };
      let response = await axios.put(
        `${api}/admin/service/${service_id}`,
        send_data,
        header_token
      );

      if (response.status === 200 || response.status === 201) {
        swal("แก้ไขบริการเสร็จสิ้น !!", {
          icon: "success",
          timer: 1500,
        });
        setTimeout(() => {
          push(`/service_fee`);
        }, 1600);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onEditorStateChange = (editorState) => {
    setdescription(editorState);
  };

  const uploadImageCallBack = async (file) => {
    let uploadedImages = [];

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
      base64: await toBase64(file),
    };

    uploadedImages.push(imageObject);

    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.base64 } });
    });
  };

  useEffect(() => {
    // console.log("test edit", );
    getServiceOption();
  }, []);

  useEffect(() => {
    if (service_option.length > 0) getData();
  }, [service_option]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">บริการ</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                บริการต่างๆ
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>เพิ่มบริการ</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="name">
                                  รูป Cover (400*300)
                                </label>
                              </div>
                              <div className="col-sm-5" id="cover_img_div">
                                <div className="mb-2">
                                  {errors.cover_img ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                      style={{ width: "100%", padding: 5 }}
                                    >
                                      กรุณาอัพโหลดไฟล์
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="cover_img"
                                  className="dropify"
                                  data-max-file-size="3M"
                                  accept=".png, .jpg, .jpeg"
                                  data-default-file={`${img_url}${cover.preview}`}
                                  onChange={async (e) => {
                                    if (e.target.files.length) {
                                      let img_file = e.target.files[0];
                                      let base64img = await toBase64(img_file);
                                      if (
                                        await image_size(base64img, 400, 300)
                                      ) {
                                        setcover({
                                          preview:
                                            URL.createObjectURL(img_file),
                                          base64: base64img,
                                        });
                                        trigger("cover_img");
                                      } else {
                                        setTimeout(() => {
                                          $(
                                            "#cover_img_div .dropify-wrapper .dropify-clear"
                                          ).trigger("click");
                                          alert("ขนาดภาพเกินกว่าที่กำหนด");
                                        }, 50);
                                      }
                                    }
                                  }}
                                // ref={(e) => {
                                //   register(
                                //     { name: "cover_img" },
                                //     {
                                //       validate: (value) => {
                                //         return cover.base64 === undefined ||
                                //           cover.base64 === ""
                                //           ? false
                                //           : true;
                                //       },
                                //     }
                                //   );
                                // }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="name">
                                  รูป Thumbnail (400*225)
                                </label>
                              </div>
                              <div className="col-sm-5" id="thumb_img_div">
                                <div className="mb-2">
                                  {errors.thumb_img ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                      style={{ width: "100%", padding: 5 }}
                                    >
                                      กรุณาอัพโหลดไฟล์
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="thumb_img"
                                  className="dropify"
                                  data-max-file-size="3M"
                                  accept=".png, .jpg, .jpeg"
                                  data-default-file={`${img_url}${thumb.preview}`}
                                  onChange={async (e) => {
                                    if (e.target.files.length) {
                                      let img_file = e.target.files[0];
                                      let base64img = await toBase64(img_file);
                                      if (
                                        await image_size(base64img, 400, 225)
                                      ) {
                                        setthumb({
                                          preview:
                                            URL.createObjectURL(img_file),
                                          base64: base64img,
                                        });
                                        trigger("thumb_img");
                                      } else {
                                        setTimeout(() => {
                                          $(
                                            "#thumb_img_div .dropify-wrapper .dropify-clear"
                                          ).trigger("click");
                                          alert("ขนาดภาพเกินกว่าที่กำหนด");
                                        }, 50);
                                      }
                                    }
                                  }}
                                // ref={(e) => {
                                //   register(
                                //     { name: "thumb_img" },
                                //     {
                                //       validate: (value) => {
                                //         return thumb.base64 === undefined ||
                                //           thumb.base64 === ""
                                //           ? false
                                //           : true;
                                //       },
                                //     }
                                //   );
                                // }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="name"
                                className="col-sm-3 col-form-label"
                              >
                                ชื่อ
                              </label>
                              <div className="col-sm-5">
                                <input
                                  id={`name`}
                                  name={`name`}
                                  ref={register({
                                    required: false,
                                  })}
                                  className={`form-control ${errors.name ? "error-input" : ""
                                    }`}
                                  type="text"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                หมวดหมู่
                              </label>
                              <div className="col-sm-5">
                                <Select
                                  name="service_group"
                                  options={service_option}
                                  value={service_group}
                                  onChange={async (e) => {
                                    await setservice_group(e);
                                    trigger("service_group");
                                  }}
                                  placeholder={"หมวดหมู่..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.service_group
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.service_group
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                // ref={(e) => {
                                //   register(
                                //     { name: "service_group" },
                                //     {
                                //       validate: () => {
                                //         return service_group.value ? true : false;
                                //       },
                                //     }
                                //   );
                                // }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="price"
                                className="col-sm-3 col-form-label"
                              >
                                ราคา (บาท)
                              </label>
                              <div className="col-sm-5">
                                <input
                                  id={`price`}
                                  name={`price`}
                                  style={{ width: 80 }}
                                  ref={register({
                                    required: false,
                                  })}
                                  className={`form-control ${errors.price ? "error-input" : ""
                                    }`}
                                  type="number"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="discount"
                                className="col-sm-3 col-form-label"
                              >
                                สินค้านี้กำลังลดราคา
                              </label>
                              <div
                                className="col-sm-5"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="discount"
                                  name="discount"
                                  // defaultChecked={}
                                  ref={register}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="price_promotion"
                                className="col-sm-3 col-form-label"
                              >
                                ราคาโปรโมชั่น(บาท)
                              </label>
                              <div className="col-sm-5">
                                <input
                                  id={`price_promotion`}
                                  name={`price_promotion`}
                                  style={{ width: 80 }}
                                  ref={register({
                                    required: false,
                                  })}
                                  className={`form-control ${errors.price_promotion ? "error-input" : ""
                                    }`}
                                  type="number"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="percentage_commission"
                                className="col-sm-3 col-form-label"
                              >
                                ค่าคอมมิชชั่น(%)
                              </label>
                              <div className="col-sm-5">
                                <input
                                  id={`percentage_commission`}
                                  name={`percentage_commission`}
                                  style={{ width: 80 }}
                                  ref={register({
                                    required: false,
                                  })}
                                  className={`form-control ${errors.percentage_commission
                                      ? "error-input"
                                      : ""
                                    }`}
                                  type="number"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="area_distance"
                                className="col-sm-3 col-form-label"
                              >
                                ระยะทาง (km)
                              </label>
                              <div className="col-sm-5">
                                <input
                                  id={`area_distance`}
                                  name={`area_distance`}
                                  style={{ width: 80 }}
                                  ref={register({
                                    required: false,
                                  })}
                                  className={`form-control ${errors.area_distance ? "error-input" : ""
                                    }`}
                                  type="number"
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="description"
                                className="col-sm-3 col-form-label"
                              >
                                รายละเอียด
                              </label>
                              <div className="col-sm-8">
                                <Editor
                                  editorStyle={{
                                    border: "1px solid #F1F1F1",
                                    borderRadius: 3,
                                    height: 400,
                                    lineHeight: 1,
                                  }}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  editorState={description}
                                  onEditorStateChange={onEditorStateChange}
                                  toolbar={{
                                    options: [
                                      "inline",
                                      "fontSize",
                                      "image",
                                      "colorPicker",
                                      "textAlign",
                                      "history",
                                    ],
                                    inline: {
                                      options: ["bold", "italic", "underline"],
                                    },
                                    image: {
                                      uploadCallback: uploadImageCallBack,
                                      previewImage: true,
                                      alt: { present: true, mandatory: false },
                                      inputAccept: "image/jpeg,image/jpg",
                                    },
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-lg-3">
                                <label htmlFor="name">สถานะ</label>
                              </div>
                              <div className="col-lg-2">
                                <Select
                                  name="status"
                                  options={service_status}
                                  value={status}
                                  onChange={async (e) => {
                                    await setstatus(e);
                                    trigger("status");
                                  }}
                                  placeholder={"สถานะ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.status
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.status
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary mr-2 ml-auto"
                              >
                                บันทึก
                              </button>
                              {/* <button className="btn btn-outline-primary mr-3">
                                ยกเลิก
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col mt-4">
                      <h4 className="mb-4 ">
                        รายละเอียดเพิ่มเติม / บริการเสริม
                      </h4>
                    </div>
                    <div className="row mt-2">
                      <button
                        data-toggle="modal"
                        data-target={`.add-service`}
                        className="btn btn-outline-primary  mr-4 ml-auto mt-1 "
                        style={{ height: 42 }}
                      >
                        + เพิ่มรายละเอียด / บริการเสริม
                      </button>
                    </div>
                  </div>

                  {add_on.length > 0 &&
                    add_on.map((val, idx) => {
                      let tmp = [...add_on];
                      return (
                        <div className="row mb-4" key={idx}>
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <label>ชื่อ</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={val.name}
                                      onChange={(e) => {
                                        tmp[idx].name = e.target.value;

                                        setadd_on(tmp);
                                      }}
                                    />
                                  </div>

                                  <div className="col">
                                    <div className="row ">
                                      <div className="col-2 ml-auto">
                                        <label>ขั้นต่ำ</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="0"
                                          value={val.min}
                                          onChange={(e) => {
                                            tmp[idx].min = parseInt(
                                              e.target.value
                                            );
                                            if (
                                              parseInt(e.target.value) >
                                              tmp[idx].max
                                            )
                                              tmp[idx].max = parseInt(
                                                e.target.value
                                              );

                                            setadd_on(tmp);
                                          }}
                                        />
                                      </div>

                                      <div className="col-2 ml-auto">
                                        <label>ไม่เกิน</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="0"
                                          value={val.max}
                                          onChange={(e) => {
                                            tmp[idx].max =
                                              parseInt(e.target.value) >
                                                parseInt(tmp[idx].min)
                                                ? parseInt(e.target.value)
                                                : parseInt(tmp[idx].min);

                                            setadd_on(tmp);
                                          }}
                                        />
                                      </div>

                                      <div className="col">
                                        <div className="row mt-4">
                                          <button
                                            className="btn btn-primary mr-2 ml-auto"
                                            onClick={() => {
                                              editAddOn(val);
                                            }}
                                          >
                                            บันทึก
                                          </button>
                                          <button
                                            className="btn btn-danger mr-2"
                                            onClick={() => {
                                              swal({
                                                title: `ต้องการบริการเสริมที่เลือกหรือไม่ ?`,
                                                confirmButtonText: "ใช่",
                                                cancelButtonText: "ไม่",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                              }).then(async (willDelete) => {
                                                if (willDelete) {
                                                  let del = deleteAddOn(val.id);
                                                  if (del) {
                                                    tmp.splice(idx, 1);
                                                    setadd_on(tmp);
                                                  }
                                                }
                                              });
                                            }}
                                          >
                                            <i className="fa fa-trash-o tx-20"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="content mt-2">
                                  <table id="add_on" className="table">
                                    <thead>
                                      <tr>
                                        <th width="800">ชื่อ</th>
                                        <th>ราคา(บาท)</th>
                                        <th width="80">Edit</th>
                                        <th width="80">Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {val.add_on.map((v, i) => (
                                        <tr key={i}>
                                          <td className="end">
                                            {v.edit ? (
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={v.name || ""}
                                                onChange={(e) => {
                                                  tmp[idx].add_on[i].name =
                                                    e.target.value;
                                                  setadd_on(tmp);
                                                }}
                                              />
                                            ) : (
                                              v.name
                                            )}
                                          </td>
                                          <td className="cost">
                                            {v.edit ? (
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="0"
                                                value={v.price || ""}
                                                onChange={(e) => {
                                                  tmp[idx].add_on[i].price =
                                                    e.target.value;
                                                  setadd_on(tmp);
                                                }}
                                              />
                                            ) : (
                                              v.price
                                            )}
                                          </td>
                                          <td className="edit">
                                            {v.edit === false ? (
                                              <i
                                                style={{ cursor: "pointer" }}
                                                className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                                onClick={() => {
                                                  tmp[idx].add_on[i].edit =
                                                    !tmp[idx].add_on[i].edit;

                                                  setadd_on(tmp);
                                                }}
                                              ></i>
                                            ) : (
                                              <i
                                                style={{ cursor: "pointer" }}
                                                className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                                onClick={async () => {
                                                  tmp[idx].add_on[i].edit =
                                                    !tmp[idx].add_on[i].edit;
                                                  let success;
                                                  if (v.new) {
                                                    let { isSuceess, data } =
                                                      await addAddOnOption(
                                                        v,
                                                        val.id
                                                      );

                                                    tmp[idx].add_on[i] = data;
                                                    tmp[idx].add_on[
                                                      i
                                                    ].edit = false;
                                                    tmp[idx].add_on[
                                                      i
                                                    ].new = false;

                                                    success = isSuceess;
                                                  } else {
                                                    let { isSuceess, data } =
                                                      await editAddOnOption(v);
                                                    tmp[idx].add_on[i] = data;
                                                    tmp[idx].add_on[
                                                      i
                                                    ].edit = false;
                                                    tmp[idx].add_on[
                                                      i
                                                    ].new = false;
                                                    success = isSuceess;
                                                  }

                                                  if (success) {
                                                    setadd_on(tmp);
                                                  }
                                                }}
                                              ></i>
                                            )}{" "}
                                          </td>
                                          <td className="trash">
                                            <i
                                              style={{ cursor: "pointer" }}
                                              className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                              onClick={() => {
                                                if (!v.new) {
                                                  swal({
                                                    title: `ต้องการลบรายการนี้หรือไม่ ?`,
                                                    confirmButtonText: "ใช่",
                                                    cancelButtonText: "ไม่",
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                  }).then(
                                                    async (willDelete) => {
                                                      if (willDelete) {
                                                        let isSuceess =
                                                          await deleteAddOnOption(
                                                            v.id
                                                          );

                                                        if (isSuceess) {
                                                          tmp[
                                                            idx
                                                          ].add_on.splice(i, 1);
                                                          setadd_on(tmp);
                                                        }
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  tmp[idx].add_on.splice(i, 1);
                                                  setadd_on(tmp);
                                                }
                                              }}
                                            ></i>
                                          </td>
                                        </tr>
                                      ))}

                                      <tr>
                                        <td
                                          className="new-row"
                                          colSpan="7"
                                          onClick={() => {
                                            tmp[idx].add_on.push({
                                              name: "",
                                              edit: true,
                                              new: true,
                                            });

                                            setadd_on(tmp);
                                          }}
                                        >
                                          + Add
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className={`modal  add-service`} role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>เพิ่มรายละเอียด / บริการเสริม</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <label>ชื่อ</label>
                  <input
                    type="text"
                    className="form-control"
                    value={add_item.name || ""}
                    onChange={(e) => {
                      let tmp = { ...add_item };
                      tmp.name = e.target.value;
                      setadd_item(tmp);
                    }}
                  />
                </div>

                <div className="col">
                  <div className="row ">
                    <div className="col-2 ml-auto">
                      <label>ขั้นต่ำ</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                        value={add_item.min || ""}
                        onChange={(e) => {
                          let tmp = { ...add_item };
                          tmp.min = parseInt(e.target.value);
                          if (parseInt(e.target.value) > tmp.max)
                            tmp.max = parseInt(e.target.value);

                          setadd_item(tmp);
                        }}
                      />
                    </div>

                    <div className="col-2 ml-auto">
                      <label>ไม่เกิน</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                        value={add_item.max || ""}
                        onChange={(e) => {
                          let tmp = { ...add_item };
                          tmp.max =
                            parseInt(e.target.value) > parseInt(tmp.min)
                              ? parseInt(e.target.value)
                              : parseInt(tmp.min);

                          setadd_item(tmp);
                        }}
                      />
                    </div>

                    <div className="col">
                      <div className="row mt-4">
                        <button
                          className="btn btn-primary mr-2 ml-auto"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={async () => {
                            let tmp = [...add_on];
                            let new_addon = await addAddOn(add_item);
                            new_addon.add_on = [];
                            tmp.push(new_addon);
                            setadd_on(tmp);
                            setadd_item({});
                          }}
                        >
                          เพิ่ม
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row mr-2 mb-4">
                <div className="col-12">
                  <div className="row">
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-outline-primary mr-2 ml-auto "
                    >
                      ยกเลิก
                    </button>
                    <button
                      data-dismiss="modal"
                      aria-label="Close"
                      className="btn btn-primary  mr-2 "
                      onClick={() => {
                        // addCredit(rider_id);
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
