import React, { useState, useEffect, useRef } from "react";
import { api, header_token, recommened_options } from "../../config";
import Axios from "axios";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import "moment/locale/th";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";

const condition_options = [
  { value: 3, label: "ใช้ได้...จำนวนครั้ง/วัน" },
  {
    value: 2,
    label: "ใช้ได้...ครั้งเท่านั้น/ท่าน",
  },
  { value: "0", label: "ไม่จำกัด" },
  { value: 1, label: "สั่งซื้อครั้งแรก" },
];
const type_options = [
  { value: "0", label: "ค่าอาหาร" },
  { value: 1, label: "ค่าส่ง" },
  // { value: 2, label: "ค่าบริการ" },
];

const discount_type_options = [
  { value: "0", label: "%" },
  { value: 1, label: "บาท" },
];

export const PromotionCodeIndex = () => {
  const { register, handleSubmit, errors, control, trigger, reset } = useForm();

  const checkAllRef = useRef(null);
  const close = useRef(null);
  const [loadingAll, setloadingAll] = useState(false);

  const [data, setdata] = useState([]);

  const [current_page, setcurrent_page] = useState(1);
  const [total_page, settotal_page] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [promotion_id, setpromotion_id] = useState("");
  const [type, settype] = useState("");
  const [discount_type, setdiscount_type] = useState("");
  const [condition, setcondition] = useState();
  const [isquantity, setisquantity] = useState(true);
  const [begin_date, setbegin_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [select_operation, setselect_operation] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState("");

  const getData = async () => {
    setloadingAll(true);

    try {
      let send_data = {
        name: filter_name,
        status: filter_status.value
          ? [parseInt(filter_status.value)]
          : [0, 1, 2, 3],
      };

      let { data } = await Axios.post(
        `${api}/admin/promotion-code/filter?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log(data);
      let page_count = Math.ceil(data.meta.total_count / limit);
      settotal_page(page_count);
      settotal_count(data.meta.total_count);

      setdata(data.promotions ? data.promotions : []);
    } catch (error) {
      console.log(error);
    }
    setloadingAll(false);
  };

  const onSubmit = async (data) => {
    // console.log('onSubmit',data);

    let begin = moment(begin_date, "DD/MM/YYYY").format("YYYY-MM-DD");
    let end = moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD");

    let send_data = {
      begin: `${begin} ${data.begin_time + ":00"}`,
      code: data.code.trim(),
      condition: parseInt(condition.value),
      condition_quantity: data.condition_quantity
        ? parseInt(data.condition_quantity)
        : 0,
      detail: data.detail.trim(),
      discount: parseInt(data.discount),
      discount_type: parseInt(discount_type.value),
      end: `${end} ${data.end_time + ":00"}`,
      max_discount: parseInt(data.max_discount),
      min_price: parseInt(data.min_price),
      name_en: "",
      name_th: data.name_th.trim(),
      quantity: parseInt(data.quantity),
      type: parseInt(type.value),
    };
    // console.log(send_data);
    editSelect(send_data);
  };

  const editSelect = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/promotion-code/${promotion_id}`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        swal("แก้ไขเสร็จสิ้น !!", {
          icon: "success",
        });
        close.current.click();
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const changeStatusSelect = async (send_data) => {
    try {
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/promotion-code/status`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
      return false;
    }
  };

  const deletePromotionCode = async (id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/promotion-code/${id}`,
        header_token
      );

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error.response);
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_status, filter_name]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h4 className="mb-4 mt-2">ทั้งหมด {total_count} รายการ</h4>
              </div>

              <div className="form-row align-items-center mb-2 mr-2 ">
                <div className=" mr-2" style={{ width: 200 }}>
                  <span className="tx-13 text-muted">ค้นหา</span>
                  <input
                    type="text"
                    className="form-control- global_filter"
                    id="global_filter"
                    placeholder="ชื่อโปรโมชั่นโค้ด"
                    value={filter_name}
                    onChange={(e) => setfilter_name(e.target.value)}
                  />
                </div>

                <div className="col">
                  <div className="row mr-2">
                    <div style={{ width: 150 }}>
                      <span>สถานะ</span>
                      <Select
                        value={filter_status}
                        onChange={async (e) => {
                          setfilter_status(e);
                        }}
                        options={recommened_options}
                        placeholder={"สถานะ..."}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="row  mr-2">
                    <div style={{ width: 150 }}>
                      <span>ดำเนินการ</span>
                      <Select
                        value={select_operation}
                        onChange={(e) => {
                          setselect_operation(e);
                        }}
                        options={[
                          { value: 1, label: "ใช้งาน" },
                          { value: 2, label: "ระงับการใช้งาน" },
                          { value: 3, label: "หมดอายุ" },
                          { value: "0", label: "ยกเลิก" },
                          { value: "delete", label: "ลบ" },
                        ]}
                        placeholder={"ดำเนินการ..."}
                      />
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="row">
                    <div style={{ maxWidth: 150 }}>
                      <button
                        className="btn btn-primary   mt-4 "
                        onClick={async () => {
                          let count = data.filter((e) => e.checked === true);
                          if (count.length <= 0) {
                            alert("เลือกตัวดำเนินการ");
                          } else if (select_operation.value === "delete") {
                            swal({
                              title: `ต้องการลบเลือกหรือไม่ ?`,
                              confirmButtonText: "ใช่",
                              cancelButtonText: "ไม่",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let sending = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    sending.push(deletePromotionCode(value.id));
                                  }
                                });

                                let is_success = true;
                                await Promise.all(sending).then((res) => {
                                  // console.log(res);
                                  if (
                                    res.filter((e) => e === true).length ===
                                      0 ||
                                    res.length === 0
                                  )
                                    is_success = false;
                                });

                                if (is_success) {
                                  getData();

                                  swal("เสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          } else if (select_operation.value) {
                            swal({
                              title: `ดำเนินการที่เลือกหรือไม่ ?`,
                              confirmButtonText: "ใช่",
                              cancelButtonText: "ไม่",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                let _id = [];
                                data.forEach((value) => {
                                  if (value.checked === true) {
                                    _id.push(value.id);
                                  }
                                });

                                let is_success = changeStatusSelect({
                                  id: _id,
                                  status: parseInt(select_operation.value),
                                });

                                if (is_success) {
                                  getData();

                                  swal("เสร็จสิ้น !!", {
                                    icon: "success",
                                  });
                                } else {
                                  swal("เกิดข้อผิดพลาด !!", {
                                    icon: "error",
                                  });
                                }
                              }
                            });
                          }
                        }}
                      >
                        ดำเนินการ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LoadingMask loading={loadingAll} text={"loading..."}>
              <div style={{ minHeight: 500 }}>
                <div className="table-responsive">
                  <table id="dataTable" className="table table-bordered-">
                    <thead>
                      <tr>
                        <th style={{ minWidth: 30 }}>
                          <input
                            type="checkbox"
                            className="selectAll "
                            name="selectAll"
                            defaultChecked={false}
                            ref={checkAllRef}
                            onClick={(e) => {
                              let tmp = [...data];
                              let _tmp = [];
                              tmp.forEach((value, index) => {
                                value.checked = checkAllRef.current.checked;
                                _tmp.push(value);
                              });

                              setdata(tmp);
                            }}
                          />
                        </th>
                        <th style={{ minWidth: 150 }}>ชื่อโปรโมชั่น</th>
                        <th style={{ minWidth: 50 }}>สำหรับ</th>
                        <th style={{ minWidth: 120 }}>รหัสโค้ด</th>
                        <th style={{ minWidth: 120 }}>ส่วนลด</th>
                        <th style={{ minWidth: 180 }}>สั่งซื้อขั้นต่ำ(บาท)</th>
                        <th style={{ minWidth: 180 }}>ลดสูงสุด(บาท)</th>
                        <th style={{ minWidth: 250 }}>เงื่อนไขการใช้</th>
                        <th style={{ minWidth: 100 }}>จำนวน</th>
                        <th style={{ minWidth: 100 }}>ใช้ไป</th>
                        <th style={{ minWidth: 100 }}>วันเริ่มต้น</th>
                        <th style={{ minWidth: 150 }}>วันสิ้นสุด</th>
                        <th style={{ minWidth: 100 }}>สถานะ</th>
                        <th style={{ minWidth: 100 }}>เพิ่มเติม</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ minWidth: 30 }}>
                              <input
                                type="checkbox"
                                checked={value.checked || ""}
                                onChange={(e) => {
                                  let tmp = [...data];
                                  data.forEach((vl, idx) => {
                                    if (vl.id === value.id) {
                                      tmp[idx].checked = !tmp[idx].checked;
                                      setdata(tmp);
                                    }
                                  });
                                }}
                              />
                            </td>
                            <td>{value.name_th}</td>
                            <td>
                              {type_options.filter(
                                (e) =>
                                  e.value ===
                                  (value.type === 0 ? "0" : value.type)
                              ).length > 0 &&
                                type_options.filter(
                                  (e) =>
                                    e.value ===
                                    (value.type === 0 ? "0" : value.type)
                                )[0].label}
                            </td>
                            <td>{value.code}</td>
                            <td>{value.discount}</td>
                            <td>{value.min_price}</td>
                            <td>{value.max_discount}</td>
                            <td>
                              {
                                condition_options.filter(
                                  (e) =>
                                    e.value ===
                                    (value.condition === 0
                                      ? "0"
                                      : value.condition)
                                )[0].label
                              }
                            </td>
                            <td>{value.quantity}</td>
                            <td>{value.usage}</td>
                            <td>{moment(value.begin).format("DD/MM/YYYY")}</td>
                            <td>{moment(value.end).format("DD/MM/YYYY")}</td>
                            <td>
                              {(() => {
                                switch (value.status) {
                                  case 0:
                                    return (
                                      <div className="badge badge-warning">
                                        ยกเลิก
                                      </div>
                                    );
                                  case 1:
                                    return (
                                      <div className="badge badge-success">
                                        ใช้งาน
                                      </div>
                                    );

                                  case 2:
                                    return (
                                      <div className="badge badge-secondary">
                                        ระงับ
                                      </div>
                                    );
                                  case 3:
                                    return (
                                      <div className="badge badge-danger">
                                        หมดอายุ
                                      </div>
                                    );

                                  default:
                                    break;
                                }
                              })()}
                            </td>
                            <td>
                              <button
                                data-toggle="modal"
                                data-target=".edit-row"
                                className="btn btn-outline-primary btn-sm mb-1"
                                onClick={() => {
                                  setpromotion_id(value.id);
                                  settype(
                                    type_options.filter(
                                      (e) =>
                                        e.value ===
                                        (value.type === 0 ? "0" : value.type)
                                    )[0]
                                  );
                                  setdiscount_type(
                                    discount_type_options.filter(
                                      (e) =>
                                        e.value ===
                                        (value.discount_type === 0
                                          ? "0"
                                          : value.discount_type)
                                    )[0]
                                  );
                                  setcondition(
                                    condition_options.filter(
                                      (e) =>
                                        e.value ===
                                        (value.condition === 0
                                          ? "0"
                                          : value.condition)
                                    )[0]
                                  );
                                  setisquantity(
                                    value.condition === 3 ||
                                      value.conditione === 2
                                      ? true
                                      : false
                                  );
                                  setbegin_date(new Date(value.begin));

                                  setend_date(new Date(value.end));

                                  reset({
                                    name_th: value.name_th,
                                    quantity: value.quantity,
                                    min_price: value.min_price,
                                    max_discount: value.max_discount,
                                    discount: value.discount,
                                    detail: value.detail,
                                    code: value.code,
                                    condition_quantity:
                                      value.condition_quantity,
                                    begin_time: moment(value.begin).format(
                                      "HH:mm"
                                    ),
                                    end_time: moment(value.end).format("HH:mm"),
                                    min_price: value.min_price,
                                  });
                                }}
                              >
                                แก้ไข
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mt-2" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={total_page}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </LoadingMask>
          </div>
        </div>
      </div>

      <div className="modal  edit-row" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>แก้ไขโปรโมชั่นโค้ด</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    ชื่อโปรโมชั่น
                  </label>
                  <div className="col-sm-5">
                    <input
                      name="name_th"
                      type="text"
                      className={`form-control ${
                        errors.name_th ? "error-input" : ""
                      }`}
                      ref={register({ required: true })}
                      placeholder="ชื่อ"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    ส่วนลดสำหรับ
                  </label>
                  <div className="col-sm-5">
                    <Select
                      name="type"
                      value={type}
                      onChange={async (e) => {
                        await settype(e);
                        trigger("type");
                      }}
                      options={[
                        { value: "0", label: "ค่าอาหาร" },
                        { value: 1, label: "ค่าส่ง" },
                      ]}
                      placeholder={"ส่วนลด..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.type ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.type ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      ref={(e) => {
                        register(
                          { name: "type" },
                          {
                            validate: () => {
                              return type.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    รหัสโค้ด
                  </label>
                  <div className="col-sm-5">
                    <input
                      name="code"
                      type="text"
                      className={`form-control ${
                        errors.code ? "error-input" : ""
                      }`}
                      ref={register({ required: true })}
                      placeholder="รหัส"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    ส่วนลด
                  </label>
                  <div className="col-3">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.discount ? "error-input" : ""
                      }`}
                      id="discount"
                      name="discount"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="col-2">
                    <Select
                      name="discount_type"
                      value={discount_type}
                      onChange={async (e) => {
                        await setdiscount_type(e);
                        trigger("discount_type");
                      }}
                      options={[
                        { value: "0", label: "%" },
                        { value: 1, label: "บาท" },
                      ]}
                      placeholder={"ชนิด..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.discount_type ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.discount_type
                              ? "red"
                              : "#cdd4e0",
                          },
                        }),
                      }}
                      ref={(e) => {
                        register(
                          { name: "discount_type" },
                          {
                            validate: () => {
                              return discount_type.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    สั่งซื้อขั้นต่ำ(บาท)
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.min_price ? "error-input" : ""
                      }`}
                      id="min_price"
                      name="min_price"
                      ref={register({ required: true })}
                      placeholder="ขั้นต่ำ"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    ลดสูงสุด(บาท)
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.max_discount ? "error-input" : ""
                      }`}
                      id="max_discount"
                      name="max_discount"
                      ref={register({ required: true })}
                      placeholder="สูงสุด"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    เงื่อนไขการใช้
                  </label>
                  <div className="col-sm-5">
                    <div className="row">
                      <div className="col-8">
                        <Select
                          name="condition"
                          value={condition}
                          onChange={async (e) => {
                            if (e.value === 3 || e.value === 2) {
                              setisquantity(true);
                            } else {
                              setisquantity(false);
                            }
                            await setcondition(e);
                            trigger("condition");
                          }}
                          options={[
                            { value: 3, label: "ใช้ได้...จำนวนครั้ง/วัน" },
                            { value: 2, label: "ใช้ได้...ครั้งเท่านั้น/ท่าน" },
                            { value: "0", label: "ไม่จำกัด" },
                            { value: 1, label: "สั่งซื้อครั้งแรก" },
                          ]}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: errors.condition ? "red" : "#cdd4e0",
                              "&:hover": {
                                borderColor: errors.condition
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }),
                          }}
                          ref={(e) => {
                            register(
                              { name: "condition" },
                              {
                                validate: () => {
                                  return condition.value ? true : false;
                                },
                              }
                            );
                          }}
                        />
                      </div>

                      <div className="col-4">
                        {isquantity ? (
                          <input
                            type="text"
                            className={`form-control ${
                              errors.condition_quantity ? "error-input" : ""
                            }`}
                            id="condition_quantity"
                            name="condition_quantity"
                            ref={register({
                              required: isquantity ? true : false,
                            })}
                            placeholder="ครั้ง"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-4">
                    <label htmlFor="address">วันเริ่มต้น</label>
                  </div>
                  <div className="col-sm-5">
                    <div className="row">
                      <div className="col-7">
                        <Controller
                          control={control}
                          name="begin_date"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={begin_date}
                              onDayChange={async (e) => {
                                await setbegin_date(e);
                                trigger("begin_date");

                                if (!end_date) {
                                  await setend_date(e);
                                  trigger("end_date");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 8,
                                  border: "1px solid #cdd4e0",
                                  borderRadius: 5,
                                  borderColor: errors.begin_date
                                    ? "red"
                                    : "#cdd4e0",
                                  height: "100%",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "begin_date" },
                                  {
                                    validate: (value) => {
                                      return begin_date ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className="form-control"
                          type="time"
                          min="00:00"
                          max="60:00"
                          className={`form-control ${
                            errors.begin_time ? "error-input" : ""
                          }`}
                          id="begin_time"
                          name="begin_time"
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-4">
                    <label htmlFor="address">วันสิ้นสุด</label>
                  </div>
                  <div className="col-sm-5">
                    <div className="row">
                      <div className="col-7">
                        <Controller
                          control={control}
                          name="end_date"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={end_date}
                              onDayChange={async (e) => {
                                await setend_date(e);
                                trigger("end_date");

                                if (!end_date) {
                                  await setbegin_date(e);
                                  trigger("begin_date");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 8,
                                  border: "1px solid #cdd4e0",
                                  borderRadius: 5,
                                  borderColor: errors.end_date
                                    ? "red"
                                    : "#cdd4e0",
                                  height: "100%",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "end_date" },
                                  {
                                    validate: (value) => {
                                      return end_date ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-5">
                        <input
                          className="form-control"
                          type="time"
                          min="00:00"
                          max="60:00"
                          className={`form-control ${
                            errors.end_time ? "error-input" : ""
                          }`}
                          id="end_time"
                          name="end_time"
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    จำนวน
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.quantity ? "error-input" : ""
                      }`}
                      id="quantity"
                      name="quantity"
                      ref={register({ required: true })}
                      placeholder="จำนวน"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-4 col-form-label "
                  >
                    หมายเหตุ
                  </label>
                  <div className="col-sm-5">
                    <textarea
                      className={`form-control ${
                        errors.detail ? "error-input" : ""
                      }`}
                      name="detail"
                      style={{ height: 100 }}
                      ref={register({ required: true, maxLength: 400 })}
                    ></textarea>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="row">
                      <button
                        className="btn btn-outline-primary mr-2 ml-auto"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={close}
                      >
                        ยกเลิก
                      </button>
                      <button className="btn btn-primary btn-sm mr-3  ">
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
