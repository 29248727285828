import React, { useState } from "react";
import { RestaurantDetailMenuList } from "./RestaurantDetailMenuList";
import { RestaurantDetailMenuAddonGroup } from "./RestaurantDetailMenuAddonGroup";
import { RestaurantDetailMenuListAddon } from "./RestaurantDetailMenuListAddon";

export const RestaurantDetailMenu = (props) => {
  const [menu, setMenu] = useState(1);

  return (
    <div className="card-body">
      <div className="row">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li
            className="nav-item"
            onClick={() => {
              setMenu(1);
            }}
          >
            <a className={"nav-link " + (menu === 1 ? "active" : "")}>
              รายการอาหาร
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setMenu(2);
            }}
          >
            <a className={"nav-link " + (menu === 2 ? "active" : "")}>
              หมวดหมู่ Add On
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setMenu(3);
            }}
          >
            <a className={"nav-link " + (menu === 3 ? "active" : "")}>
              รายการ Add On
            </a>
          </li>
        </ul>
        {
          !props?.restaurant?.is_sub_brand ?
            <>
              {menu === 1 ? (
                <button
                  onClick={() => {
                    props.pushToLink(
                      `/restaurant/detail/${props.restaurantId}/menu-add`
                    );
                  }}
                  className="btn btn-primary  mr-2 ml-auto "
                  style={{ height: 42 }}
                >
                  + เพิ่มรายการอาหาร
                </button>
              ) : (
                ""
              )}

              {menu === 3 ? (
                <button
                  data-toggle="modal"
                  data-target=".create-list-addon"
                  className="btn btn-primary  mr-2 ml-auto "
                  style={{ height: 42 }}
                >
                  + เพิ่มรายการ Add On
                </button>
              ) : (
                ""
              )}
            </>
            : <></>
        }
      </div>
      {menu === 1 ? (
        <RestaurantDetailMenuList
          restaurant={props.restaurant}
          restaurantId={props.restaurantId}
          pushToLink={props.pushToLink}
        />
      ) : (
        ""
      )}
      {menu === 2 ? (
        <RestaurantDetailMenuAddonGroup
          restaurant={props.restaurant}
          restaurantId={props.restaurantId} />
      ) : (
        ""
      )}
      {menu === 3 ? (
        <RestaurantDetailMenuListAddon
          restaurant={props.restaurant}
          restaurantId={props.restaurantId} />
      ) : (
        ""
      )}
    </div>
  );
};
