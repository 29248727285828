import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import Axios from "axios";
import { api, img_url, wallet_option, header_token } from "../../config";
import swal from "sweetalert";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";
import { Link } from "react-router-dom";
import { CreditHead } from "./CreditHead";
import { ExportCreditData } from "./ExportCreditData";

const deposit_type = [
  { value: -1, label: "เลือก.." },
  { value: 1, label: "พนักงานส่ง" },
  { value: "0", label: "ร้านอาหาร" },
];

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const CreditHistory = () => {
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);

  const [img, setimg] = useState("");
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_from, setfilter_from] = useState("");
  const [filter_to, setfilter_to] = useState("");
  const [filter_name, setfilter_name] = useState("");
  const [filter_status, setfilter_status] = useState({
    value: "",
    label: "เลือก..",
  });
  const [filter_type, setfilter_type] = useState({
    value: "",
    label: "เลือก..",
  });

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const exportexcel = async () => {
    setexport_loading(true);

    try {
      let start = "";
      let end = "";
      if (filter_from || filter_to) {
        start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");
      }

      let send_data = {
        name: filter_name,
        status: filter_status.value === "" ? -1 : filter_status.value,
        type: 1,
        start_date: start,
        end_date: end,
        is_user: parseInt(filter_type.value),
      };

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${1}&limit=${99999999999}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let _export = [];
      if (data.wallets) {
        data.wallets.forEach((value, index) => {
          let st;
          switch (value.status) {
            case 0:
              st = "ยกเลิก";
              break;
            case 1:
              st = "อนุมัติ";
              break;
            case 2:
              st = "รอตรวจสอบ";
              break;
            case 3:
              st = "รอดำเนินการ";
              break;

            default:
              break;
          }

          _export.push({
            user_id: value.user
              ? value.user.user_id
              : value.restaurant.restaurant_id,
            name: value.user ? value.user.full_name : value.restaurant.name_th,
            area: value.user
              ? value.user.area
                ? value.user.area.name_th
                : ""
              : value.restaurant.area
              ? value.restaurant.area.name_th
              : "",
            amount: value.amount,
            credit: value.user ? value.user.credit : value.restaurant.credit,
            bank: value.bank_name_th,
            type: value.user_id
              ? "พนักงาน"
              : value.restaurant_id
              ? "ร้านค้า"
              : "",
            created_at: `${moment(value.created_at).format(
              "DD/MM/YYYY"
            )} ${moment(value.created_at).format("LT")}`,
            status: st,
          });
        });
      }
      console.log(_export);
      setexport_data(_export);
    } catch (error) {
      console.log(error.response);
    }

    setexport_loading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let start = "";
      let end = "";
      if (filter_from || filter_to) {
        start = moment(filter_from, "DD/MM/YYYY").format("YYYY-MM-DD");
        end = moment(filter_to, "DD/MM/YYYY").format("YYYY-MM-DD");
      }

      let send_data = {
        name: filter_name,
        status: filter_status.value === "" ? -1 : filter_status.value,
        type: 1,
        start_date: start,
        end_date: end,
        is_user: parseInt(filter_type.value),
      };

      let { data } = await Axios.post(
        `${api}/admin/wallet/history?page=${current_page}&limit=${limit}`,
        send_data,
        header_token
      );
      // console.log("data", data);

      let cal_page_count = Math.ceil(data.meta.total_count / limit);
      setpage_count(cal_page_count);
      settotal_count(data.meta.total_count);

      setdata(data.wallets);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_name, filter_status, filter_type, filter_from, filter_to]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <CreditHead />

      <div className="row">
        <div className="ml-auto mr-2 mb-2">
          <p className="tx-13 text-muted mb-2">ค้นหาจากช่วงเวลา</p>
          <div className="InputFromTo">
            <DayPickerInput
              value={filter_from}
              onDayChange={(e) => {
                setfilter_from(e);
                if (!filter_to) {
                  setfilter_to(e);
                }
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
            />{" "}
            ถึง{" "}
            <span className="InputFromTo-to">
              <DayPickerInput
                value={filter_to}
                onDayChange={(e) => {
                  setfilter_to(e);
                  if (!filter_from) {
                    setfilter_from(e);
                  }
                }}
                overlayComponent={CustomOverlay}
                placeholder={"วัน/เดือน/ปี"}
                format="L"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  locale: "th",
                  localeUtils: MomentLocaleUtils,
                }}
              />
            </span>
            <button
              type="button"
              className="btn btn-primary mr-2 ml-2"
              style={{ marginTop: -2, padding: `5px 10px 5px 10px` }}
              onClick={async () => {
                if (!trigger && !export_loading) {
                  await exportexcel();
                  await settrigger(true);
                }
              }}
            >
              {export_loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              {` `}Export
            </button>
            <ExportCreditData
              data={export_data}
              trigger={trigger}
              settrigger={settrigger}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <span className="h5 ml-3 mt-3">
                  ทั้งหมด {total_count} รายการ
                </span>

                <div className="form-row align-items-center mb-2 ml-auto">
                  <div className="col mr-2">
                    <span className="text-muted tx-13">ค้นหา</span>
                    <div style={{ width: 200 }}>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="ชื่อ"
                        value={filter_name}
                        onChange={(e) => {
                          setfilter_name(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col mr-3">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="text-muted tx-13">ประเภท</span>
                        <Select
                          value={filter_type}
                          onChange={(e) => {
                            setfilter_type(e);
                          }}
                          options={deposit_type}
                          placeholder={"ประเภท.."}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col mr-3">
                    <div className="row">
                      <div style={{ width: 150 }}>
                        <span className="text-muted tx-13">สถานะ</span>
                        <Select
                          value={filter_status}
                          onChange={(e) => {
                            setfilter_status(e);
                          }}
                          options={wallet_option}
                          placeholder={"สถานะ.."}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="table-responsive">
                    <table
                      id="dataTable"
                      className="table "
                      style={{ width: "100%" }}
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ minWidth: 50 }}>รหัส</th>
                          <th style={{ minWidth: 250 }}>ชื่อ</th>
                          <th style={{ minWidth: 250 }}>พื้นที่</th>
                          <th style={{ minWidth: 120 }}>จำนวนเงิน</th>
                          <th style={{ minWidth: 120 }}>ไฟล์แนบ</th>
                          <th style={{ minWidth: 150 }}>เครดิตคงเหลือ</th>
                          <th style={{ minWidth: 120 }}>ประเภท</th>
                          <th style={{ minWidth: 200 }}>วันที่</th>
                          <th style={{ minWidth: 200 }}>สถานะ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: value.checked ? "#e8eaed" : "",
                              }}
                            >
                              <td>
                                {value.user
                                  ? value.user.user_id
                                  : value.restaurant.restaurant_id}
                              </td>

                              <td>
                                <Link
                                  to={
                                    value.user
                                      ? `/rider/credit/${value.user.id}`
                                      : `/restaurant/detail/${value.restaurant.id}/credit`
                                  }
                                >
                                  {value.user
                                    ? value.user.full_name
                                    : value.restaurant.name_th}
                                </Link>
                              </td>
                              <td>
                                {value.user
                                  ? value.user.area
                                    ? value.user.area.name_th
                                    : ""
                                  : value.restaurant.area
                                  ? value.restaurant.area.name_th
                                  : ""}
                              </td>
                              <td className="text-success">
                                ฿{value.amount.toLocaleString()}
                              </td>
                              <td className="dt-edit">
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setimg(
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.picture === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.picture}`
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {value.user
                                  ? `฿${value.user.credit.toLocaleString()}`
                                  : `฿${value.restaurant.credit.toLocaleString()}`}
                              </td>
                              <td>
                                {value.user_id ? "พนักงาน" : ""}
                                {value.restaurant_id ? "ร้านค้า" : ""}
                              </td>
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}{" "}
                                {moment(value.created_at).format("LT")}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "อนุมัติ";
                                    case 2:
                                      return "รอตรวจสอบ";
                                    case 3:
                                      return "รอดำเนินการ";
                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-2" style={{ float: "right" }}>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={<span className="gap">...</span>}
                    pageCount={page_count}
                    onPageChange={(e) => setcurrent_page(e.selected + 1)}
                    forcePage={current_page - 1}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previous_page"}
                    nextLinkClassName={"next_page"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                  />
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>
      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
