import React, { useEffect, useState } from "react";
import { api } from "../../config";
import swal from "sweetalert";
import Axios from "axios";
import { NavLink, Route, Switch, useParams } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import { BrandHeader } from "./BrandHeader";
import { DetailBrandHistory } from "./DetailBrandHistory";
import { MenuBrand } from "./MenuBrand";
import { BrandMenuGroup } from "./BrandMenuGroup";

export const DetailBrand = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { id } = useParams();

  const getBrandById = async () => {
    try {
      setLoading(true);
      let { data } = await Axios.get(`${api}/brand/${id}`);
      setData(data)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
        button: false,
      });
    }
  };

  useEffect(() => {
    getBrandById();
  }, [])

  return (
    <div>
      <BrandHeader />
      <div className="row">
        <div className="col-12 mb-3">
          <h3 className="mb-4 mt-2">{data?.name ?? ""}</h3>
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-tabs nav-fill card-header-tabs">
                <li className="nav-item">
                  <NavLink
                    exact
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/brands/detail/${id}`}
                  >
                    รายละเอียด
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/brands/detail/${id}/menu`}
                  >
                    เมนู
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/brands/detail/${id}/catergory`}
                  >
                    หมวดหมู่
                  </NavLink>
                </li>
              </ul>
            </div>
            <LoadingMask loading={loading} text={"loading..."}>
              <Switch>
                <Route exact path={`/brands/detail/${id}`}>
                  <DetailBrandHistory brand={data} />
                </Route>
                <Route path={`/brands/detail/${id}/menu`}>
                  {
                    loading ? <></> : <MenuBrand brand={data} />
                  }
                </Route>
                <Route path={`/brands/detail/${id}/catergory`}>
                  {
                    loading ? <></> : <BrandMenuGroup brand={data} />
                  }
                </Route>
              </Switch>
            </LoadingMask>
          </div>
        </div>
      </div>

    </div>

  )
}