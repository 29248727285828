import React, { useState, useEffect } from "react";
import Select from "react-select";

import { api, img_url, branch_status_option, header_token } from "../../config";
import Axios from "axios";
import swal from "sweetalert";
import moment from "moment";

export const BranchDetailHistory = (props) => {
  const [status, setstatus] = useState({});

  const [data, setdata] = useState({
    credit: 0,
    user_rating: { rating: 0, votes: 0 },
    location: {
      latitude: 16.409936252252667,
      longitude: 103.06710188750003,
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      province: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
        short_name_en: "",
        short_name_th: "",
      },
      district: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
      },
      sub_district: {
        id: "",
        zip_code: "",
        name_en: "",
        name_th: "",
      },
    },

    type: "",
  });
  const [summary, setsummary] = useState(0);

  const getData = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/admin/branch/${props.branchId}`,
        header_token
      );
      props.callbackName(data.name_th);

      // console.log(data);
      setdata(data);

      branch_status_option.forEach((value) => {
        if (parseInt(value.value) === parseInt(data.status)) {
          setstatus(value);
        }
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getSummary = async () => {
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/${props.branchId}/summary/filter`,
        {
          start_date: "2019/01/01",
          end_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD"),
        },
        header_token
      );

      // console.log(data);
      setsummary(data.total_amount);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const setBranchStatus = async () => {
    try {
      let send_data = {
        branch_id: [props.branchId],
        status: status.value,
      };
      // console.log(send_data);
      let res = await Axios.put(
        `${api}/admin/branch/update-status`,
        send_data,
        header_token
      );

      console.log(res);
      if (res.status === 200) {
        swal("อัพเดตเสร็จสิ้น !!", {
          icon: "success",
        });
      }
      // console.log(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };
  useEffect(() => {
    getData();
    getSummary();
  }, []);

  return (
    <div className="card-body">
      <div className="row border-bottom pd-3 pt-4">
        {/* <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">ยอดขายทั้งหมด</h5>
          <span className="h1 mr-2 ">฿{summary.toLocaleString()}</span>
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">ร้านอาหาร</h5>
          <span className="h1 mr-2 ">฿{data.credit.toLocaleString()}</span>
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">Rider</h5>
          <span className="h1 mr-2 ">{data.menu_count}</span>
          <span>รายการ</span>
        </div>
        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">พื้นที่บริการ</h5>
          <span className="h1 mr-2 ">{data.user_rating.rating}</span>
          <span>({data.user_rating.votes} รีวิว)</span>
        </div> */}

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">สถานะ</h5>
          <Select
            options={branch_status_option}
            placeholder={"สถานะ"}
            value={status}
            // readOnly
            onChange={(e) => {
              setstatus(e);
              // console.log(status);
            }}
          />
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted"> &nbsp;</h5>
          <a
            tabIndex="0"
            className="btn btn-primary  mr-2 "
            onClick={() => {
              setBranchStatus();
            }}
          >
            บันทึก
          </a>
        </div>
        <div className="col-12 text-white pt-4"></div>
      </div>

      <div className="row mt-4 mb-4">
        <div className="col-4">
          {data.full_name}
          <br />
          {data.location.address}
          {", "}
          {`ตำบล${data.location.sub_district.name_th}`}
          {", "}
          {`อำเภอ${data.location.district.name_th}`}
          {", "}
          {`จังหวัด${data.location.province.name_th}`}
          {", "}
          {`${data.location.sub_district.zip_code}`}
          <br />
          <br />
          เบอร์โทร {data.phone_number}
          <br />
          อีเมล {data.email}
          <br />
          <button
            onClick={() => props.pushToLink(`/branch/edit/${props.branchId}`)}
            // href={`/restaurant/edit/${props.branchId}`}
            className="btn btn-outline-primary mr-2 ml-auto  "
          >
            แก้ไข
          </button>
        </div>

        <div className="col-4">{/* <MyMapComponent /> */}</div>

        <div
          className="col-4 img-thumbmail"
          style={{ height: 400, overflow: "scroll" }}
        >
          <img
            className="lazy img-responsive"
            src={
              data.picture === ""
                ? "/images/no-img.jpg"
                : `${img_url}${data.picture}`
            }
          />
        </div>
      </div>
    </div>
  );
};
