import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import { api, header_token } from "../../config";

export const RestaurantDetailMenuGroup = (props) => {
  const [loading, setloading] = useState(false);

  const [group_addon, setgroup_addon] = useState([
    {
      id: "",
      title: "",
      name_en: "",
      options: [],
      edit: false,
      new: false,
    },
  ]);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/restaurants/${props.restaurantId}/menu-group`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          id: value.id,
          title: value.title,
          edit: false,
          new: false,
        });
      });
      setgroup_addon(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const addMenuGroup = async (send_data) => {
    try {
      let res = await Axios.post(
        `${api}/restaurants/${props.restaurantId}/menu-group`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const deleteMenuGroup = async (restaurantId, addon_id) => {
    try {
      let res = await Axios.delete(
        `${api}/restaurants/${restaurantId}/menu-group/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(" Failed", "Please, Try again", "error");
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const editMenuGroup = async (menu_group_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/restaurants/${props.restaurantId}/menu-group/${menu_group_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="card-body">
      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="example" className="table tebleSize">
            <thead>
              <tr>
                <th>หมวดหมู่</th>
                {
                  !props.restaurant?.is_sub_brand ? (
                    <>
                      <th width="80">Edit</th>
                      <th width="80">Delete</th>
                    </>
                  ) : <></>
                }
              </tr>
            </thead>
            <tbody>
              {group_addon.map((value, index) => {
                return (
                  <tr key={value.id}>
                    <td className="name">
                      {value.edit === true ? (
                        <input
                          type="text"
                          className="form-control"
                          value={value.title}
                          onChange={(e) => {
                            let tmp = [...group_addon];
                            tmp[index].title = e.target.value;
                            setgroup_addon(tmp);
                          }}
                        />
                      ) : (
                        value.title
                      )}
                    </td>
                    {
                      !props.restaurant?.is_sub_brand ? (
                        <>
                          <td className="edit">
                            {value.edit === false ? (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                onClick={() => {
                                  let tmp = [...group_addon];
                                  tmp[index].edit = !tmp[index].edit;
                                  setgroup_addon(tmp);
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                onClick={() => {
                                  let tmp = [...group_addon];
                                  if (group_addon[index].title) {
                                    if (tmp[index].new === true) {
                                      addMenuGroup({
                                        title: tmp[index].title.trim(),
                                      });
                                    } else {
                                      editMenuGroup(value.id, {
                                        title: value.title.trim(),
                                      });
                                    }
                                    tmp[index].edit = !tmp[index].edit;
                                    tmp[index].new = false;

                                    setgroup_addon(tmp);
                                  }
                                }}
                              ></i>
                            )}
                          </td>
                          <td className="trash">
                            <i
                              style={{ cursor: "pointer" }}
                              className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                              onClick={() => {
                                swal({
                                  title: `ต้องการลบ  ${value.title.trim()} ?`,
                                  // text:
                                  //   "Once deleted, you will not be able to recover this imaginary file!",
                                  confirmButtonText: "ลบ",
                                  cancelButtonText: "ยกเลิก",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    let is_delete = await deleteMenuGroup(
                                      props.restaurantId,
                                      value.id
                                    );
                                    // console.log("is_delete", is_delete);
                                    // console.log("value.id", value.id);
                                    if (willDelete && is_delete) {
                                      swal("ลบเสร็จสิ้น !!", {
                                        icon: "success",
                                      });
                                    } else {
                                      swal("เกิดข้อผิดพลาด !!", {
                                        icon: "error",
                                      });
                                    }
                                  }
                                });
                              }}
                            ></i>
                          </td>
                        </>
                      ) : <></>
                    }
                  </tr>
                );
              })}
              {
                !props.restaurant?.is_sub_brand ? (
                  <tr>
                    <td
                      className="new-row"
                      colSpan="5"
                      onClick={() => {
                        let tmp = [...group_addon];
                        // if (group_addon[group_addon.length - 1].new !== true) {
                        tmp.push({
                          id: "",
                          title: "",
                          name_en: "",
                          options: [],
                          edit: true,
                          new: true,
                        });
                        setgroup_addon(tmp);
                        // }
                      }}
                    >
                      <span className="h3">+</span> <span className="h6">Add</span>{" "}
                    </td>
                  </tr>) : <></>
              }
            </tbody>
          </table>
        </div>
      </LoadingMask>
    </div>
  );
};
