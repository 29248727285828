import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import $ from "jquery";
import Axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";

import moment from "moment";
import { api, img_url, restaurant_status_option } from "../../config";
import { RULE } from "../Setting/SettingAdmin";

export const RestaurantEditProfile = forwardRef((props, ref) => {
  const { register, handleSubmit, errors } = useForm();

  const [image, setImage] = useState({ preview: "", raw: "", base64: "" });
  const [restaurant_id, setrestaurant_id] = useState("");
  const [name_th, setname_th] = useState("");
  const [phone_numbers, setphone_numbers] = useState("");
  const [contact_name, setcontact_name] = useState("");
  const [personal_number, setpersonal_number] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [sub_district, setsub_district] = useState("");
  const [district, setdistrict] = useState("");
  const [province, setprovince] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [area, setarea] = useState("");
  const [categories, setcategories] = useState([{ value: "", label: "" }]);
  const [register_date, setregister_date] = useState(new Date());
  const [credit, setcredit] = useState(0);
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [filename, setfilename] = useState("");

  const [option_area, setoption_area] = useState([]);
  const [option_categories, setoption_categories] = useState([]);
  const [option_sub_district, setoption_sub_district] = useState([]);
  const [option_district, setoption_district] = useState([]);
  const [option_province, setoption_province] = useState([]);
  const [option_brands, setoption_brands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isBrand, setIsBrand] = useState(false);
  const [brand, setBrand] = useState(null);

  useImperativeHandle(ref, () => ({
    sendToParent() {
      sentBack();
    },
  }));

  const getBrands = async () => {
    try {
      let { data } = await Axios.get(`${api}/brand`);
      // console.log(data);
      let tmp = [{ value: null, label: "เลือกแบรนด์..." }];
      if (!!data) {
        data.forEach((value) => {
          tmp.push({ value: value.id, label: value.name });
        });
      }
      setBrands(data ?? [])
      setoption_brands(tmp);

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionCategories = async () => {
    try {
      let { data } = await Axios.get(`${api}/categories`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.title });
      });
      setoption_categories(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionSubDistrict = async (district_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          value: value.id,
          label: value.name_th,
          zip_code: value.zip_code,
        });
      });
      setoption_sub_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${province_id}`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const sentBack = () => {
    let momentObj = moment(register_date);
    var momentString = momentObj.format("YYYY-MM-DD");
    // console.log(sub_district)
    let category = [];
    categories.map((value) => {
      category.push(value.value);
      return;
    });
    let send_data = {
      name_th: name_th,
      image: image.base64 === "" ? image.preview : image.base64,
      phone_numbers: phone_numbers,
      contact_name: contact_name,
      personal_number: personal_number,
      email: email,
      address: address,
      sub_district_id: sub_district.value,
      district_id: district.value,
      province_id: province.value,
      area_id: area.value,
      categories_id: category,
      register_date: momentString,
      credit: credit,
      status: status.value,
    };

    props.callbackProfile(send_data);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const receiveProps = async (parentData) => {
    // console.log(parentData);
    setImage({
      preview: parentData.picture,
      raw: "",
      base64: "",
      new: false,
    });
    setname_th(parentData.name_th);
    setrestaurant_id(parentData.restaurant_id);
    setphone_numbers(parentData.phone_numbers);
    setcontact_name(parentData.full_name);
    setpersonal_number(parentData.personal_number);
    setemail(parentData.email);
    setaddress(parentData.location.address);

    setprovince({
      value: parentData.location.province.id,
      label: parentData.location.province.name_th,
    });

    setdistrict({
      value: parentData.location.district.id,
      label: parentData.location.district.name_th,
    });

    setsub_district({
      value: parentData.location.sub_district.id,
      label: parentData.location.sub_district.name_th,
    });

    setzip_code(parentData.location.sub_district.zip_code);
    setarea({ value: parentData.area_id, label: parentData.area.name_th });
    let categ = [];
    parentData.categories.map((v) => {
      categ.push({ value: v.id, label: v.title });
      return;
    });
    setcategories(categ);

    var date = new Date(parentData.register_date);

    setregister_date(date);
    setcredit(parentData.credit);
    restaurant_status_option.map((v) => {
      if (parseInt(v.value) === parentData.status) {
        setstatus({ value: parentData.status, label: v.label });
      }
      return;
    });
  };

  const onSubmit = (data) => {
    // console.log(data);
    sentBack();

    props.submit(props.data);
  };

  // console.log(errors);

  useEffect(() => {
    // console.log(props);
    getOptionArea();
    getOptionCategories();
    getOptionProvince();
  }, []);

  useEffect(() => {
    // console.log(props);
    async function loadAddress() {
      await getOptionDistrict(props.parentData.location.province_id);
      await getOptionSubDistrict(props.parentData.location.district_id);
    }

    if (Object.keys(props.parentData).length > 0) {
      loadAddress();
      receiveProps(props.parentData);
    }
  }, [props.parentData]);

  useEffect(() => {
    if (brands.length > 0 && Object.keys(props?.parentData).length > 0) {
      setBrand({ value: props?.parentData?.brand_id, label: brands?.find(e => e.id === props?.parentData?.brand_id)?.name });
      setIsBrand(props?.parentData?.brand_id !== null);
    }
  }, [brands, props?.parentData])

  // console.log(props);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="name">รูปร้าน</label>
          </div>
          <div className="col-lg-5">
            <img
              alt=""
              id="img-upload"
              src={
                image.base64 === ""
                  ? image.preview === ""
                    ? ""
                    : img_url + image.preview
                  : image.preview
              }
              style={{ maxWidth: "100%", maxHeight: 500 }}
            />
            <div className="row">
              <div className="col-10">
                <div className="input-group">
                  <input
                    type="file"
                    id="imgInp"
                    className="form-control"
                    value={filename}
                    accept=".png, .jpg, .jpeg"
                    onChange={async (e) => {
                      // console.log(e.target.files[0]);
                      setfilename(e.target.value);
                      if (e.target.files.length) {
                        setImage({
                          preview: URL.createObjectURL(e.target.files[0]),
                          raw: e.target.files[0],
                          base64: await toBase64(e.target.files[0]),
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-light btn-sm mt-1"
                  onClick={() => {
                    // console.log(e.target.files[0]);
                    setfilename("");
                    setImage({
                      preview: "",
                      raw: "",
                      base64: "",
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="restaurant_id">รหัสร้าน</label>
          </div>
          <div className="col-lg-5">
            <input
              type="text"
              readOnly
              value={restaurant_id}
              className="form-control"
              id="restaurant_id"
              placeholder=""
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="name_th">
              ชื่อร้าน<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="name_th"
              className={`form-control ${errors.name_th ? "error-input" : ""}`}
              name="name_th"
              type="text"
              ref={register({ required: true })}
              value={name_th}
              onChange={(e) => {
                setname_th(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="phone_numbers">
              เบอร์โทรศัพท์ที่ร้าน<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="phone_numbers"
              className={`form-control ${errors.phone_numbers ? "error-input" : ""
                }`}
              name="phone_numbers"
              type="text"
              ref={register({ required: true })}
              value={phone_numbers}
              onChange={(e) => setphone_numbers(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="contactname">ชื่อผู้ติดต่อ</label>
          </div>
          <div className="col-lg-5">
            <input
              id="contactname"
              className="form-control"
              name="contactname"
              type="text"
              value={contact_name}
              onChange={(e) => setcontact_name(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="personal_number">
              เบอร์โทรศัพท์ผู้ติดต่อ<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="personal_number"
              className={`form-control ${errors.personal_number ? "error-input" : ""
                }`}
              name="personal_number"
              type="text"
              ref={register({ required: true })}
              value={personal_number}
              onChange={(e) => setpersonal_number(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="email">
              อีเมล์<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="email"
              className={`form-control ${errors.email ? "error-input" : ""}`}
              name="email"
              type="text"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">ที่อยู่</label>
          </div>
          <div className="col-lg-5">
            <input
              id="address"
              className="form-control"
              name="address"
              type="text"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">จังหวัด</label>
          </div>
          <div className="col-lg-5">
            <Select
              value={province || ""}
              onChange={(e) => {
                setprovince(e);
                setdistrict({});
                setsub_district({});

                // console.log(e)
                getOptionDistrict(e.value);
              }}
              options={option_province}
              placeholder={"เลือกจังหวัด.."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="province">อำเภอ / เขต</label>
          </div>
          <div className="col-lg-5">
            <Select
              value={district || ""}
              onChange={(e) => {
                setdistrict(e);
                setsub_district({});

                getOptionSubDistrict(e.value);
              }}
              options={option_district}
              placeholder={"เลือกอำเภอ..."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="district">ตำบล / แขวง</label>
          </div>
          <div className="col-lg-5">
            <Select
              name="district"
              value={sub_district || ""}
              onChange={(e) => {
                // console.log(e);
                setsub_district(e);
                setzip_code(e.zip_code);
              }}
              options={option_sub_district}
              placeholder={"เลือกตำบล/แขวง..."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="zip">รหัสไปรษณีย์</label>
          </div>
          <div className="col-lg-5">
            <input
              id="zip"
              className="form-control"
              name="zip"
              type="text"
              value={zip_code}
              onChange={(e) => setzip_code(e.target.value)}
              readOnly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">
              พื้นที่<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <Select
              value={area || ""}
              onChange={(e) => {
                // console.log(e);
                setarea(e);
              }}
              options={option_area}
              placeholder={"เลือกพื้นที่..."}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: errors.area ? "red" : "#cdd4e0",

                  "&:focus": {
                    borderColor: errors.area ? "red" : "#cdd4e0",
                  },
                }),
              }}
              ref={(e) => {
                register(
                  { name: "area" },
                  {
                    validate: (value) => {
                      return area.value ? true : false;
                    },
                  }
                );
              }}
            />
          </div>
        </div>

        {
          RULE.hasOwnProperty('brand') && (
            <div className="form-group row">
              <div className="col-lg-3">
                <label htmlFor="address">แบรนด์</label>
              </div>

              <div className="col-lg-5">
                <Select
                  isDisabled={true}
                  value={brand}
                  onChange={(e) => {
                    setBrand(e);
                    setIsBrand(e.value !== null)
                    setcategories(brands?.find(v => v.id === e.value)?.categories?.map(v => ({ value: v.id, label: v.title })) ?? [])
                  }}
                  options={option_brands}
                  placeholder={"แบรนด์..."}
                />
              </div>
            </div>
          )
        }

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">ประเภทอาหาร</label>
          </div>

          <div className="col-lg-5">
            <Select
              value={categories || ""}
              isDisabled={isBrand}
              onChange={(e) => {
                // console.log(e);
                setcategories(e);
              }}
              options={option_categories}
              placeholder={"เลือกประเภทอาหาร..."}
              closeMenuOnSelect={false}
              isMulti
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">วันที่สมัคร</label>
          </div>
          <div className="col-lg-5">
            <DayPickerInput
              value={register_date}
              onDayChange={(e) => {
                setregister_date(e);
              }}
              placeholder={"วัน/เดือน/ปี"}
              style={{ width: "100%" }}
              inputProps={{ style: { width: "100%", padding: 5 } }}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
              readonly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="credit">เครดิต(บาท)</label>
          </div>
          <div className="col-lg-5">
            <input
              id="credit"
              className="form-control"
              name="credit"
              type="number"
              value={credit}
              // onChange={(e) => setcredit(parseInt(e.target.value))}
              disabled
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="status">สถานะ</label>
          </div>
          <div className="col-lg-5">
            <Select
              options={restaurant_status_option}
              placeholder={"สถานะ"}
              value={status || ""}
              onChange={(e) => setstatus(e)}
            />
          </div>
        </div>

        {/* <div className="row">
        <div className="col-10"></div>
        <div className="col">
          <div className="row">
            <a
              href="#"
              className="btn btn-primary  mr-2  mt-2 "
              onClick={() => {
                console.log(props);
                sentBack();
                props.nextRef.current.click();
              }}
            >
              ถัดไป
            </a>
          </div>
        </div>
      </div> */}

        <div className="row">
          <div className="col-10"></div>
          <div className="col">
            <div className="row">
              <button
                type="submit"
                className="btn btn-primary  mr-2  mt-2 "
              // onClick={() => {}}
              >
                บันทึกข้อมูล
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});
