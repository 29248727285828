import React, { useContext } from "react";
import { useRouteMatch, NavLink } from "react-router-dom";
import { NotiContext } from "../../store/keep";

export const UserServiceHead = () => {
  let match = useRouteMatch();
  const { noti } = useContext(NotiContext);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">พนักงานทั้งหมด</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {(() => {
                  if (match.url.includes("add")) {
                    return "เพิ่มพนักงานบริการ";
                  } else if (match.url.includes("approve")) {
                    return "สมัครใหม่";
                  } else {
                    return "พนักงานบริการทั้งหมด";
                  }
                })()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/user_service`}
              >
                พนักงานบริการทั้งหมด
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/user_service/approve"
              >
                สมัครใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.user_service > 0 ? noti.user_service : ""}
                </span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/user_service/add"
              >
                เพิ่มพนักงานบริการ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
