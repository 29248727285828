import React, { useState, useEffect } from "react";
import Select from "react-select";
import $ from "jquery";
import dropify from "dropify";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";
import swal from "sweetalert";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";
import moment from "moment";

import { api, restaurant_status_option } from "../../config";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { useHistory } from "react-router-dom";
import { UserHead } from "./UserHead";

export const UserAdd = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const history = useHistory();

  const [user_img, setuser_img] = useState({
    preview: "",
    base64: "",
  });
  const [user_id] = useState("");
  const [full_name, setfull_name] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [email, setemail] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [show, setshow] = useState(false);

  const [birth_date, setbirth_date] = useState("");
  const [address, setaddress] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [sub_district_id, setsub_district_id] = useState("");
  const [district_id, setdistrict_id] = useState("");
  const [province_id, setprovince_id] = useState("");
  const [register_date, setregister_date] = useState("");
  const [point, setpoint] = useState(0);
  const [status, setstatus] = useState(0);

  const [option_sub_district, setoption_sub_district] = useState([]);
  const [option_district, setoption_district] = useState([]);
  const [option_province, setoption_province] = useState([]);

  const getOptionSubDistrict = async (district_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          value: value.id,
          label: value.name_th,
          zip_code: value.zip_code,
        });
      });
      setoption_sub_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${province_id}`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    $(".dropify").dropify();

    var drEventRider = $("#user_img").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setuser_img({
        preview: "",
        base64: "",
      });
      trigger("user_img");
    });
    getOptionProvince();
  }, []);

  const onSubmit = async (data) => {
    // console.log("submit", data);
    let birth_dateMoment = moment(birth_date);
    var birth_dateStr = birth_dateMoment.format("YYYY-MM-DD");

    let register_dateMoment = moment(register_date);
    var register_dateStr = register_dateMoment.format("YYYY-MM-DD");

    let send_data = {
      about: "",
      area_id: [],
      birth_date: birth_dateStr,
      credit: 0,
      degree_id: "",
      display_name: "",
      driving_license: "",
      driving_picture: "",
      email: email,
      employee_type_id: "",
      full_name: full_name,
      gender_id: "",
      identification_number: "",
      identification_picture: "",
      license_plate: "",
      line_id: "",
      location: {
        address: address,
        district_id: district_id.value,
        latitude: 0,
        longitude: 0,
        province_id: province_id.value,
        sub_district_id: sub_district_id.value,
      },
      phone_number: phone_number,
      point: point,
      picture: user_img.base64,
      provider: {
        password: password,
        provider_id: "",
        provider_name: "password",
        provider_token: "",
        username: username,
      },
      register_date: register_dateStr,
      status: parseInt(status.value),
      team_id: "",
      thumbnail: "",
      title_id: "",
      vehicle_color: "",
      vehicle_make: "",
      vehicle_type_id: "",
    };
    // console.log("send data", send_data);
    if (password === confirm_password) {
      try {
        let res = await Axios.post(`${api}/user/register`, send_data);
        // console.log(res);
        if (res.status === 201) {
          // // console.log("okkk");
          swal("สร้างสมาชิกเสร็จสิ้น !!", {
            icon: "success",
            timer: 1500,
          });
          setTimeout(() => {
            history.push("/user");
          }, 1600);
          // window.location = "/restaurant";
        }
      } catch (error) {
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  // console.log(errors);
  return (
    <div>
      <UserHead />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">รูปสมาชิก</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="mb-2">
                      {errors.user_img ? (
                        <div
                          className="alert alert-danger"
                          role="alert"
                          style={{ width: "100%", padding: 5 }}
                        >
                          กรุณาอัพโหลดไฟล์
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <input
                      type="file"
                      id="user_img"
                      className="dropify"
                      data-max-file-size="3M"
                      accept=".png, .jpg, .jpeg"
                      data-default-file=""
                      onChange={async (e) => {
                        // console.log(e.target.files[0]);
                        if (e.target.files.length) {
                          await setuser_img({
                            preview: URL.createObjectURL(e.target.files[0]),
                            base64: await toBase64(e.target.files[0]),
                          });
                          trigger("user_img");
                        }
                      }}
                      ref={(e) => {
                        register(
                          { name: "user_img" },
                          {
                            validate: (value) => {
                              return user_img.base64 || user_img.preview
                                ? true
                                : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="full_name"> Use No.</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="full_name"
                      className={`form-control `}
                      name="user_id"
                      type="text"
                      value={user_id}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="full_name">ชื่อ-นามสกุล</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="full_name"
                      className={`form-control ${
                        errors.full_name ? "error-input" : ""
                      }`}
                      name="full_name"
                      type="text"
                      ref={register({ required: true })}
                      value={full_name}
                      onChange={(e) => {
                        setfull_name(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">วัน/เดือน/ปีเกิด</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <Controller
                        control={control}
                        name="birth_date"
                        render={({ onChange, value }) => (
                          <DayPickerInput
                            value={birth_date}
                            onDayChange={async (e) => {
                              await setbirth_date(e);
                              trigger("birth_date");
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                borderColor: errors.birth_date
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                            ref={(e) => {
                              register(
                                { name: "birth_date" },
                                {
                                  validate: (value) => {
                                    // console.log(e.props);

                                    return e.props.value === undefined ||
                                      e.props.value === ""
                                      ? false
                                      : true;
                                  },
                                }
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">ที่อยู่</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="address"
                      className={`form-control ${
                        errors.address ? "error-input" : ""
                      }`}
                      name="address"
                      type="text"
                      ref={register({ required: true })}
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province_id">จังหวัด</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกจังหวัด.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.province_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.province_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={province_id}
                      options={option_province}
                      onChange={async (e) => {
                        await setprovince_id(e);
                        trigger("province_id");

                        getOptionDistrict(e.value);
                      }}
                      ref={(e) => {
                        register(
                          { name: "province_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="province">อำเภอ / เขต</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกอำเภอ.."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.district_id ? "red" : "#cdd4e0",
                          "&:hover": {
                            borderColor: errors.district_id ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={district_id}
                      options={option_district}
                      onChange={async (e) => {
                        await setdistrict_id(e);
                        trigger("district_id");

                        setsub_district_id("");

                        getOptionSubDistrict(e.value);
                      }}
                      ref={(e) => {
                        register(
                          { name: "district_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="district">ตำบล / แขวง</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกตำบล/แขวง..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.sub_district_id
                            ? "red"
                            : "#cdd4e0",
                          "&:focus": {
                            borderColor: errors.sub_district_id
                              ? "red"
                              : "#cdd4e0",
                          },
                        }),
                      }}
                      value={sub_district_id}
                      options={option_sub_district}
                      onChange={async (e) => {
                        await setsub_district_id(e);
                        trigger("sub_district_id");

                        setzip_code(e.zip_code);
                      }}
                      ref={(e) => {
                        register(
                          { name: "sub_district_id" },
                          {
                            validate: (value) => {
                              return e.props.value.value === undefined ||
                                e.props.value.value === ""
                                ? false
                                : true;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="zip">รหัสไปรษณีย์</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="zip"
                      className="form-control"
                      name="zip"
                      type="text"
                      value={zip_code}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="phone_number">เบอร์โทรศัพท์</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="phone_number"
                      className={`form-control ${
                        errors.phone_number ? "error-input" : ""
                      }`}
                      name="phone_number"
                      type="text"
                      ref={register({ required: true })}
                      value={phone_number}
                      onChange={(e) => {
                        setphone_number(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="point">แต้ม</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="point"
                      className={`form-control ${
                        errors.point ? "error-input" : ""
                      }`}
                      name="point"
                      type="number"
                      ref={register({ min: 0 })}
                      value={point}
                      onChange={(e) => {
                        setpoint(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="address">วันที่สมัคร</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="input-group input-daterange d-flex align-items-center">
                      <Controller
                        control={control}
                        name="register_date"
                        render={({ onChange, value }) => (
                          <DayPickerInput
                            value={register_date}
                            onDayChange={async (e) => {
                              await setregister_date(e);
                              trigger("register_date");
                            }}
                            placeholder={"วัน/เดือน/ปี"}
                            style={{ width: "100%" }}
                            inputProps={{
                              style: {
                                width: "100%",
                                padding: 5,
                                borderColor: errors.register_date
                                  ? "red"
                                  : "#cdd4e0",
                              },
                            }}
                            format="L"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              locale: "th",
                              localeUtils: MomentLocaleUtils,
                            }}
                            ref={(e) => {
                              register(
                                { name: "register_date" },
                                {
                                  validate: (value) => {
                                    return register_date ? true : false;
                                  },
                                }
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="email">อีเมล</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="email"
                      className={`form-control ${
                        errors.email ? "error-input" : ""
                      }`}
                      name="email"
                      type="text"
                      ref={register({ required: true })}
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {password !== confirm_password ? (
                  <div className=" row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-5">
                      <label htmlFor="name" style={{ color: "red" }}>
                        รหัสผ่านไม่ตรงกัน
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="username">Username</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="username"
                      className={`form-control ${
                        errors.username ? "error-input" : ""
                      }`}
                      name="username"
                      type="text"
                      ref={register({ required: true })}
                      value={username}
                      onChange={async (e) => {
                        await setusername(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="password">Password</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="password"
                      className={`form-control ${
                        errors.password ? "error-input" : ""
                      }`}
                      name="password"
                      type={show ? "text" : "password"}
                      ref={register({ required: true })}
                      value={password}
                      onChange={async (e) => {
                        await setpassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="confirm_password">Confirm password</label>
                  </div>
                  <div className="col-lg-5">
                    <input
                      id="confirm_password"
                      className={`form-control ${
                        errors.confirm_password ? "error-input" : ""
                      }`}
                      name="confirm_password"
                      type={show ? "text" : "password"}
                      // ref={register({ required: true })}
                      value={confirm_password}
                      onChange={async (e) => {
                        await setconfirm_password(e.target.value);
                      }}
                    />
                    <div className="form-check mt-4 ml-4">
                      <input
                        type="checkbox"
                        className="form-check-input "
                        checked={show}
                        onChange={() => setshow(!show)}
                      />
                      Show Password
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="status">สถานะ</label>
                  </div>
                  <div className="col-lg-5">
                    <Select
                      placeholder={"เลือกสถานะ..."}
                      menuPlacement="top"
                      menuContainerStyle={{ zIndex: 999 }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: errors.status ? "red" : "#cdd4e0",

                          "&:focus": {
                            borderColor: errors.status ? "red" : "#cdd4e0",
                          },
                        }),
                      }}
                      value={status}
                      options={restaurant_status_option}
                      onChange={async (e) => {
                        await setstatus(e);
                        trigger("status");
                      }}
                      ref={(e) => {
                        register(
                          { name: "status" },
                          {
                            validate: (value) => {
                              return status.value ? true : false;
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary  mr-2  mt-2 "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
