import React from "react";
import { Route } from "react-router-dom";
import { NotFound } from "./NotFound";

const PrivateRoute = ({
  component: Component,
  isAllowed: isAllowed,
  token: token,
  componentProps: componentProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (isAllowed && token) ? <Component {...props} {...componentProps} /> : <NotFound />
      }
    />
  );
};

export default PrivateRoute;
