import React, { useState, useEffect, useContext } from "react";
import {
  Switch,
  BrowserRouter as Route,
  NavLink,
  useHistory,
} from "react-router-dom";
import { NotiContext } from "../../store/keep";

import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/th";

import { api, header_token } from "../../config";

import moment from "moment";
import { OrderCarNew } from "./OrderCarNew";
import { OrderCarSending } from "./OrderCarSending";
import { OrderCarSuccess } from "./OrderCarSuccess";
import { OrderCarCancel } from "./OrderCarCancel";
import { OrderCarUnsuccess } from "./OrderCarUnsuccess";
import { OrderCarPreOrder } from "./OrderCarPreOrder";
import { OrderCarHistory } from "./OrderCarHistory";
import { OrderCarReceive } from "./OrderCarReceive";
import { OrderCarOnprocess } from "./OrderCarOnprocess";

function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -109 }}
      {...props}
    >
      <div className={classNames.overlay}>{children}</div>
    </div>
  );
}

export const OrderCarIndex = () => {
  const { push } = useHistory();
  const { noti } = useContext(NotiContext);

  const [data_preorder, setdata_preorder] = useState([]);
  const [preorder_count, setpreorder_count] = useState(0);
  const [start_date, setstart_date] = useState(moment().format("DD/MM/YYYY"));

  const [option_area, setoption_area] = useState([]);

  const getDataPreOrder = async () => {
    try {
      let send_data = {
        end_date: "",
        order_no: "",
        start_date: "",
        status: [1, 2, 3],
        type: [0],
        service: ["car"],
      };

      let { data } = await Axios.post(
        `${api}/admin/order/filter?page=${1}&limit=${999999}&sort=${-1}`,
        send_data,
        header_token
      );

      // console.log("getDataPreOrder", data);
      if (data.orders !== null) {
        setdata_preorder(data.orders);
        setpreorder_count(data.orders.length);
      } else {
        setdata_preorder([]);
        setpreorder_count(0);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  };

  const getOptionArea = async () => {
    try {
      let { data } = await Axios.get(`${api}/area`);
      let tmp = [];
      tmp.push({ value: "", label: "ทั้งหมด" });

      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_area(tmp);
    } catch (error) {
      console.log(error.response);
    }
  };

  const pushToLink = async (link) => {
    setTimeout(() => {
      push(link);
    }, 50);
  };

  useEffect(() => {
    if (data_preorder.length <= 0) {
      getDataPreOrder();
    }
    getOptionArea();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin ">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">รายการคำสั่งซื้อ</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                บริการเรียกรถ
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-1">
        <h3 className=" ml-3" style={{ marginTop: 10 }}>
          {moment(start_date, "DD/MM/YYYY").format("LL")}
        </h3>
        <div className="col-2 ml-auto">
          <div className="input-group input-daterange d-flex align-items-center">
            <DayPickerInput
              value={start_date}
              onDayChange={(e) => {
                setstart_date(e);
              }}
              overlayComponent={CustomOverlay}
              style={{ width: "100%" }}
              inputProps={{
                style: {
                  width: "100%",
                },
              }}
              placeholder={"วัน/เดือน/ปี"}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
              // inputProps={{
              //   style: {
              //     borderColor: "hsl(0,0%,80%)",
              //   },
              // }}
            />
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">ออเดอร์ทั้งหมด</h6>
                <span className="h2 mr-2">{total_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">ออเดอร์ใหม่</h6>
                <span className="h2 mr-2">{new_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">กำลังจัดจัดส่ง</h6>
                <span className="h2 mr-2">{sending_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">จัดส่งสำเร็จ</h6>
                <span className="h2 mr-2">{success_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">ยกเลิก,ไม่สำเร็จ</h6>
                <span className="h2 mr-2">{cancel_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
        <div className="col-2">
          <div className="card">
            <LoadingMask loading={loading} text={"loading..."}>
              <div className="card-body">
                <h6 className="tx-12 text-muted">ออเดอร์ล่วงหน้า</h6>
                <span className="h2 mr-2">{preorder_count}</span>
                <span className="tx-12 text-muted">รายการ</span>
              </div>
            </LoadingMask>
          </div>
        </div>
      </div> */}

      <div className="row mt-3">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order"
              >
                ออเดอร์ใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.car_service > 0 ? noti.car_service : ""}
                </span>
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/receive"
              >
                พนักงานรับผู้โดยสาร
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/onprocess"
              >
                ดำเนินการ
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/sending"
              >
                กำลังจัดส่ง
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/success"
              >
                ส่งสำเร็จ
              </NavLink>
            </li>
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/cancel"
              >
                ยกเลิก
              </NavLink>
            </li>
            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/unsuccess"
              >
                ไม่สำเร็จ
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/car_service_order/pre_order"
              >
                {data_preorder.length > 0 ? (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "red",
                      marginRight: 8,
                      display: "inline-block",
                    }}
                  />
                ) : (
                  ""
                )}
                ออเดอร์ล่วงหน้า
              </NavLink>
            </li>

            <li className="nav-item mb-1">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/car_service_order/history`}
              >
                ประวัติคำสั่งซื้อ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route exact path={`/car_service_order`}>
          <OrderCarNew
            // data={data.filter((e) => e.status === 1)}
            statusOrder={[1]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>

        <Route exact path={`/car_service_order/receive`}>
          <OrderCarReceive
            // data={data.filter((e) => e.status === 2)}
            statusOrder={[2]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>

        <Route exact path={`/car_service_order/onprocess`}>
          <OrderCarOnprocess
            // data={data.filter((e) => e.status === 3)}
            statusOrder={[3]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>

        <Route exact path={`/car_service_order/sending`}>
          <OrderCarSending
            // data={data.filter((e) => e.status === 4)}
            statusOrder={[4]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>
        <Route exact path={`/car_service_order/success`}>
          <OrderCarSuccess
            // // data={data.filter((e) => e.status === 5)}
            statusOrder={[5]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>
        <Route exact path={`/car_service_order/cancel`}>
          <OrderCarCancel
            // data={data.filter((e) => e.status === 0)}
            statusOrder={[0]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>
        <Route exact path={`/car_service_order/unsuccess`}>
          <OrderCarUnsuccess
            // data={data.filter((e) => e.status === 6)}
            statusOrder={[6]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>
        <Route exact path={`/car_service_order/pre_order`}>
          <OrderCarPreOrder
            data={data_preorder}
            pushToLink={pushToLink}
            optionArea={option_area}
          />
        </Route>
        <Route exact path={`/car_service_order/history`}>
          <OrderCarHistory
            statusOrder={[]}
            pushToLink={pushToLink}
            optionArea={option_area}
            start_date={start_date}
          />
        </Route>
      </Switch>
    </div>
  );
};
