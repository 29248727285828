import React, { useEffect, useState } from "react";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

import "moment/locale/th";
import { api, header_token, recommened_options } from "../../config";
import Axios from "axios";
import { Controller, useForm } from "react-hook-form";

export const PromotionBannerAdd = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const [loading, setloading] = useState(false);

  const [restaurant_option, setrestaurant_option] = useState("");
  const [select_restaurant, setselect_restaurant] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [status, setstatus] = useState("");

  const [filter_name, setfilter_name] = useState("");
  const [limit] = useState(20);
  const [filename, setfilename] = useState("");

  const [image, setImage] = useState({ preview: "", base64: "" });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getData = async () => {
    setloading(true);

    let send_data = {
      name_th: filter_name,
      // status: [0],
    };
    try {
      let { data } = await Axios.post(
        `${api}/admin/restaurants/filter?page=${1}&limit=${limit}`,
        send_data,
        header_token
      );

      let tmp = [];
      data.restaurants.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      // console.log(tmp);
      setrestaurant_option(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const addPromotionBanner = async () => {
    try {
      let send_data = {
        begin: moment(start, "DD/MM/YYYY").format("YYYY-MM-DD"),
        end: moment(end, "DD/MM/YYYY").format("YYYY-MM-DD"),
        cover: image.base64,
        restaurant_id: select_restaurant.value,
        status: parseInt(status.value),
      };
      // console.log(send_data);
      let res = await Axios.post(
        `${api}/admin/restaurants/banner`,
        send_data,
        header_token
      );

      // console.log(res);
      if (res.status === 201) {
        swal("เพิ่มโปรโมชั่นแบนเนอร์เสร็จสิ้น !!", {
          icon: "success",
        });
        setselect_restaurant("");
        setstart("");
        setImage("");
        setend("");
        setstatus("");
        setfilename("");
      } else {
        swal("ผิดพลาด !!", {
          icon: "error",
        });
      }
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);
    addPromotionBanner();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [filter_name]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="name">โปรโมชั่นแบนเนอร์</label>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-11">
                        <img
                          id="img-upload"
                          src={image.preview}
                          style={{ maxWidth: "100%", maxHeight: 500 }}
                        />
                        <div className="row">
                          <div className="col-10">
                            <div className="input-group">
                              <input
                                type="file"
                                id="imgBanner"
                                className="form-control"
                                accept=".png, .jpg, .jpeg"
                                value={filename}
                                onChange={async (e) => {
                                  setfilename(e.target.value);
                                  if (e.target.files.length) {
                                    await setImage({
                                      preview: URL.createObjectURL(
                                        e.target.files[0]
                                      ),
                                      base64: await toBase64(e.target.files[0]),
                                    });
                                    trigger("imgBanner");
                                  }
                                }}
                                className={` ${
                                  errors.imgBanner ? "error-img-upload" : ""
                                }`}
                                style={{
                                  width: "100%",
                                  overflowWrap: "break-word",
                                  display: "-webkit-inline-box",
                                }}
                                ref={(e) => {
                                  register(
                                    { name: "imgBanner" },
                                    {
                                      validate: () => {
                                        return image.preview || image.base64
                                          ? true
                                          : false;
                                      },
                                    }
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-light btn-sm mt-1"
                              onClick={() => {
                                // console.log(e.target.files[0]);
                                setfilename("");
                                setImage({
                                  preview: null,
                                  raw: "",
                                  base64: "",
                                });
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3 pl-3">
                    <label htmlFor="name">เลือกร้านค้า</label>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-11">
                        <div className="side-by-side clearfix">
                          <LoadingMask loading={loading} text={"loading..."}>
                            <Select
                              name="select_restaurant"
                              value={select_restaurant}
                              onChange={async (e) => {
                                await setselect_restaurant(e);
                                trigger("select_restaurant");
                              }}
                              options={restaurant_option}
                              placeholder={"ค้นหา..."}
                              onKeyDown={async (e) => {
                                let val = e.target.value;
                                setTimeout(async () => {
                                  await setfilter_name(val);
                                }, 50);
                              }}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  borderColor: errors.select_restaurant
                                    ? "red"
                                    : "#cdd4e0",
                                  "&:hover": {
                                    borderColor: errors.select_restaurant
                                      ? "red"
                                      : "#cdd4e0",
                                  },
                                }),
                              }}
                              ref={(e) => {
                                register(
                                  { name: "select_restaurant" },
                                  {
                                    validate: () => {
                                      return select_restaurant.value
                                        ? true
                                        : false;
                                    },
                                  }
                                );
                              }}
                              isClearable={true}
                            />
                          </LoadingMask>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3 pl-3">
                    <label htmlFor="name">กำหนดระยะเวลา</label>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-5">
                        <Controller
                          control={control}
                          name="start"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={start}
                              onDayChange={async (e) => {
                                await setstart(e);
                                trigger("start");

                                if (!end) {
                                  await setend(e);
                                  trigger("end");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 5,
                                  borderColor: errors.start ? "red" : "#cdd4e0",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "start" },
                                  {
                                    validate: (value) => {
                                      return start ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-1 text-center">ถึง</div>
                      <div className="col-5">
                        <Controller
                          control={control}
                          name="end"
                          render={({ onChange, value }) => (
                            <DayPickerInput
                              value={end}
                              onDayChange={async (e) => {
                                await setend(e);
                                trigger("end");

                                if (!start) {
                                  await setstart(e);
                                  trigger("start");
                                }
                              }}
                              placeholder={"วัน/เดือน/ปี"}
                              style={{ width: "100%" }}
                              inputProps={{
                                style: {
                                  width: "100%",
                                  padding: 5,
                                  borderColor: errors.end ? "red" : "#cdd4e0",
                                },
                              }}
                              format="L"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                locale: "th",
                                localeUtils: MomentLocaleUtils,
                              }}
                              ref={(e) => {
                                register(
                                  { name: "end" },
                                  {
                                    validate: (value) => {
                                      return end ? true : false;
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-3 pl-3">
                    <label htmlFor="name">สถานะ</label>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-11">
                        <div className="side-by-side clearfix">
                          <Select
                            placeholder={"เลือก..."}
                            menuPlacement="top"
                            menuContainerStyle={{ zIndex: 999 }}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                borderColor: errors.status ? "red" : "#cdd4e0",
                                "&:hover": {
                                  borderColor: errors.status
                                    ? "red"
                                    : "#cdd4e0",
                                },
                              }),
                            }}
                            value={status}
                            options={recommened_options}
                            onChange={async (e) => {
                              await setstatus(e);
                              trigger("status");
                            }}
                            ref={(e) => {
                              register(
                                { name: "status" },
                                {
                                  validate: () => {
                                    return status.value ? true : false;
                                  },
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="row">
                      {/* <button className="btn btn-outline-primary mr-2 ml-auto mt-2 ">
                        ยกเลิก
                      </button> */}
                      <button
                        className="btn btn-primary  mr-2  mt-2 ml-auto "
                        // onClick={() => addPromotionBanner()}
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
