import React, { useContext } from "react";
import { useRouteMatch, NavLink } from "react-router-dom";
import { NotiContext } from "../../store/keep";

export const CreditHead = () => {
  let match = useRouteMatch();
  const { noti } = useContext(NotiContext);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">การเติมเครดิต</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {(() => {
                  if (match.url.includes("history")) {
                    return "ประวัติ";
                  } else {
                    return "เติมเครดิต";
                  }
                })()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/credit"
              >
                คำขอใหม่
                <span className="badge badge-pill badge-danger ml-1">
                  {noti.credit > 0 ? noti.credit : ""}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to="/credit/history"
              >
                ประวัติ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
