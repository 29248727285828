import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { api, header_token } from "./config";
import Axios from "axios";
export const LoginFormRestaurant = () => {
  const { register, handleSubmit, errors } = useForm();

  const login = async (user, pass) => {
    try {
      let res = await axios.post(`${api}/user/login`, {
        is_restaurant: true,
        username: user,
        password: pass,
        provider: "password",
      });

      if (res.status === 200) {
        // console.log(res);
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("role", "restaurant");
        return true;
      } else {
        return false;
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
      swal("Login Failed", "Username or Password  wrong", "error");

      return false;
    }
  };

  const unsubscribeTopic = async () => {
    let fcm = await localStorage.getItem("fcm-token");
    // console.log("fcm", fcm);
    if (fcm) {
      try {
        let unsub = await Axios.get(
          `${api}/fcm/unsubscribe/${fcm}`,
          header_token
        );
        // console.log("unsub", unsub);

        if (unsub.status === 200) {
          localStorage.clear();

          return true;
        }
      } catch (error) {
        console.log(error);
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
        return false;
      }
    } else {
      localStorage.clear();
    }
  };

  const onSubmit = async (value) => {
    let is_login = await login(value.username, value.password);
    if (is_login) {
      window.location = window.location.href;
    }
  };

  useEffect(() => {
    unsubscribeTopic();
  }, []);

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5 border">
                <div className="brand-logo">
                  <img src="/images/logo.png" alt="logo" />
                </div>
                <h4>Hello! Restaurant</h4>
                <h6 className="font-weight-light">Sign in to continue.</h6>
                <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control form-control-lg ${
                        errors.username ? "error-input" : ""
                      }`}
                      id="username"
                      placeholder="Username"
                      name="username"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className={`form-control  form-control-lg${
                        errors.password ? "error-input" : ""
                      }`}
                      id="password"
                      name="password"
                      placeholder="Password"
                      ref={register({ required: true, minLength: 6 })}
                    />
                  </div>
                  <div className="mt-3">
                    <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                      SIGN IN
                    </button>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <input type="checkbox" /> Keep me signed in
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
