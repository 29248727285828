import React, { } from "react";
import { BrandHeader } from "./BrandHeader";

export const CategoryBrand = () => {

  return (
    <div>
      <BrandHeader />
    </div>

  )
}