import React, { useState, useEffect } from "react";
import Axios from "axios";
import { api, img_url } from "../../config";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import "react-day-picker/lib/style.css";
import "moment/locale/th";
import { Link } from "react-router-dom";
import { BrandHeader } from "./BrandHeader";

export const ListBrand = () => {
  const [loading, setLoading] = useState(true);

  const [img, setImg] = useState("");
  const [data, setData] = useState([]);

  const [filterName, setFilterName] = useState("");

  const getBrand = async () => {
    try {
      setLoading(true);
      let { data } = await Axios.get(`${api}/brand`);
      setData(data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  useEffect(() => {
    getBrand();
  }, []);

  return (
    <div>
      <BrandHeader />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-end mb-2">
                <Link
                  to={`/brands/add`}
                  className={"btn btn-primary"}
                >
                  เพิ่มแบรนด์
                </Link>
              </div>
              <div className="row">
                <div className="col">
                  <h4 className="mb-4 mt-2">
                    ทั้งหมด {data?.length.toLocaleString() ?? 0} รายการ
                  </h4>
                </div>

                <div className="form-row align-items-center mb-2 mr-2 ">
                  <div className=" mr-2" style={{ width: 200 }}>
                    <span className="tx-13 text-muted">ค้นหา</span>
                    <input
                      type="text"
                      className="form-control- global_filter"
                      id="global_filter"
                      placeholder="ชื่อ"
                      value={filterName}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <LoadingMask loading={loading} text={"loading..."}>
                <div style={{ minHeight: 500 }}>
                  <div className="sticky-div">
                    <table id="dataTable" className="table sticky-table">
                      <thead>
                        <tr>
                          <th style={{ width: 50 }} className="sticky-head">
                            #
                          </th>
                          <th style={{ width: 50 }} className="sticky-head">
                            ลำดับ
                          </th>
                          <th style={{ width: 100 }} className="sticky-head">
                            รูป
                          </th>
                          <th style={{ width: 200 }} className="sticky-head">
                            ชื่อ
                          </th>
                          <th style={{ width: 200 }} className="sticky-head">
                            ประเภทอาหาร
                          </th>
                          <th style={{ width: 80 }}>สถานะ</th>
                          <th style={{ width: 120 }}>เพิ่มเติม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className="sticky-col">
                                {index + 1}
                              </td>
                              <td className="sticky-col">
                                {value.index}
                              </td>
                              <td className="sticky-col" >
                                <div
                                  className="thumbnail cursor-img"
                                  data-toggle="modal"
                                  data-target=".show-img"
                                  onClick={() => {
                                    setImg(
                                      value.logo === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.logo}`
                                    );
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="lazy img-responsive"
                                    src={
                                      value.logo === ""
                                        ? "/images/no-img.jpg"
                                        : `${img_url}${value.logo}`
                                    }
                                  />
                                </div>
                              </td>
                              <td className="sticky-col">
                                {value.name}
                              </td>
                              <td className="sticky-col">
                                {value.categories?.map(e => e.title)?.join(", ") ?? ""}
                              </td>
                              <td>
                                {(() => {
                                  switch (value.status) {
                                    case 0:
                                      return (
                                        <div className="badge badge-warning">
                                          ระงับ
                                        </div>
                                      );
                                    case 1:
                                      return (
                                        <div className="badge badge-success">
                                          ใช้งาน
                                        </div>
                                      );
                                    default:
                                      break;
                                  }
                                })()}
                              </td>
                              <td>
                                <Link
                                  className="btn btn-outline-primary mb-1 mr-1"
                                  to={`/brands/detail/${value.id}`}
                                >
                                  รายละเอียด
                                </Link>
                                <Link
                                  className="btn btn-outline-warning mb-1"
                                  to={`/brands/edit/${value.id}`}
                                >
                                  แก้ไข
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </LoadingMask>
            </div>
          </div>
        </div>
      </div>

      <div id="showModdal" className="modal  show-img" role="dialog">
        <div className="modal-dialog ">
          <div className="modal-content" style={{ width: 800 }}>
            <div className="modal-header">
              <span>รูปภาพ</span>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                alt=""
                src={img ? img : ""}
                style={{ maxWidth: 600, display: "block", margin: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
