import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";

import "moment/locale/th";

import moment from "moment";
import { api, branch_status_option } from "../../config";

// const type_options = [
//   { value: "normal", label: "ยอดขาย GP" },
//   // { value: "postpaid", label: "รายเดือน" },
//   { value: "invoice", label: "วางบิล" },
//   { value: "buy", label: "ฝากซื้อ" },
// ];

export const BranchAddProfile = forwardRef((props, ref) => {
  const { register, handleSubmit, errors } = useForm();

  const [image, setImage] = useState({ preview: "", raw: "", base64: "" });
  const [name_th, setname_th] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [contact_name, setcontact_name] = useState("");
  const [personal_number, setpersonal_number] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [sub_district, setsub_district] = useState("");
  const [district, setdistrict] = useState("");
  const [province, setprovince] = useState("");
  const [zip_code, setzip_code] = useState("");
  const [categories, setcategories] = useState([]);
  const [register_date, setregister_date] = useState(new Date());
  const [status, setstatus] = useState({ value: 1, label: "ใช้งาน" });
  const [filename, setfilename] = useState("");

  const [option_sub_district, setoption_sub_district] = useState([]);
  const [option_district, setoption_district] = useState([]);
  const [option_province, setoption_province] = useState([]);

  useImperativeHandle(ref, () => ({
    sendToParent() {
      sentBack();
    },
  }));

  const getOptionSubDistrict = async (district_id) => {
    try {
      let { data } = await Axios.get(
        `${api}/sub-district/district/${district_id}`
      );
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({
          value: value.id,
          label: value.name_th,
          zip_code: value.zip_code,
        });
      });
      setoption_sub_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionDistrict = async (province_id) => {
    try {
      let { data } = await Axios.get(`${api}/district/province/${province_id}`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_district(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionProvince = async () => {
    try {
      let { data } = await Axios.get(`${api}/province`);
      // console.log(data);
      let tmp = [];
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th });
      });
      setoption_province(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const sentBack = () => {
    let momentObj = moment(register_date);
    var momentString = momentObj.format("YYYY-MM-DD");

    let send_data = {
      name_th: name_th,
      image: image.base64,
      phone_number: phone_number,
      contact_name: contact_name,
      personal_number: personal_number,
      email: email,
      address: address,
      sub_district_id: sub_district.value,
      district_id: district.value,
      province_id: province.value,
      register_date: momentString,
      status: status.value,
      // type: type ? type.value : "",
    };

    props.callbackProfile(send_data);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    getOptionProvince();
  }, []);

  const onSubmit = (data) => {
    // console.log(data);
    sentBack();
    props.nextRef.current.click();
  };
  // console.log(errors);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="name">รูปสาขา</label>
          </div>
          <div className="col-lg-5">
            <img
              id="img-upload"
              src={image.preview}
              style={{ maxWidth: "100%", maxHeight: 500 }}
            />
            <div className="row">
              <div className="col-10">
                <div className="input-group">
                  <input
                    type="file"
                    id="imgInp"
                    className="form-control"
                    accept=".png, .jpg, .jpeg"
                    value={filename}
                    onChange={async (e) => {
                      setfilename(e.target.value);
                      // console.log(e.target.files[0]);
                      if (e.target.files.length) {
                        setImage({
                          preview: URL.createObjectURL(e.target.files[0]),
                          raw: e.target.files[0],
                          base64: await toBase64(e.target.files[0]),
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <button
                  className="btn btn-light btn-sm mt-1"
                  onClick={() => {
                    // console.log(e.target.files[0]);
                    setfilename("");
                    setImage({
                      preview: null,
                      raw: "",
                      base64: "",
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="restaurant_id">รหัสสาขา</label>
          </div>
          <div className="col-lg-5">
            <input
              type="text"
              disabled
              value=""
              className="form-control"
              id="restaurant_id"
              placeholder=""
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="name_th">
              ชื่อสาขา<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="name_th"
              className={`form-control ${errors.name_th ? "error-input" : ""}`}
              name="name_th"
              type="text"
              ref={register({ required: true })}
              value={name_th}
              onChange={(e) => {
                setname_th(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="phone_number">
              เบอร์โทรศัพท์ประจำสาขา<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="phone_number"
              className={`form-control ${
                errors.phone_number ? "error-input" : ""
              }`}
              name="phone_number"
              type="text"
              ref={register({ required: true })}
              value={phone_number}
              onChange={(e) => setphone_number(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="contactname">ชื่อผู้ติดต่อ</label>
          </div>
          <div className="col-lg-5">
            <input
              id="contactname"
              className="form-control"
              name="contactname"
              type="text"
              value={contact_name}
              onChange={(e) => setcontact_name(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="personal_number">
              เบอร์โทรศัพท์ผู้ติดต่อ<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              id="personal_number"
              className={`form-control ${
                errors.personal_number ? "error-input" : ""
              }`}
              name="personal_number"
              type="text"
              ref={register({ required: true })}
              value={personal_number}
              onChange={(e) => setpersonal_number(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="email">
              อีเมล์<span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="col-lg-5">
            <input
              className={`form-control ${errors.email ? "error-input" : ""}`}
              name="email"
              type="text"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">ที่อยู่</label>
          </div>
          <div className="col-lg-5">
            <input
              id="address"
              className="form-control"
              name="address"
              type="text"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">จังหวัด</label>
          </div>
          <div className="col-lg-5">
            <Select
              value={province}
              onChange={(e) => {
                setprovince(e);
                setdistrict({});
                setsub_district({});

                // console.log(e)
                getOptionDistrict(e.value);
              }}
              options={option_province}
              placeholder={"เลือกจังหวัด.."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="province">อำเภอ / เขต</label>
          </div>
          <div className="col-lg-5">
            <Select
              value={district}
              onChange={(e) => {
                setdistrict(e);
                setsub_district({});

                getOptionSubDistrict(e.value);
              }}
              options={option_district}
              placeholder={"เลือกอำเภอ..."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="district">ตำบล / แขวง</label>
          </div>
          <div className="col-lg-5">
            <Select
              name="district"
              value={sub_district}
              onChange={(e) => {
                setsub_district(e);
                setzip_code(e.zip_code);
              }}
              options={option_sub_district}
              placeholder={"เลือกตำบล/แขวง..."}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="zip">รหัสไปรษณีย์</label>
          </div>
          <div className="col-lg-5">
            <input
              id="zip"
              className="form-control"
              name="zip"
              type="text"
              value={zip_code}
              onChange={(e) => setzip_code(e.target.value)}
              readOnly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="address">วันที่สมัคร</label>
          </div>
          <div className="col-lg-5">
            <DayPickerInput
              value={register_date}
              onDayChange={(e) => {
                // console.log(typeof e);
                // console.log(e);

                setregister_date(e);
              }}
              placeholder={"วัน/เดือน/ปี"}
              style={{ width: "100%" }}
              inputProps={{ style: { width: "100%", padding: 5 } }}
              format="L"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                locale: "th",
                localeUtils: MomentLocaleUtils,
              }}
              readonly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label htmlFor="status">สถานะ</label>
          </div>
          <div className="col-lg-5">
            <Select
              id="status"
              name="status"
              options={branch_status_option}
              placeholder={"สถานะ"}
              value={status}
              onChange={(e) => setstatus(e)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-10"></div>
          <div className="col">
            <div className="row">
              {/* <a href="#" className="btn btn-outline-primary mr-2 ml-auto mt-2 ">
              ยกเลิก
            </a> */}
              <button
                type="submit"
                className="btn btn-primary  mr-2  mt-2 "
                onClick={() => {
                  // validateForm();
                  // console.log(props);
                  // console.log("click");
                  // handleSubmit(onSubmit);
                }}
                // value={"okasdfjo"}
              >
                ถัดไป
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});
