import React, { useState, useEffect } from "react";
// import { RestaurantDetailHistory } from "./RestaurantDetailHistory";
// import { RestaurantDetailSale } from "./RestaurantDetailSale";
// import { RestaurantDetailCredit } from "./RestaurantDetailCredit";
// import { RestaurantDetailMenu } from "./RestaurantDetailMenu";
// import { RestaurantDetailMenuGroup } from "./RestaurantDetailMenuGroup";
// import { RestaurantDetailRate } from "./RestaurantDetailRate";
import {
  Switch,
  BrowserRouter as Route,
  NavLink,
  useParams,
  useHistory,
} from "react-router-dom";
import Axios from "axios";
import { api, header_token } from "../../config";
import swal from "sweetalert";
import { BranchDetailHistory } from "./BranchDetailHistory";
import { BranchDetailSale } from "./BranchDetailSale";
import { BranchDetailShipping } from "./BranchDetailShipping";

export const BranchDetail = () => {
  const { push } = useHistory();
  const { id } = useParams();
  const [name, setname] = useState();

  const callbackName = (name) => {
    setname(name);
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(`${api}/admin/branch/${id}`, header_token);

      setname(data.name_th);
    } catch (error) {
      console.log(error.response);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const pushToLink = async (link) => {
    setTimeout(() => {
      push(link);
    }, 50);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-3">สาขา</h4>
            <div className="d-none d-md-flex mt-2">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าหลัก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">สาขา</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">{name}</p>
            </div>
          </div>
          <div className="button-wrapper d-flex align-items-center mt-md-3 mt-xl-0"></div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <h3 className="mb-4 mt-2">{name}</h3>
          <div className="card">
            <div className="card-header">
              <ul className="nav nav-tabs nav-fill card-header-tabs">
                <li className="nav-item">
                  <NavLink
                    exact
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/branch/detail/${id}/history`}
                  >
                    ประวัติ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/branch/detail/${id}/sale`}
                    isActive={() => [`/branch/detail/${id}/sale`, `/branch/detail/${id}/shipping`].includes(window.location.pathname)}
                    >
                    ยอดขาย
                  </NavLink>
                </li>
              </ul>
            </div>

            <Switch>
              <Route exact path={`/branch/detail/${id}/history`}>
                <BranchDetailHistory
                  branchId={id}
                  callbackName={callbackName}
                  pushToLink={pushToLink}
                />
              </Route>

              <Route exact path={`/branch/detail/${id}/sale`}>
                <BranchDetailSale
                  branchId={id}
                  callbackName={callbackName}
                  pushToLink={pushToLink}
                />
              </Route>

              <Route exact path={`/branch/detail/${id}/shipping`}>
                <BranchDetailShipping
                  branchId={id}
                  callbackName={callbackName}
                  pushToLink={pushToLink}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};
