import React, { useState, useEffect, useContext } from "react";

import { useRouteMatch, NavLink } from "react-router-dom";

import Axios from "axios";
import { api, header_token } from "../../config";
import swal from "sweetalert";

export const BranchHead = ({ type }) => {
  let match = useRouteMatch();

  const [countall, setcountall] = useState(0);

  const getData = async () => {
    try {
      let send_data = {
        // name_th: filter_name,
        // province_id: filter_province.value,
        // status: [0],
        service: [type ? type : "order"],
      };
      let { data } = await Axios.post(
        `${api}/admin/branch/filter?page=${1}&limit=${0}`,
        send_data,
        header_token
      );

      setcountall(data.meta.total_count);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              สาขาทั้งหมด({countall.toLocaleString()})
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                {(() => {
                  if (match.url.includes("add")) {
                    return "เพิ่มสาขา";
                  } else if (match.url.includes("sale")) {
                    return "ยอดขายต่อสาขา";
                  } else {
                    return "สาขาทั้งหมด";
                  }
                })()}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <NavLink
                exact
                className={"nav-link "}
                activeClassName="active"
                to={`/branch`}
              >
                สาขาทั้งหมด
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/branch/sale`}
              >
                ยอดขายต่อสาขา
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={"nav-link "}
                activeClassName="active"
                to={`/branch/add`}
              >
                เพิ่มสาขา
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
