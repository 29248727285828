import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import Select from "react-select";

import {
  api,
  img_url,
  restaurant_status_option,
  header_token,
  google_api_key,
} from "../../config";
import Axios from "axios";
import swal from "sweetalert";
import moment from "moment";

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log('re-render');
  return (
    <GoogleMap defaultCenter={props.point} zoom={17} center={props.point}>
      <Marker position={props.point} draggable={false} />
    </GoogleMap>
  );
});

export const RestaurantDetailHistory = (props) => {
  const [radius] = useState(17);
  const [point, setpoint] = useState({ lat: 13.674351, lng: 100.517556 });
  const [status, setstatus] = useState({});
  const [open_day, setopen_day] = useState([]);
  const [close_day, setclose_day] = useState([]);

  const [data, setdata] = useState({
    credit: 0,
    user_rating: { rating: 0, votes: 0 },
    location: {
      latitude: 16.409936252252667,
      longitude: 103.06710188750003,
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      province: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
        short_name_en: "",
        short_name_th: "",
      },
      district: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
      },
      sub_district: {
        id: "",
        zip_code: "",
        name_en: "",
        name_th: "",
      },
    },
    open_day: {
      sunday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      monday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      tuesday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      wednesday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      thursday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      friday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
      saturday: {
        is_open: true,
        start_time: "",
        end_time: "",
      },
    },
    categories: [
      {
        id: "",
        title: "",
      },
    ],
  });
  const [summary, setsummary] = useState(0);

  const getSummary = async () => {
    try {
      let { data } = await Axios.post(
        `${api}/restaurants/summary/filter`,
        {
          start_date: "2019/01/01",
          end_date: moment(new Date(), "DD/MM/YYYY").format("YYYY-MM-DD"),
        },
        header_token
      );

      // console.log(data);
      setsummary(data.total_amount);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getData = async () => {
    try {
      let { data } = await Axios.get(
        `${api}/restaurants/${props.restaurantId}`
      );

      // console.log(data);
      setdata(data);

      restaurant_status_option.forEach((value) => {
        if (parseInt(value.value) === parseInt(data.status)) {
          setstatus(value);
        }
      });

      setpoint({ lat: data.location.latitude, lng: data.location.longitude });
      let _tmp_open = [];
      let _tmp_close = [];
      Object.keys(data.open_day).map((key, idx) => {
        if (data.open_day[key].is_open === true) {
          // console.log("yes", data.open_day[key]);
          let time =
            data.open_day[key].start_time + " - " + data.open_day[key].end_time;

          let day;
          switch (key) {
            case "sunday":
              day = "วันอาทิตย์";
              break;
            case "monday":
              day = "วันจันทร์";
              break;
            case "tuesday":
              day = "วันอังคาร";
              break;
            case "wednesday":
              day = "วันพุธ";
              break;
            case "thursday":
              day = "วันพฤหัส";
              break;
            case "friday":
              day = "วันศุกร์";
              break;
            case "saturday":
              day = "วันเสาร์";
              break;

            default:
              return;
          }
          _tmp_open.push(
            <React.Fragment key={key}>
              <span>
                {day} {time}
              </span>
              <br />
            </React.Fragment>
          );
        } else {
          let day;
          switch (key) {
            case "sunday":
              day = "หยุดวันอาทิตย์";
              break;
            case "monday":
              day = "หยุดวันจันทร์";
              break;
            case "tuesday":
              day = "หยุดวันอังคาร";
              break;
            case "wednesday":
              day = "หยุดวันพุธ";
              break;
            case "thursday":
              day = "หยุดวันพฤหัส";
              break;
            case "friday":
              day = "หยุดวันศุกร์";
              break;
            case "saturday":
              day = "หยุดวันเสาร์";
              break;

            default:
              return;
          }
          // console.log(time);
          // if()
          _tmp_close.push(
            <React.Fragment key={key}>
              <span>{day} </span>
              <br />
            </React.Fragment>
          );
        }
        // console.log("key", v, "value", data.open_day[key]);
      });
      setopen_day(_tmp_open);
      setclose_day(_tmp_close);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const setRestaurantStatus = async () => {
    try {
      let res = await Axios.get(
        `${api}/restaurants/${props.restaurantId}/update-status?status=${status.value}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        swal("อัพเดตเสร็จสิ้น !!", {
          icon: "success",
        });
      }
      // console.log(data);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    if (props.restaurantId) {
      getData();
      getSummary();
    }
  }, [props]);

  return (
    <div className="card-body">
      <div className="row border-bottom pd-3 pt-4">
        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">ยอดขายทั้งหมด</h5>
          <span className="h1 mr-2 ">฿{summary.toLocaleString()}</span>
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">เครดิต</h5>
          <span className="h1 mr-2 ">฿{data.credit.toLocaleString()}</span>
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">เมนูอาหาร</h5>
          <span className="h1 mr-2 ">{data.menu_count}</span>
          <span>รายการ</span>
        </div>
        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">คะแนน</h5>
          <span className="h1 mr-2 ">{data.user_rating.rating}</span>
          <span>({data.user_rating.votes} รีวิว)</span>
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted">สถานะ</h5>
          <Select
            options={restaurant_status_option}
            placeholder={"สถานะ"}
            value={status}
            // readOnly
            onChange={(e) => {
              setstatus(e);
              // console.log(status);
            }}
          />
        </div>

        <div className="col-3 mb-2">
          <h5 className="tx-13 text-muted"> &nbsp;</h5>
          <a
            tabIndex="0"
            className="btn btn-primary  mr-2 "
            onClick={() => {
              setRestaurantStatus();
            }}
          >
            บันทึก
          </a>
        </div>
        <div className="col-12 text-white pt-4"></div>
      </div>

      <div className="row mt-4 mb-4">
        <div className="col-4">
          {data.location.address}
          {", "}
          {`ตำบล${data.location.sub_district.name_th}`}
          {", "}
          {`อำเภอ${data.location.district.name_th}`}
          {", "}
          {`จังหวัด${data.location.province.name_th}`}
          {", "}
          {`${data.location.sub_district.zip_code}`}
          <br />
          <br />
          {/* เปิดให้บริการ {data.open_day.sunday.start_time} -{" "}
          {data.open_day.sunday.end_time} */}
          {open_day}
          <br />
          {close_day}
          <br />
          เบอร์โทร {data.phone_numbers}
          <br />
          อีเมล {data.email}
          <br />
          {/* อาหารทะเล อาหารไทย */}
          {data.categories.map((value) => {
            return <span key={value.id}>{value.title}, </span>;
          })}
          <br />
          <button
            onClick={() => props.pushToLink(`/restaurant/edit`)}
            // href={`/restaurant/edit/${props.restaurantId}`}
            className="btn btn-outline-primary mr-2 ml-auto  "
          >
            แก้ไข
          </button>
        </div>

        <div
          className="col-4 img-thumbmail"
          style={{ height: 400, overflow: "scroll" }}
        >
          <img
            className="lazy img-responsive"
            src={
              data.picture === ""
                ? "/images/no-img.jpg"
                : `${img_url}${data.picture}`
            }
          />
        </div>

        <div className="col-4">
          <MyMapComponent point={point} />
        </div>
      </div>
    </div>
  );
};
