import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { api, header_token } from "../../config";
import moment from "moment";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { NotiContext } from "../../store/keep";

export const RestaurantDetailRate = (props) => {
  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  // const [current_page, setcurrent_page] = useState(1);
  // const [page_count, setpage_count] = useState(0);
  // const [limit, setlimit] = useState(10);
  // const [total_count, settotal_count] = useState(0);

  const { restaurantObj } = useContext(NotiContext);

  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(
        `${api}/review/restaurant/${props.restaurantId}`,
        header_token
      );
      // console.log("data", data);

      // let cal_page_count = Math.ceil(data.meta.total_count / limit);
      // setpage_count(cal_page_count);
      // settotal_count(data.meta.total_count);
      if (data) {
        setdata(data);
      } else {
        setdata([]);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  const deleteReview = async (order_id) => {
    try {
      let res = await Axios.delete(`${api}/review/${order_id}`, header_token);

      // console.log(res);
      if (res.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      return false;
    }
  };

  useEffect(() => {
    // console.log('restaurantObj',restaurantObj)
    if (Object.keys(restaurantObj).length > 0) {
      // console.log("restaurantObj", restaurantObj);

      getData();
    }
  }, [restaurantObj]);

  return (
    <div className="card-body">
      <LoadingMask loading={loading} text={"loading..."}>
        <div style={{ minHeight: 500 }}>
          <table id="example" className="table tebleSize">
            <thead>
              <tr>
                <th>รีวิว</th>
                <th width="200">คะแนน</th>
                <th width="150">วันที่</th>
                <th width="150">ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="4"> ไม่มีรีวิว</td>
                </tr>
              ) : null}
              {data.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.review_text}</td>
                    <td>{value.rating}</td>
                    <td>{moment(value.created_at).format("DD/MM/YYYY")}</td>
                    <td>
                      <button
                        className="btn btn-outline-primary btn-sm mb-1"
                        onClick={() => {
                          // console.log("click");
                          swal({
                            title: `ต้องการลบรีวิวใช่หรือไม่ ?`,
                            confirmButtonText: "ใช่",
                            cancelButtonText: "ยกเลิก",
                            icon: "warning",
                            buttons: true,
                            // dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              let is_success = await deleteReview(value.id);
                              getData();

                              if (is_success) {
                                getData();
                                swal("ลบรีวิวเสร็จสิ้น !!", {
                                  icon: "success",
                                });
                              } else {
                                swal("เกิดข้อผิดพลาด !!", {
                                  icon: "error",
                                });
                              }
                            }
                          });
                        }}
                      >
                        ลบ
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </LoadingMask>
    </div>
  );
};
