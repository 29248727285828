import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api, img_url } from "./config";
import Axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";

export const ReadNews = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);

  const [img, setimg] = useState("");
  const [detail, setdetail] = useState("");
  const getData = async () => {
    setloading(true);
    try {
      let { data } = await Axios.get(`${api}/news/${id}`);
      // console.log(data);
      const blocksFromHtml = htmlToDraft(data.description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );

      setdetail(EditorState.createWithContent(contentState));
      setimg(img_url + data.cover);
      //   setdetail(data.description);
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  useEffect(() => {
    // console.log(id);
    getData();
  }, []);
  return (
    <LoadingMask loading={loading} text={"loading..."}>
      <div style={{ paddingTop: 8, backgroundColor: "white", minHeight: '100vh' }}>
        <img
          src={img ? img : ""}
          style={{
            maxWidth: "95%",
            maxHeight: 300,
            display: "block",
            margin: "auto",
          }}
        />
        <Editor
          editorStyle={{
            border: "1px solid white",
            borderRadius: 3,
            height: "100%",
            padding: 8,
          }}
          // toolbarClassName="toolbarClassName"
          // wrapperClassName="wrapperClassName"
          // editorClassName="editorClassName"
          editorState={detail}
          readOnly={true}
          toolbarHidden={true}
          toolbar={{
            options: [
              // "inline",
              //   "blockType",
              // "fontSize",
              //   "fontFamily",
              // "image",
              // "colorPicker",
              // "textAlign",
              // "history",
            ],
          }}
        />
      </div>
    </LoadingMask>
  );
};
