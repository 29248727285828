import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BrandMenuAddonGroup } from "./BrandMenuAddonGroup";
import { BrandMenuGroup } from "./BrandMenuGroup";
import { BrandMenuList } from "./BrandMenuList";
import { BrandMenuListAddon } from "./BrandMenuListAddon";

export const MenuBrand = ({ brand }) => {
  const [menu, setMenu] = useState(1);
  const { push } = useHistory();

  return (
    <div className="card-body">
      <div className="row">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li
            className="nav-item"
            onClick={() => {
              setMenu(1);
            }}
          >
            <a className={"nav-link " + (menu === 1 ? "active" : "")}>
              รายการอาหาร
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setMenu(2);
            }}
          >
            <a className={"nav-link " + (menu === 2 ? "active" : "")}>
              หมวดหมู่ Add On
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setMenu(3);
            }}
          >
            <a className={"nav-link " + (menu === 3 ? "active" : "")}>
              รายการ Add On
            </a>
          </li>
        </ul>
        {menu === 1 ? (
          <button
            onClick={() => {
              push(
                `/brands/detail/${brand?.id}/menu-add`
              );
            }}
            className="btn btn-primary  mr-2 ml-auto "
            style={{ height: 42 }}
          >
            + เพิ่มรายการอาหาร
          </button>
        ) : (
          ""
        )}

        {menu === 3 ? (
          <button
            data-toggle="modal"
            data-target=".create-list-addon"
            className="btn btn-primary  mr-2 ml-auto "
            style={{ height: 42 }}
          >
            + เพิ่มรายการ Add On
          </button>
        ) : (
          ""
        )}
      </div>

      {menu === 1 ? (
        <BrandMenuList brand={brand} />
      ) : (
        ""
      )}

      {menu === 2 ? (
        <BrandMenuAddonGroup brand={brand} />
      ) : (
        ""
      )}

      {menu === 3 ? (
        <BrandMenuListAddon brand={brand} />
      ) : (
        ""
      )}
    </div>
  );
};
