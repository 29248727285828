import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Select from "react-select";
import "moment/locale/th";
import moment from "moment";
import { api, header_token, convertMoney } from "../../config";
import Axios from "axios";
import swal from "sweetalert";

const order_status = [
  { value: 1, label: "ออเดอร์ใหม่" },
  { value: 4, label: "กำลังจัดส่ง" },
  { value: 5, label: "ส่งสำเร็จ" },
  { value: 0, label: "ยกเลิก" },
  { value: 6, label: "ส่งไม่สำเร็จ" },
  { value: 3, label: "กำลังดำเนินการ" },
  { value: 2, label: "พนักงานส่งรับงาน" },
];

export const OrderDetail = () => {
  const { id } = useParams();

  const [data, setdata] = useState({
    restaurant: {
      location: {
        latitude: 16.409936252252667,
        longitude: 103.06710188750003,
        address: "",
        province_id: "",
        district_id: "",
        sub_district_id: "",
        province: {
          id: "",
          code: "",
          name_th: "",
          name_en: "",
          short_name_en: "",
          short_name_th: "",
        },
        district: {
          id: "",
          code: "",
          name_th: "",
          name_en: "",
        },
        sub_district: {
          id: "",
          zip_code: "",
          name_en: "",
          name_th: "",
        },
      },
    },
    user: {},
    to: {
      latitude: 16.409936252252667,
      longitude: 103.06710188750003,
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      province: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
        short_name_en: "",
        short_name_th: "",
      },
      district: {
        id: "",
        code: "",
        name_th: "",
        name_en: "",
      },
      sub_district: {
        id: "",
        zip_code: "",
        name_en: "",
        name_th: "",
      },
    },
    rider: { id: "", employee_type: {}, team: {} },
  });
  const [menu, setmenu] = useState([]);
  const [menu_option, setmenu_option] = useState([]);

  const [status, setstatus] = useState({});

  const getOrder = async () => {
    try {
      let { data } = await Axios.get(`${api}/order/${id}`, header_token);

      let tmp = [];
      data.menu.forEach((value) => {
        let _food_cost = value.is_promotion
          ? value.promotion_price
          : value.price;
        let _add_on = "";
        if (value.add_on) {
          value.add_on.forEach((val) => {
            if (val.options) {
              val.options.forEach((v, idx) => {
                _food_cost += v.price;
                _add_on += `${v.name_th} (${v.price}) ${
                  val.options.length !== idx + 1 ? "," : ""
                }`;
              });
            }
          });
        }
        tmp.push({
          id: value.id,
          name_th: { value: value.id, label: value.name_th },
          name_en: value.name_en,
          add_on: _add_on,
          quantity: value.quantity,
          price: _food_cost,
          remark: value.remark,
          edit: false,
          new: false,
        });
      });
      setmenu(tmp);

      let _tmp = { ...data };

      if (data.rider === null) {
        _tmp.rider = { id: "", employee_type: {}, team: {} };
      }
      getMenu(data.restaurant.id);
      setdata(_tmp);

      setstatus(order_status.filter((e) => parseInt(e.value) === parseInt(data.status))[0]);
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  const getMenu = async (restaurantId) => {
    try {
      let { data } = await Axios.get(`${api}/restaurants/${restaurantId}/menu`);
      let tmp = [];
      // console.log(data);
      data.forEach((value) => {
        tmp.push({ value: value.id, label: value.name_th, price: value.price });
      });
      setmenu_option(tmp);
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">
              Order No.{data.order_no}
            </h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                รายการสั่งซื้อ
              </p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                Order No.{data.order_no}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">วันที่/เวลา</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="input-group  d-flex align-items-center col-6">
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">ออเดอร์วันที่</span>
                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        style={{ width: 200, borderRight: 0 }}
                        value={`${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("DD/MM/YYYY")} ${moment(
                          data.created_at,
                          "YYYY/MM/DD h:mm:ss a"
                        ).format("LT")}
                        `}
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon mx-2 mt-3">-</div>
                  <div className="input-group-addon">
                    <span className="text-muted tx-13">วันที่ส่ง</span>

                    <div className="input-group mb-3">
                      <input
                        id="add_name"
                        className="form-control"
                        name="add_name"
                        type="text"
                        style={{ width: 200, borderRight: 0 }}
                        value={
                          data.shipping_date
                            ? `${moment(
                                data.shipping_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("DD/MM/YYYY")} ${moment(
                                data.shipping_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("LT")}
                            `
                            : data.due_date
                            ? `${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("DD/MM/YYYY")} ${moment(
                                data.due_date,
                                "YYYY/MM/DD h:mm:ss a"
                              ).format("LT")}
                            `
                            : ""
                        }
                        readOnly
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#F7F9FC",
                            borderTopColor: "rgb(227, 231, 237)",
                            borderRightColor: "rgb(227, 231, 237)",
                            borderBottomColor: "rgb(227, 231, 237)",
                            borderLeft: 0,
                          }}
                        >
                          <i
                            className="fa fa-calendar-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group-addon ml-3">
                    <span className="text-muted tx-13">ประเภท</span>
                    <h5>{data.type === 0 ? "ออเดอร์ล่วงหน้า" : "ส่งทันที"}</h5>
                  </div>
                </div>

                <span className="ml-auto mr-3">
                  <span className="tx-13 text-muted">สถานะ</span>
                  <Select
                    value={status}
                    options={order_status}
                    placeholder={"สถานะ.."}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        width: 150,
                      }),
                    }}
                    // isDisabled
                    onChange={() => {
                      console.log(menu);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">สถานที่จัดส่ง</h6>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="container_timeline">
                  <div className="timeline-block timeline-block-right mb-4">
                    <div className="marker active" style={{ zIndex: 1 }}>
                      <i className="fa fa-map-marker " aria-hidden="true"></i>
                    </div>
                    <div className="timeline-content border-bottom pb-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-13">ชื่อร้าน</span>
                              <br />
                              <Link
                                to={`/restaurant/detail/${data.restaurant.id}/history`}
                              >
                                {data.restaurant.full_name}
                              </Link>{" "}
                              <br />
                              <span className="tx-13 text-muted">
                                {data.restaurant.restaurant_id}
                              </span>
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">ที่อยู่</span>
                              <br />
                              {data.restaurant.location.address}
                              {", "}
                              {`ตำบล${data.restaurant.location.sub_district.name_th}`}
                              {", "}
                              {`อำเภอ${data.restaurant.location.district.name_th}`}
                              {", "}
                              {`จังหวัด${data.restaurant.location.province.name_th}`}
                              {", "}
                              {`${data.restaurant.location.sub_district.zip_code}`}
                              <br />
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">
                                เบอร์โทรศัพท์
                              </span>
                              <br />
                              {data.restaurant.phone_numbers}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">เครดิต</span>
                              <br />฿
                              {data.restaurant.credit
                                ? convertMoney(data.restaurant.credit)
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="timeline-block timeline-block-right ">
                    <div className="marker active" style={{ zIndex: 1 }}>
                      <i className="fa fa-map-marker " aria-hidden="true"></i>
                    </div>
                    <div className="timeline-content ">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col">
                              <span className="text-muted tx-13">
                                ชื่อ-นามสกุล
                              </span>
                              <br />
                              {/* <Link to="user_profile.php"> */}
                              {data.contact_name}
                              {/* </Link> */}
                              <br />
                              <span className="tx-13 text-muted">
                                {data.user.user_id}
                              </span>
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">ที่อยู่</span>
                              <br />
                              {data.to.address}
                              {", "}
                              {`ตำบล${data.to.sub_district.name_th}`}
                              {", "}
                              {`อำเภอ${data.to.district.name_th}`}
                              {", "}
                              {`จังหวัด${data.to.province.name_th}`}
                              {", "}
                              {`${data.to.sub_district.zip_code}`}
                              <br />
                              {`${data.to.detail}`}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">
                                เบอร์โทรศัพท์
                              </span>
                              <br />
                              {data.phone_number}
                            </div>
                            <div className="col">
                              <span className="text-muted tx-13">คะแนน</span>
                              <br />
                              {data.user.point}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h6 className="text-muted">รายการอาหาร</h6>
          <div className="card">
            <div className="card-body">
              {/* <div className="table-responsive"> */}
              <div className="content">
                <table id="" className="table">
                  <thead>
                    <tr>
                      <th width="200">รายการอาหาร</th>
                      <th width="400">add-on</th>
                      <th width="80">จำนวน</th>
                      <th width="100">ราคา</th>
                      <th width="150">หมายเหตุ</th>
                      <th width="50">Edit</th>
                      <th width="50">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menu.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td className="name">
                            {value.edit === true ? (
                              <Select
                                value={value.name_th}
                                options={menu_option}
                                placeholder={"เมนู.."}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    // width: 100,
                                  }),
                                }}
                                onChange={async (e) => {
                                  let tmp = [...menu];
                                  tmp[index].name_th = e;
                                  tmp[index].price = e.price;
                                  await setmenu(tmp);
                                }}
                              />
                            ) : (
                              value.name_th.label
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <input
                                type="text"
                                className="form-control"
                                value={value.add_on}
                                disabled
                              />
                            ) : (
                              value.add_on
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <Select
                                value={value.name_th}
                                options={() => {
                                  //   menu_option.map;
                                }}
                                placeholder={"เมนู.."}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    // width: 100,
                                  }),
                                }}
                                onChange={async (e) => {
                                  let tmp = [...menu];
                                  tmp[index].name_th = e;
                                  tmp[index].price = e.price;
                                  await setmenu(tmp);
                                }}
                                closeMenuOnSelect={false}
                                isMulti
                              />
                            ) : (
                              value.quantity
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <input
                                type="text"
                                className="form-control"
                                value={value.price}
                                onChange={(e) => {
                                  let tmp = [...menu];
                                  tmp[index].price = e.target.value;
                                  setmenu(tmp);
                                }}
                                disabled
                              />
                            ) : (
                              value.price
                            )}
                          </td>
                          <td>
                            {value.edit === true ? (
                              <input
                                type="text"
                                className="form-control"
                                value={value.remark}
                                onChange={(e) => {
                                  let tmp = [...menu];
                                  tmp[index].remark = e.target.value;
                                  setmenu(tmp);
                                }}
                              />
                            ) : (
                              value.remark
                            )}
                          </td>
                          <td className="edit">
                            {value.edit === false ? (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                onClick={() => {
                                  let tmp = [...menu];
                                  tmp[index].edit = !tmp[index].edit;
                                  setmenu(tmp);
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                onClick={() => {
                                  let tmp = [...menu];

                                  if (tmp[index].new === true) {
                                    // console.log(tmp[index]);
                                    // addArea({
                                    //   name_th: tmp[index].name_th,
                                    //   name_en: tmp[index].name_en,
                                    // });
                                  } else {
                                    // console.log("edit");
                                    let send_data = {
                                      name_en: value.name_en,
                                      name_th: menu[index].name_th,
                                    };
                                    console.log(send_data);
                                  }
                                  tmp[index].edit = !tmp[index].edit;
                                  tmp[index].new = false;

                                  setmenu(tmp);
                                }}
                              ></i>
                            )}
                          </td>
                          <td className="trash">
                            <i
                              style={{ cursor: "pointer" }}
                              className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                              onClick={() => {
                                swal({
                                  title: `ต้องการลบ  ${value.name_th} ?`,
                                  confirmButtonText: "ลบ",
                                  cancelButtonText: "ยกเลิก",
                                  icon: "warning",
                                  buttons: true,
                                  dangerMode: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    // let is_delete = deleteArea(value.id);
                                    let is_delete;
                                    if (is_delete) {
                                      swal("ลบเสร็จสิ้น !!", {
                                        icon: "success",
                                      });
                                    } else {
                                      swal("เกิดข้อผิดพลาด !!", {
                                        icon: "error",
                                      });
                                    }
                                  }
                                });
                              }}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td
                        className="new-row"
                        colSpan="7"
                        onClick={() => {
                          if (menu[menu.length - 1].new !== true) {
                            let tmp = [...menu];
                            tmp.push({
                              id: "",
                              name_th: { value: "", label: "" },
                              name_en: "",
                              quantity: "",
                              price: "",
                              remark: "",
                              edit: true,
                              new: true,
                            });
                            setmenu(tmp);
                          }
                        }}
                      >
                        + เพิ่มเมนู
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* </div> */}

              <div className="row" style={{ textAlign: "right" }}>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าอาหาร ฿
                    {data.food_cost ? data.food_cost.toLocaleString() : ""}
                  </span>
                </div>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    ค่าจัดส่ง(~ {data.distance}km) ฿
                    {data.shipping_cost
                      ? data.is_promotion_service
                        ? data.shipping_cost_promotion.toLocaleString()
                        : data.shipping_cost.toLocaleString()
                      : ""}{" "}
                    {data.is_promotion_service ? `(โปรโมชั่น)` : ""}
                  </span>
                </div>
                <div className="col-12 border-bottom pt-2 pb-2">
                  <span className="h5">
                    เก็บเงินสด ฿{data.total ? data.total.toLocaleString() : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
