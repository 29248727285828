import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { api, header_token, img_url } from "../../config";
import Select from "react-select";
import { useHistory } from "react-router-dom";


export const DetailBrandHistory = ({ brand }) => {
  const { push } = useHistory();
  const [data, setData] = useState();
  const [status, setStatus] = useState({ value: 0, label: "ไม่ใช้งาน" });

  const setBrandStatus = async () => {
    try {
      let res = await Axios.post(
        `${api}/brand/${data.id}/update-status?status=${status.value}`,
        null,
        header_token
      );
      swal("อัพเดตเสร็จสิ้น !!", {
        icon: "success",
      });
    } catch (error) {
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
  };

  useEffect(() => {
    if (!brand) return;
    setData(brand);
    setStatus({ label: brand.status === 1 ? "ใช้งาน" : "ไม่ใช้งาน", value: brand.status })
  }, [brand])

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-8 text-center">
          <img
            alt=""
            className="lazy img-responsive"
            src={
              data?.logo === ""
                ? "/images/no-img.jpg"
                : `${img_url}${data?.logo}`
            }
            style={{
              width: "auto",
              height: 512
            }}
          />
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-6 mb-2">
              <h5 className="tx-13 text-muted">สถานะ</h5>
              <Select
                options={[
                  { value: 0, label: "ไม่ใช้งาน" },
                  { value: 1, label: "ใช้งาน" },
                ]}
                placeholder={"สถานะ"}
                value={status}
                onChange={e => {
                  setStatus(e);
                }}
              />
            </div>

            <div className="col-6 mb-2">
              <h5 className="tx-13 text-muted"> &nbsp;</h5>
              <button
                tabIndex="0"
                className="btn btn-primary  mr-2 "
                onClick={() => {
                  setBrandStatus();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>

          <div className="">
            <button
              onClick={() =>
                push(`/brands/edit/${data?.id}`)
              }
              className="btn btn-outline-primary mr-2 ml-auto  "
            >
              แก้ไข
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}